.two-img
    .container
        @media(max-width: 991px)
            .row
                &:first-child
                    img
                        margin-bottom: 100px 
        @media(max-width: 767px)
            .row
                &:first-child
                    img
                        margin-bottom: 50px 