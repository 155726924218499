@font-face
    font-family: 'GloberRegular'
    src: url( '../font/globerregular.eot' )
    src: url( '../font/globerregular.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerregular.woff2' ) format( 'woff2' ),
        url( '../font/globerregular.woff' ) format( 'woff' ),
        url('../font/GloberRegular.otf') format("opentype"),
        url( '../font/globerregular.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberHeavy'
    src: url( '../font/globerheavy.eot' )
    src: url( '../font/globerheavy.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerheavy.woff2' ) format( 'woff2' ),
        url( '../font/globerheavy.woff' ) format( 'woff' ),
        url('../font/GloberHeavy.otf') format("opentype"),
        url( '../font/globerheavy.svg' ) format( 'svg' ) 

// @font-face
//     font-family: 'GloberBlack'
//     src: url( '../font/globerblack.eot'  )
//     src: url( '../font/globerblack.eot?#iefix' ) format( 'embedded-opentype' ),
//         url( '../font/globerblack.woff2' ) format( 'woff2' ),
//         url( '../font/globerblack.woff' ) format( 'woff' ),
//         url('../font/GloberBlack.otf') format("opentype"),
//         url( '../font/globerblack.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberxBold'
    src: url( '../font/globerxbold.eot' )
    src: url( '../font/globerxbold.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerxbold.woff2' ) format( 'woff2' ),
        url( '../font/globerxbold.woff' ) format( 'woff' ), 
        url('../font/GloberxBold.otf') format("opentype"),
        url( '../font/globerxbold.svg' ) format( 'svg' )

// @font-face
//     font-family: 'GloberBold'
//     src: url( '../font/globerbold.eot' )
//     src: url( '../font/globerbold.eot?#iefix' ) format( 'embedded-opentype' ),
//         url( '../font/globerbold.woff2' ) format( 'woff2' ),
//         url( '../font/globerbold.woff' ) format( 'woff' ),
//         url('../font/GloberBold.otf') format("opentype"),
//         url( '../font/globerbold.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberBook'
    src: url( '../font/globerbook.eot' )
    src: url( '../font/globerbook.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerbook.woff2' ) format( 'woff2' ),
        url( '../font/globerbook.woff' ) format( 'woff' ),
        url('../font/GloberBook.otf') format("opentype"),
        url( '../font/globerbook.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberSemiBold'
    src: url( '../font/globersemibold.eot' )
    src: url( '../font/globersemibold.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globersemibold.woff2' ) format( 'woff2' ),
        url( '../font/globersemibold.woff' ) format( 'woff' ),
        url('../font/GloberSemiBold.otf') format("opentype"),
        url( '../font/globersemibold.svg' ) format( 'svg' )

@font-face
    font-family: 'GloberLight'
    src: url( '../font/globerlight.eot' )
    src: url( '../font/globerlight.eot?#iefix' ) format( 'embedded-opentype' ),
        url( '../font/globerlight.woff2' ) format( 'woff2' ),
        url( '../font/globerlight.woff' ) format( 'woff' ),
        url('../font/GloberLight.otf') format("opentype"),
        url( '../font/globerlight.svg' ) format( 'svg' )

html
    font-size: 24px
    @media(max-width: 1499px)
        font-size: 22px
    @media(max-width: 991px)
        font-size: 20px
    @media(max-width: 767px)
        font-size: 18px

    // font-size: 24px
    // line-height: 45px
    // @media(max-width:1500px)
    //     margin-bottom: 18px
    //     line-height: 29px
    // @media(max-width: 375px)
    //     font-size: 14px	
    //     line-height: 25px


    body
        font-size: 24px
        @media(max-width: 1199px)
            font-size: 22px
        @media(max-width: 991px)
            font-size: 20px
        @media(max-width: 767px)
            font-size: 18px

        line-height: 1.875rem //45px

    main

        h1,h2,h3,h4,h5,h6
            margin: 0
            padding: 0
            line-height: normal

        ul
            list-style-type: none
            @media(max-width: 768px)
                padding-left: 20px

            li
                position relative
                &:before
                    position: absolute
                    content: ''
                    background-color: brand-color
                    border-radius: 50%
                    top: 22px
                    left: -40px
                    height: 6px
                    width: 6px
                    @media(max-width: 768px)
                        left: -20px
                        top: 10px



        p
            line-height: 1.875rem //45px
            margin: 0

        .f90
            font-size: 3.75rem
            font-family: 'GloberHeavy'
            line-height 110px

            @media(max-width: 1499px)
                font-size: 80px
                line-height 100px

            @media(max-width: 1199px)
                font-size: 60px
                line-height 70px

            @media(max-width: 991px)
                font-size: 50px
                line-height 70px

            @media(max-width: 767px)
                font-size: 45px
                line-height 54px

            @media(max-width: 500px)
                font-size: 35px
                line-height 45px

        

        .f77
            font-family: 'GloberHeavy'
            font-size: 3.2rem
            line-height: 4.041666666666667rem

            @media(max-width: 1499px)
                font-size: 70.4px
                line-height 77px

            @media(max-width: 1199px)
                font-size: 59.4px
                line-height 61.59px

            @media(max-width: 991px)
                font-size: 50px
                line-height 52px

            @media(max-width: 767px)
                font-size: 41.4px
                line-height 45px

            @media(max-width: 500px)
                font-size: 27px
                line-height 32.4px


        .f48
            font-size: 2rem
            font-family: 'GloberxBold'
            line-height: 2.4166666666666665rem


            @media(max-width: 1499px)
                font-size: 42px
                line-height: 52px
            @media(max-width: 768px)
                font-size: 36px
                line-height:46px
            @media(max-width: 640px)
                font-size: 35px
                line-height: 43px
            @media(max-width: 576px)
                font-size: 30px
                line-height: 34px


            margin-bottom: 60px
            @media(max-width:1500px)
                margin-bottom: 40px
            @media(max-width:375px)
                margin-bottom: 20px
            p
                font-size: 2rem
                font-family: 'GloberxBold'
                line-height: 2.4166666666666665rem //58px

                @media(max-width: 1499px)
                    font-size: 42px
                    line-height: 52px
                @media(max-width: 768px)
                    font-size: 36px
                    line-height:46px  
                @media(max-width: 640px)
                    font-size: 35px
                    line-height: 43px
                @media(max-width: 576px)
                    font-size: 30px
                    line-height: 34px

        .f38
            @media(min-width: 600px)
                font-size: 1.5833333333333333rem
                line-height: 2.125rem

        

        .f30
            font-size: 1.25rem

        .f24
            font-size: 1rem
            h2
                font-size: 1rem




        .f18
            font-size: 0.75rem
            line-height: 0.8333333333333334rem // 20px
            p
                font-size: 0.75rem
                line-height: 0.8333333333333334rem // 20px
        .f16
            font-size: 0.6666666666666666rem

        .f14
            font-size: 14px

        .f12
            font-size: 12px
            line-height: 24px

        .lh110
            line-height: 4.583333333333333rem
            // @media(max-width: 480px)
            //     line-height 32px


        .lh97
            line-height: 4.041666666666667rem
            // line-height: 45px
            // @media( min-width: 768px )
            //     line-height: 66px
            // @media( min-width: 992px )
            //     line-height: 76px
            // @media( min-width: 1200px )
            //     line-height: 86px
            // @media( min-width: 1500px )
            //     line-height: 96px

        .lh80
            line-height: 3.3333333333333335rem
            // @media(max-width: 1200px)
            //     line-height 32px
            // @media(max-width: 480px)
            //     line-height 32px

        .lh45
            line-height: 1.875rem
            p
                line-height: 1.875rem
            h2
                line-height: 1.875rem


            // @media(max-width:1500px)
            //     line-height: 29px
            // @media(max-width: 375px)
            //     line-height: 25px
            

        .lh36
            line-height: 1.5rem
            p
                line-height: 1.5rem

        .lh32
            line-height:  1.3333333333333333rem
            p


        .lh24
            line-height: 24px
            p
                line-height: 24px

        .GX
            font-family: 'GloberxBold'

        .GB
            font-family: 'GloberBook'