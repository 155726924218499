.carosuel
    .slick-track
        margin-bottom: 2.222rem //40px
        display: flex
        align-items: center
    .slick-prev
        left: -11%
    .slick-next
        right: -11%
    .slick-prev:before
    .slick-next:before
        background-repeat: no-repeat
        color: transparent
        font-size: 26px
        @media(max-width: 767px)
            display: none
    .slick-prev:before
        background-image: url(../../dist/img/arrow-left.png)        
    .slick-next:before 
        background-image: url(../../dist/img/arrow-right.png)           
    .slick-slide img
        margin: 0 auto
        max-width: 100%
        height: auto        
    .slick-dots 
        li
            &:before
                content: none
    a
        text-decoration: none
    .slick-active
        a
            text-decoration: underline