.photo-on-right
    figure
        display: table
        margin: 0
    img 
        margin-bottom: 25px
    figcaption
        display: table-caption
        caption-side: bottom
        p
            font-size: 14px
            line-height: normal
