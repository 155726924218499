.start

    .what-do-we-do
        background-color: light-grey-2
        padding-top: 70px
        padding-bottom: 70px

        @media( min-width: 992px )
            padding-top: 115px
            padding-bottom: 115px

        .content
            margin-top: 25px

            @media( min-width: 768px )
                margin-top: 40px
            @media( min-width: 992px )
                margin-top: 60px
            @media( min-width: 1200px )
                margin-top: 90px
            
            .pictogram-wrapper
                position: relative
                min-height: 420px

                @media( min-width: 600px )
                    min-height: 500px
                @media( min-width: 992px )
                    min-height: 478px
                @media( min-width: 1200px )
                    min-height: 478px

                .outer-circle,
                .inner-circle
                    position: relative
                    border: 2px solid dark-blue
                    border-radius: 100%

                    @media( min-width: 992px )
                        position: absolute
                        top: 0
                        left: 0

                    > svg 
                        position: absolute
                        display: none 

                        circle
                            fill: transparent
                            stroke-width: 1
                            stroke: alpha( brand-color, .4 ) 


                .outer-circle
                    width: 220px
                    height: 220px
                    margin: 90px auto 40px
                    transform: scale(0.85)
                    
                    @media( min-width: 375px )
                        transform: scale(1)

                    @media( min-width: 600px )
                        width: 300px
                        height: 300px

                    @media( min-width: 992px )
                        margin-left: 160px
                        width: 220px
                        height: 220px

                    @media( min-width: 1200px )
                        width: 300px
                        height: 300px

                    > svg 
                        top: -7px
                        left: -7px
                        width: calc( 100% + 14px )
                        height: calc( 100% + 14px )

                    .moon-orbit
                        position: absolute
                        top: 0
                        left: 0
                        right: 0
                        bottom: 0

                        .moon
                            position: absolute
                            top: 50%
                            left: 0
                            transform: translate( -50%, -50% )
                            width: 25px
                            height: 25px
                            border: 2px solid brand-color
                            border-radius: 100%
                            background-color: light-grey-2
                    
                    .other-strategy-elements
                        position: absolute
                        top: 0
                        left: 0
                        right: 0
                        bottom: 0
                        
                        .strategy-element
                            position: absolute
                            background: light-grey-2
                            border: 2px solid dark-blue
                            border-radius: 100%
                            width: 70px
                            height: 70px
                            padding: 0
                            margin: 0
                            transition: .2s border ease-out
                            outline: none
                            box-shadow: none !important
                            text-transform: lowercase

                            @media( min-width: 600px )
                                width: 81px
                                height: 81px


                            @media( min-width: 992px )
                                width: 55px
                                height: 55px

                            @media( min-width: 1200px )
                                width: 81px
                                height: 81px

                            svg
                                width: 100%
                                height: 100%

                                *
                                    fill: dark-blue
                                    transition: .2s all ease-out

                            span
                                position: absolute
                                text-transform: lowercase

                            &:nth-child( 1 )
                                bottom: 50%
                                left: 0
                                transform: translate( -50%, 50% )
                                
                                span
                                    right: 100px
                                    top: 140%
                                    transform: translate( 100%, -50% )
                                    
                                @media( min-width: 600px )
                                    span
                                        right: auto
                                        left: -15px
                                        top: 50%
                                        transform: translate( -100%, -50% )

                            &:nth-child( 2 )
                                top: 0
                                left: 50%
                                transform: translate( -50%, -50% )
                                
                                span
                                    left: 50%
                                    top: -15px
                                    transform: translate( -50%, -100% )

                            &:nth-child( 3 )
                                top: 50%
                                right: 0 
                                transform: translate( 50%, -50% )
                                
                                span
                                    right: 20px
                                    top: 140%
                                    transform: translate( 100%, -50% )
                                    
                                @media( min-width: 600px )
                                    span
                                        right: -15px
                                        top: 50%
                                        transform: translate( 100%, -50% )

                            &:nth-child( 4 )
                                bottom: 0
                                right: 50%
                                transform: translate( 50%, 50% )
                                
                                span
                                    left: 50%
                                    bottom: -15px
                                    transform: translate( -50%, 100% )

                            
                            &.active,
                            &:hover
                                border-color: brand-color

                                svg
                                    *
                                        fill: brand-color


                .inner-circle
                    width: 150px
                    height: 150px
                    top: 50%
                    left: 50%
                    transform: translate( -50%, -50% )
                    border-width: 0

                    @media( min-width: 600px )
                        border-width: 2px


                    @media( min-width: 992px )
                        width: 100px
                        height: 100px

                    @media( min-width: 1200px )
                        width: 150px
                        height: 150px

                    svg 
                        top: -4px
                        left: -4px
                        width: calc( 100% + 8px )
                        height: calc( 100% + 8px )

                    .strategy-element
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate( -50%, -50% )
                        font-family: 'GloberxBold'
                        border: 0
                        background: none
                        outline: none;
                        box-shadow: none !important;
                        text-transform: lowercase

                        &:before
                            content: ''
                            width: 0
                            height: 0
                            background: brand-color
                            border-radius: 100%
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate( -50%, -50% )
                            z-index: -1
                            transition: .2s all ease-out

                        &.active,
                        &:hover
                            &:before
                                width: 50px
                                height: 50px
                                transition: .5s all ease-out

            .description-container

                .description-wrapper
                    display: none

                    h1
                        color: brand-color
                        font-family: "GloberxBold"

                    .btn-plus
                        margin-top: 75px

                    &.active
                        display: block

@keyframes moon-orbit-move {

    0% {
        transform: rotate( 0deg )
    }
    100% {
        transform: rotate( 360deg )
    }

}

@keyframes moon-orbit-move-2 {

    0% {
        transform: rotate( 90deg )
    }
    100% {
        transform: rotate( 450deg )
    }

}

@keyframes moon-orbit-move-3 {

    0% {
        transform: rotate( 180deg )
    }
    100% {
        transform: rotate( 540deg )
    }

}

@keyframes moon-orbit-move-4 {

    0% {
        transform: rotate( 270deg )
    }
    100% {
        transform: rotate( 630deg )
    }

}