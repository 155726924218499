.methodology

    .sticky-nav
        max-height: 116px
        padding 2% 0
        background-color: brand-color
        display: flex
        align-items: center
        transition: all 0.6s ease
        // @media(max-width: 767px)
        //     display: none
        .text
            color: dark-brand
            img 
                margin 0 50px
            
            span
                transition: 0.5s all ease
                cursor: pointer
                display: block

                @media( min-width: 768px )
                    display: inline

                &.arrow
                    display: none 

                    @media( min-width: 768px )
                        display: inline
                // font-size: 15px

        &.sticky
            position: fixed
            top: 0
            left: 0
            z-index: 10
            width: 100%     

        &.offset1
            .text
                span
                    &:first-child
                        color: white

        &.offset2
            .text
                span
                    &:last-child
                        color: white 

    .margin
        background-color: light-grey-2

    .infographic
        position: relative
        .sections
            .section
                position absolute
                width: 100%
                display: flex
                align-items: center
                &:first-child
                    height 18%
                    top: 0
                    background-color: light-grey-2
                &:nth-child(2)
                    height 12%
                    top: 18%
                &:nth-child(3)
                    height 11%
                    background-color: light-grey-2
                    top: 30%
                &:nth-child(4)
                    height 8%
                    top: 41%

                .container
                    @media(max-width: 991px)
                        width: 100%
                    .box-text
                        .header
                            margin-bottom: 21px
                            @media(max-width: 991px)
                                transform: translateX(-50%) translateY(-50%) rotate(-90deg)
                                position: absolute
                                top: 4%
                                width: auto
                                height: 25px
                                white-space: nowrap
                                @media(max-width: 500px)
                                    font-size: 13px
                                @media(max-width: 400px)
                                    font-size: 10px
                        .description
                            @media(max-width: 991px)
                                display: none

        @media(max-width: 991px)
            img
                padding-left: 25px
    // @media(max-width:500px)
    //     display none

body.sticky
    .sticky-nav
        &.sticky
            top: 70px   

            @media( min-height: 500px )
                top: 103px             