footer
    width: 100%
    position: relative

    #scroll-up
        background-color: transparent
        border: none
        outline: none
        position: fixed
        bottom: 85px
        right: 4%
        visibility: hidden
        opacity: 0
        transition: 0.8s all ease
        z-index: 99
        border-radius: 50%
        cursor pointer
        padding: 0
        height: 42px
        width: 42px


        img
            display block
        .svg
            width: 10px
            height 22px
            position: absolute
            top: 50%
            left: 50%
            transform translate(-50%, -50%) rotate(-90deg)
        svg *
            fill: white !important

        &.visible
            visibility: visible
            opacity: 1

        &.sticky
            top: -31px
            position: absolute
            bottom: auto

    .footer-wrapper
        border-top: 2px solid #dfdfdf
        padding-top: 50px
        padding-bottom: 65px
        ul
            padding 0
            margin: 0

            li
                list-style-type: none
                &:before
                    content: none
        a
            text-decoration: none
        h4

            &.vertical-section-title
                margin-bottom: 35px
                @media(max-width: 1199px)
                    margin-bottom: 22px
        .col
            @media(max-width: 1199px)
                margin-bottom: 31px
        .row
            .col
                &:last-child
                    margin-bottom: 0



        .copyright
            text-align: right

            @media(max-width: 767px)
                text-align: center

        
        .pleogroup
            display: flex
            flex-direction: row

            h4
                margin-right: 22px
                @media(max-width: 991px)
                    margin-bottom: 35px

            ul
                display: flex
                flex-direction: row
                margin: 0
                padding: 0

                li
                    margin-right: 24px
                    list-style-type: none
                    @media(max-width: 991px)
                        margin-bottom: 35px
                    a
                        .svg
                            height: 19px 
                            width: 116px
                            opacity: 1
