.numeric-tabs
    .tab
        color: brand-color
        cursor: pointer
        padding: 0
        z-index: 1
        .up
            padding: 0 15px
            border-bottom: border-width solid light-grey
            position relative
            height 115px
            @media(max-width: 767px)
                height: 62px
            .numbers
                color: light-grey-2
                font-family: GloberHeavy
                position: absolute
                left: 50%
                transform: translate(-50%, 0px)
                bottom: 1px
                transition all .3s cubic-bezier(0.4,0,0.2,1)
                font-size: 65px
                @media(max-width: 1499px)
                    bottom: 3px
                @media(max-width: 992px)
                    bottom: 5px

                @media(max-width: 767px)
                    font-size: 46px
                    bottom: 0px
                    transition all .2s cubic-bezier(0.4,0,0.2,1)


            .circle-plus
                position: absolute
                border: border-width solid light-grey
                left: calc(50% - 22px)
                bottom: -22px
                &:before
                    background-color: light-grey
                &:after
                    background-color: light-grey
                    transition all .3s cubic-bezier(0.4,0,0.2,1)
                @media(max-width: 767px)
                    bottom: -17px
                    left: calc(50% - 16px)

        .down
            padding: 15px
            display: flex
            justify-content: center
            align-items: center
            height 100%
            height: 120px
            opacity: 0
            transition: .2s all ease-in-out
            line-height: normal
            @media(max-width: 767px)
                height inherit

        &.active
            .up
                .numbers
                    color: brand-color
                    font-size: 77px
                    transform: translate(-50%, -50px)
                    @media(max-width: 767px)
                        font-size: 50px
                        transform: translate(-50%, -27px)

            .circle-plus
                &:after
                    height: 12px
                    width: 2px
                    background-color: brand-color
                    border-radius: 50%
                    width: 12px
                    @media(max-width: 767px)
                        width: 10px
                        height: 10px
            .down
                opacity: 1
    .content

        opacity: 0
        height: 0
        min-height: 0
        transition: 0s all ease-out

        &.active
            opacity: 1
            height: auto
            transition: .5s all ease-out