.contact
    .contact-data
        margin-top: 50px

        @media( min-width: 768px )
            margin-top: 70px

        @media( min-width: 992px )
            margin-top: 90px

        @media( min-width: 1200px )
            margin-top: 110px

        ul
            margin: 0
            padding: 0
            li
                list-style-type: none
                &:before
                    content: none


        .contact-column
            margin-bottom: 20px
            a
                text-decoration: none

            @media( min-width: 768px )
                margin-bottom: 40px 

            &:last-child
                margin-bottom: 0
            
            &:nth-child(2n-1)
                clear: both

            @media( min-width: 992px )
                margin-bottom: 0    

                &:nth-child(2n-1)
                    clear: none

    .contact-form
        margin-top: 80px

        @media( min-width: 768px )
            margin-top: 100px

        @media( min-width: 992px )
            margin-top: 130px

        @media( min-width: 1200px )
            margin-top: 160px


        form
            label
                display: block
                width: 100%
                position: relative
                padding-top: 68px

                span
                    position absolute
                    top: 55px
                    left: 0
                    z-index: -1
                    transition: .2s top ease-out

                    @media( min-width: 992px )
                        top: 68px

                input,
                textarea
                    width: 100%
                    border-width: 0 0 2px 0
                    border-style: solid 
                    border-color: brand-color
                    background: transparent
                    padding-bottom: 0
                    box-shadow: none !important
                    outline: none
                    -webkit-border-radius:none
                    border-radius:none
                    -webkit-appearance:none
                    -webkit-border-radius:0

                    @media( min-width: 668px )
                        padding-bottom: 10px

                    @media( min-width: 992px )
                        padding-bottom: 25px

                &.focused
                    span
                        top: 31px

                &.required
                    span
                        &:after
                            content: " *"
                            color: brand-color

                &.error
                    span
                        color: brand-color
                        font-family: 'GloberxBold'

            #captcha-invalid
                color: brand-color

            .information
                margin-top: 60px
                margin-bottom: 30px


            button 
                background: transparent 
                border: none
                color: brand-color
                padding: 0
                box-shadow: none !important;
                outline: none;

                span 
                    color: brand-color
                    position: relative
                    padding-right: 50px
                    display: block

                    img,
                    svg
                        position: absolute
                        top: 60%
                        right: 0
                        transform: translateY( -50% )
                        display: inline-block
                        border: 2px solid brand-color
                        padding: 5px
                        border-radius: 100%
                        height: 25px
                        width: 25px
                        box-sizing: content-box
                        text-align: center

                        *
                            fill: brand-color