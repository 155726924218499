.title-container
    margin-bottom: 40px
    @media ( min-width: 500px )
        margin-bottom: 60px
    @media ( min-width: 768px )
        margin-bottom: 75px
    @media ( min-width: 992px )
        margin-bottom: 100px
    @media ( min-width: 1200px )
        margin-bottom: 125px

    .description
        margin-top: 33px
        @media(max-width:500px)
            margin-top: 20px

        p
            a
                color: brand-color