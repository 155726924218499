.baner
    position: relative
    background-repeat no-repeat
    background-size: cover
    background-position: center
    height: 100vh
    min-height: 30em
    padding-top: 20%
    padding-bottom: 9%
    @media(max-height: 960px) and (min-width: 1179px)
        padding-top: 13%
    @media(max-width: 777px)
        padding-top: 45%
    // .baner1,
    // .baner2,
    // .baner3
    //     width: 100%
    //     height: 100vh
    //     min-height: 600px
    //     padding-bottom: 120px
    //     position: relative
    //     background-repeat no-repeat
    //     background-size: cover
    //     background-position: center
    //     @media(max-width: 1200px)
    //         padding-bottom 150px    
    //     @media(max-width: 768px)
    //         padding-bottom 100px
    //     @media(max-width: 480px)
    //         padding-bottom 54px

    .container
        // top: 40%
        // position: absolute
        // left: 50%
        // transform: translateX(-50%)
        // height: 50%
        // @media(max-width: 777px)
        //     width: 100%

        .header
            color: white
            margin-bottom: 64px
            @media(max-width: 768px)
                margin-bottom 117px
            @media(max-width: 480px)
                margin-bottom 24px


            .additional-description
                margin-bottom: 6%
                h1
                    position relative
                    @media(max-width: 768px)
                        display: none
                    &:before
                        content: ''
                        width: 20px
                        height: 2px
                        background-color: white
                        position: absolute
                        top: 50%
                        left: -13px
                        -webkit-transform: translate(-100%,-50%)
                        transform: translate(-100%,-50%)
            .header
                font-family: 'GloberHeavy'
                margin-bottom: 38px

            .subheader
                font-family: 'GloberBook'

        .flex-wrapper
            display: flex
            flex-direction: column
            justify-content: space-between
            @media(max-width: 1200px)
                flex-direction: row
            .icons
                display: flex
                a
                    display: flex
                    flex-direction: column
                    align-items: center
                    margin-right: 45px
                    @media(max-width: 480px)
                        margin-right: 30px
                    
                    .svg
                        display flex
                        justify-content: center
                        align-items: center
                        width: 42px
                        height: 42px
                        margin-bottom: 12px

                    p
                        color: light-grey
                        font-family: 'GloberSemiBold'


            .arrows
                align-self: flex-end
                @media(max-width: 480px)
                    display: none
                a
                    margin-right: 22px

