.photo-text
    .row-flex
        display: flex
        align-items: center
        @media(max-width: 767px)
            display: flex
            flex-direction: column
            align-items: center
            
            img, video
                margin-bottom: 50px


