.numbers
    .up
        position relative
        z-index: 2
        &:before
            content: ''
            position absolute
            height border-width
            background-color: dark-blue
            width calc(100% + 30px)
            bottom: 18px
            left: -15px
            @media(max-width: 1499px)
                bottom: 13px
            @media(max-width: 1199px)
                bottom: 11px
            @media(max-width: 991px)
                bottom: 17px
            @media(max-width: 767px)
                max-width: 232px
                left: calc(50% - 16px)
                width: 100%
                transform: translateX(calc(-50% + 16px))
                bottom: 9px
        .f77
            @media(max-width: 767px)
                font-size: 55.2px
    .down
        max-width: 300px
        transform: translate(0, -48px)
        margin: 0 auto

        
        @media(max-width: 1199px)
            transform: translate(0,-41px)
            max-width: 200px
        @media(max-width: 991px)
            transform: translate(0,-47px)
        @media(max-width: 767px)
            transform: translate(0,-36px)


        .circle
            border-radius: 50%
            width 100%
            border: border-width solid white
            padding-bottom: 100%
        .desc
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            padding: 20px
            width: 100%