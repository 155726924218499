.filters
    display: flex
    justify-content: flex-start

    
    span
        color: brand-color
        font-family: GloberxBold

    span, .btn
        font-size: 1rem
        padding-top: 0
        padding-bottom: 0
        @media(max-width: 1499px)
            font-size: 20px
        @media(max-width: 500px)
            font-size: 18px


    .item-wrapper
        position: relative

        .btn-default
            position: absolute
            text-shadow: none
            box-shadow: none
            background-image: none
            background-color: transparent
            border-radius: 0
            border: none
            color: dark-blue
            // padding-bottom: 13px
            opacity: 0
            width: 0
            overflow hidden
            transition: all .2s ease-out
            line-height: inherit

            &.selected,
            &.active
                outline: none
                opacity: 1
                width: auto
                outline: none

            &.focus
                background-color: white          
                outline: none  

            &:after
                content: ''
                height: 2px
                background-color: brand-color
                display: block
                position: absolute
                bottom: 0
                left: 50%
                right: 50%
                transition: .1s all ease-out



    &.expanded
        .item-wrapper
            .btn-default
                opacity: 1
                width: auto
                position: relative
            
                &.active
                    font-family: 'GloberxBold'
                    color: brand-color

                &:hover
                    &:after
                        right: 0
                        left: 0
                