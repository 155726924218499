.start
    .how-do-we-work
        padding-top: 0

        @media( min-width: 500px )
            padding-top: 50px
        
        .container-fluid
            .content
                display: flex
                flex-direction: row
                justify-content: space-between
                align-items: stretch
                flex-wrap: wrap
                
                .how-do-w-work-item-wrapper
                    width: 100%
                    background-color: red
                    background-color: dark-blue
                    background-size: cover
                    background-position: top, center
                    position: relative
                    text-decoration: none
                        
                    @media( min-width: 768px )
                        width: 33.333333333%

                    .how-do-w-work-item-content                        
                        display: flex
                        flex-direction: column
                        justify-content: space-between
                        padding: 20px 15px

                        @media( min-width: 776px )                            
                            padding: 40 30px

                        @media( min-width: 992px )                            
                            padding: 75px 45px

                        @media( min-width: 1200px )
                            padding: 95px 75px


                        .bg-div
                            position: absolute
                            top: 0 
                            left: 0
                            width: 100%
                            height: 100%
                            background-color: alpha( dark-blue, .8 )
                            opacity: 0
                            transition: .2s opacity ease-out 
                        .f48
                            margin: 0 

                        .description
                            flex-direction: column
                            justify-content: flex-end 
                            display: flex
                            position: relative
                            z-index: 1
                            opacity: 0
                            transition: .2s opacity ease-out
                            padding: 20px 0 30px 

                            @media( min-width: 776px )    
                                padding: 30px 0 70px

                            @media( min-width: 992px )   
                                padding: 40px 0 100px

                            @media( min-width: 1200px )
                                padding: 50px 0 120px

                            @media(max-width: 1200px)
                                height 185px

                            p
                                color: white

                        .title
                            color: white
                            font-family: 'GloberxBold'
                            transition: .2s color ease-out
                            position: relative
                            z-index: 1

                        .btn-plus

                            color: white
                            transition: .2s color ease-out

                            .plus
                                border-color: white
                                transform: translateY(-50%) rotate( 0deg )
                                transition: .2s all ease-out


                                &:before,
                                &:after
                                    background-color: white
                                    transition: .2s background ease-out

                            &:hover
                                .plus
                                    transform: translateY(-50%) rotate( 0deg )

                            span
                                opacity: 0
                                transition: .2s opacity ease-out

                        @media(min-width: 1200px)

                            &:hover
                                .bg-div,
                                .description
                                    opacity: 1
                                    transition: .5s opacity ease-out

                                .title
                                    color: brand-color
                                    transition: .5s color ease-out

                                .btn-plus
                                    color: brand-color
                                    transition: .5s color ease-out
                                    
                                    .plus
                                        border-color: brand-color
                                        transition: .5s border ease-out,
                                            .2s transform ease-out
                                        transform: translateY(-50%) rotate( -90deg )

                                        &:before,
                                        &:after
                                            background-color: brand-color
                                            transition: .5s background ease-out
                                    
                                    span
                                        opacity: 1
                                        transition: .5s opacity ease-out


                            