.btn-plus
    display: inline-block
    position: relative
    padding-left: 50px
    text-transform: lowercase
    text-decoration: none
    &:hover, &:focus
        text-decoration: none
    @media( min-width: 992px )
        padding-left: 57px
    .plus
        display: block
        position: absolute
        top: 50%
        left: 0
        width: 32px
        height: 32px
        border: 2px solid dark-blue
        border-radius: 100%
        text-align: center
        transform: translateY(-50%) rotate(0)
        transition: .2s transform ease-out
        @media( min-width: 992px )
            width: 42px
            height: 42px

        &:before,
        &:after
            content: '';
            width: 52%;
            height: 2px;
            background-color: dark-blue;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

        &:after
            height: 52%
            width: 2px

    &:hover
        .plus
            transform: translateY(-50%) rotate( -90deg )

    &.btn-plus-red
        color: brand-color
        .plus
            border-color: brand-color
            &:before,
            &:after
                background-color: brand-color

    &.btn-plus-white
        color: white
        .plus
            border-color: white
            &:before,
            &:after
                background-color: white

.circle-plus
    display: block
    width: 42px
    height: 42px
    border: 2px solid dark-blue
    border-radius: 100%
    transform: rotate( 0deg )

    &:before,
    &:after
        content: ''
        width: 52%
        height: 2px
        background-color: dark-blue
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

    &:after
        height: 52%
        width: 2px
    @media(max-width: 767px)
        width: 32px
        height: 32px

    &.circle-plus-red
        border: 2px solid brand-color
        &:before,
        &:after
            background-color: brand-color

    &.circle-plus-white
        border: 2px solid white
        &:before,
        &:after
            background-color: white

.circle-minus
    display: block
    width: 42px
    height: 42px
    border: 2px solid dark-blue
    border-radius: 100%
    transform: rotate( 0deg )

    &:before
        content: ''
        width: 52%
        height: 2px
        background-color: dark-blue
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

    @media(max-width: 767px)
        width: 32px
        height: 32px

    &.circle-minus-white
        border: 2px solid white
        &:before
            background-color: white

.circle-small-plus
    border-radius: 50%
    border: 2px solid white
    width: 42px
    height 42px
    @media(max-width: 500px)
        width: 32px
        height 32px
    &:before,
    &:after
        content: ''
        position: absolute
        background-color white
        transform: translate(-50%,-50%)
    &:before
        left: 50%
        width: 2px
        height: 9px
        top: 50%
        
    &:after
        top: 50%
        left: 50%
        height 2px
        width 9px


.circle-small-minus
    border-radius: 50%
    border: 2px solid white
    width: 42px
    height 42px
    @media(max-width: 500px)
        width: 32px
        height 32px
    &:after
        content: ''
        position: absolute
        background-color white
        transform: translate(-50%,-50%)
    &:after
        top: 50%
        left: 50%
        height 2px
        width 9px

.circle-arrow
    border: 2px solid #eb594d
    padding: 3px 11px 3px 13px
    border-radius: 100%
    height: 32px
    width: 14px
    -webkit-box-sizing: content-box
    box-sizing: content-box
    text-align: center
    @media(max-width: 500px)
        padding: 4px 8px 4px 10px
        height: 20px
        width: 10px
    &.circle-arrow-white
        border: 2px solid white
    &.circle-arrow-white *
        fill: white !important

    &.circle-arrow-left
        transform rotate(180deg)

.circle-arrow *
    fill: brand-color !important
    height: 19px



.circle-btn
    color: #fff
    background-color: transparent
    border: none
    border-radius: 0
    text-align: left
    position: relative
    padding-left: 56px
    &:focus
        outline: none
    @media(max-width: 767px)
        padding-left: 50px

    span
        border-radius: 50%
        border: 2px solid white
        width: 42px
        height 42px
        position: absolute
        left: 0
        @media(max-width: 767px)
            width: 32px
            height 32px


        &:before,
        &:after
            content: ''
            display: block
            width: 60%
            height: 2px
            position: absolute
            top: 50%
            left: 50%
            background: white
            transform: translate(-50%, -50%)
            transition: all .25s ease-in-out

        &:before
            transform: translate(-50%,-50%) rotate(45deg)
        &:after
            transform: translate(-50%,-50%) rotate(-45deg)
