.overlay
	position: fixed 
	z-index: 1000000
	top: 0
	transform: translateY(-100%) 
	min-height: 100%
	left: 0
	right: 0
	will-change: transform
	transition: all 0.2s ease-in-out

	&.is-active
		transform: none

	.closebtn
		position absolute
		top 20px
		right 45px

	.overlay-content
		padding: 130px 0 100px
		position absolute
		top: 50%
		transform: translateY(-50%)
		width: 100%

		@media( min-width: 500px ) and ( min-height: 700px )
			padding: 0 0 30px

		@media ( max-height: 699px )		
			max-height: calc( 100vh - 170px )
			overflow-y: auto
			padding: 0 0 30px


		.wrapper
			min-width: 100%

			ul
				margin: 0
				padding: 0

				li
					list-style-type: none

					&.language-switcher
						font-size: 18px
						color: #e4e4e4
						a
							display: inline-block								
							span
								font-size: 18px
							&.active
								span
									font-family: GloberRegular

			@media( min-width: 992px ) and ( min-height 700px )
				display: inline-block
				position: absolute
				min-width: 360px
				left: 50%
				top: 50%
				transform: translate(-100%,-50%) 
				border-right: 1px solid #f27065
		 
			a
				font-family: 'GloberLight'
				text-decoration none
				color white
				display block
				line-height: 57px
				text-shadow: 1px 0px brand-color,
					-1px 0px brand-color,
					2px 0px brand-color,
					-2px 0px brand-color,
					3px 0px brand-color,
					-3px 0px brand-color,
					1px 2px brand-color,
					-1px 2px brand-color,
					2px 2px brand-color,
					-2px 2px brand-color,
					3px 2px brand-color,
					-3px 2px brand-color,
					1px 4px brand-color,
					-1px 4px brand-color,
					2px 4px brand-color,
					-2px 4px brand-color,
					3px 4px brand-color,
					-3px 4px brand-color,
					1px -2px brand-color,
					-1px -2px brand-color,
					2px -2px brand-color,
					-2px -2px brand-color,
					3px -2px brand-color,
					-3px -2px brand-color,
					1px -4px brand-color,
					-1px -4px brand-color,
					2px -4px brand-color,
					-2px -4px brand-color,
					3px -4px brand-color,
					-3px -4px brand-color,
					0 1px brand-color,
					0 -1px brand-color,
					0 2px brand-color,
					0 -2px brand-color,
					0 3px brand-color,
					0 -3px brand-color

				@media(max-height 900px)
					font-size: 30px
					line-height 43px
				@media(max-width: 1200px)
					font-size: 30px
					line-height 43px
				@media(max-height: 699px)
					font-size: 18px
					line-height 27px
				@media(max-width: 480px)
					font-size: 18px     
					line-height 27px

				span
					position: relative
					font-size: 24px
					line-height: 30px

					@media( min-width: 768px )
						font-size: 30px
						line-height: 36px

					@media( min-width: 1024px )
						font-size: 36px
						line-height: 42px

					&:before
						content: ''
						display: block
						position: absolute
						bottom: 0px
						top: calc( 100% - 1px )
						left: 50%
						right: 50%
						background: white
						z-index: -1
						transition: .2s all ease-out
							  
				&.active
					> span
						color #ba3a30
						font-family: 'GloberxBold'

						&:before
							top: calc( 100% - 5px )
							bottom: 4px
							background-color: #ba3a30

				
				&:hover	
					> span
						&:before
							left: 0
							right: 0

			.bottom-menu
				margin-top: 30px

				a

					span
						color: #ff968d
						position: relative
						padding-right: 50px

						img,
						svg
							position: absolute
							top: 50%
							right: 0
							transform: translateY( -50% )
							display: inline-block
							border: 2px solid #ff968d
							padding: 5px
							border-radius: 100%
							height: 25px
							width: 25px
							box-sizing: content-box
							text-align: center
							box-shadow: 2px 0 brand-color, 
								-2px 0 brand-color, 
								0 2px brand-color, 
								0 -2px brand-color
							height: 15px
							width: 15px

							@media( min-width: 992px )
								height: 25px
								width: 25px

							*
								fill: #ff968d

						&:before
							background-color: #ff968d

			> span 
				color: white
				font-size: 24px
				font-family: 'GloberLight'

	.container-bottom
		position: absolute
		display: flex
		color: white
		font-size: 14px
		flex-wrap: wrap
		line-height: 17px
		bottom: 10px

		@media( min-width: 768px )
			left: 50%
			transform: translateX(-50%)

		@media( min-height: 500px ) and ( min-width: 500px )
			bottom: 50px

		@media( min-height: 700px )
			bottom: 35px

		@media( min-width: 992px )
			bottom: 80px

		.wrapper
			display: flex
			justify-content: space-between
			width: 100%
			flex-wrap: wrap

			.item-wrapper
				align-self: flex-end
				display: flex
				flex-wrap wrap


				.mail,
				.social
					a
						color: white
						font-size: 18px
						line-height: 45px
						text-decoration: none
				
				@media(max-width: 480px)
					flex-direction: column
					.mail
						margin-bottom: 5px
				p
					margin-right:26px
					line-height: 45px 
				a
					margin-right: 16px

			
			.lang
				a
					color: white
					position: relative
					margin-right: 10px
					font-size: 18px
					line-height: 45px 
					text-decoration: none

					&:after
						cursor: default
						content: "/"
						position: absolute
						right: -12px
						font-size: 20px
						pointer-events: none

					&:last-child
						margin-right: 0

						&:after
							display: none

			.autor
				font-family 'GloberSemiBold'



	&.pleo-red
		background-color: brand-color

	.right-side
		p
			color: white

	.svg
		*
			fill: white
			


.navigation
	position: absolute
	top: 0
	width: 100%
	z-index: 200

	@media (min-height: 700px) and (min-width: 992px)
		top: 50px

	.wrapper
		position: absolute
		top: 0
		width: 100%
		padding: 10px 0 

		@media (min-height: 500px)
			padding: 25px 0 

		.container
			display: flex
			justify-content: space-between
			align-items: center

			&:before,
			&:after
				display: none

			.svg    
				width: 152px
				height: 34px
				transition all 0.3s ease-out
				margin-top: 6px

				@media( min-width: 769px ) and (min-height: 700px)
					width: 209px
					height: 48px					

				*
					transition fill 0.3s ease-out

			.case-studies
				color: white

				&.pleo-red
					color: dark-blue
					text-decoration: none
					@media ( min-width: 768px )
						margin-top: -3px

			.right-side
				display: flex
				align-items: center

				a
					transition: .3s color ease-out
					text-decoration: none


				p
					font-size: 24px
					font-family: 'GloberxBold'
					margin-right: 12px
					display: none
					transition font-size 0.3s ease-out

					@media( min-width: 600px )
						display: inline
				.back
					display: flex
					position: relative
					padding-left 9px
					margin-top 0 !important
					&:before, &:after
						content: ""
						position: absolute
						background-color: white
						width: 4px
						height: 1px
						transform-origin: 0 50%
						top: 50%
						left: 0
						@media(max-width: 599px )
							display: none
					&.pleo-red
						&:before, &:after
							background-color: dark-blue

						
					&:before
						transform: rotate(45deg)
					&:after
						transform: rotate(-45deg)
					p
						font-size: 12px
						margin-right: 45px



				.menu-toggle
					.hamburger
						height: 42px
						width: 42px
						display: flex
						justify-content: center
						align-items: center
						cursor: pointer
						z-index: 1000
						transition all 0.3s ease-out
						position: relative
						background-color: transparent
						box-shadow: none
						border: 1px solid white
						border-radius: 100px
						outline: none

						*
							pointer-events: none

						@media( min-height: 500px )
							width: 42px
							height: 42px			
						
						.menu-cross
							position: absolute
							top: 50%
							left: 50%
							transform: translate(-50%,-50%)
							display: block
							width: 14px
							height: 12px
							background: transparent
							border-top: 2px solid
							border-bottom: 2px solid
							border-color: white
							font-size: 0
							transition: all .25s ease-in-out

							@media( min-width: 500px )
								width: 20px

							&:before,
							&:after
								content: ''
								display: block
								width: 100%
								height: 2px
								position: absolute
								top: 50%
								left: 50%
								background: white
								transform: translate(-50%, -50%)
								transition: all .25s ease-in-out

						button
							border: none
							outline: none

						&.pleo-red
							border-color: brand-color
							
							.menu-cross
								border-color: brand-color
								
								&:before,
								&:after
									background: brand-color

						&.is-active
							border-color: white
					
							.menu-cross
								border-color: transparent
								&:before
									transform: translate(-50%, -50%) rotate(45deg)
									background-color: white !important
						
								&:after
									transform: translate(-50%, -50%) rotate(-45deg)
									background-color: white !important

						&:hover
							.circle-chart
								.circle-chart-circle
									transition: .5s stroke-dasharray ease-out, .1s opacity ease-out
									transition-delay: 0s, 0.49s
									stroke-dasharray: 0 100
									opacity: 0

		

		&:before
			content: ''
			position: absolute
			top: -200%
			left: 0
			right: 0
			height: 100%
			background-color: white
			z-index: -1
			transition: .5s all ease-out

	&.main
		&.floating-nav
			.wrapper
				position: fixed
				transition: none
				transform: translateY( -100% )

		&:not(.sticky)
			.wrapper
				.container
					.svg
						&.white
							.circle-chart-circle
								stroke: white
							path
								fill: white
						

		&.sticky
			.wrapper
				transform: translateY( -100% )
				transition: .2s transform ease-out

				.container
					transition: .2s all ease-out

					.svg    
						width: 152px
						height: 34px

					.right-side
						a
							color: dark-blue
							display: none
							
							
							@media( min-width: 450px )
								display: inline
							
							p
								font-size: 18px

						.back
							display: flex
							&:before, &:after
								background-color: dark-blue
							@media( max-width: 499px )
								display: none
							
							p
								font-size: 12px

					.hamburger
						height: 32px
						width: 32px
						border-color: dark-blue
						
						.menu-cross
							border-color: dark-blue
							width: 14px
							height: 10px

							&:before,
							&:after
								background-color: dark-blue

				&:before
					top: 0
					transition: .1s all ease-out

			&.show

				.wrapper
					transform: translateY( 0 )
					transition: .5s transform ease-out
