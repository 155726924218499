.image-on-background
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    position relative
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    opacity: 0.11