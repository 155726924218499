.next-previous
    a
        text-decoration: none
        display flex
        flex-direction: column

    .row
        .item
            &:first-child
                a
                    align-items: flex-end
                    margin-right: 6%
                    p
                        text-align: right

            &:nth-child(2)
                a
                    margin-left: 5%

            .svg
                margin-bottom: 15px

            p
                line-height: normal

            .title
                display none
                font-weight: bold

            @media(min-width: 1200px)

                &:hover
                    .text
                        display none

                    .title
                        display block
