.previous-next
    display: flex
    justify-content: center
 
    .wrapper
        display: flex
        justify-content: space-between
        @media(max-width: 375px)
            justify-content: center
        a
            display: flex
            align-items: center
            text-decoration: none
            @media(max-width: 375px)
                &:first-child
                    img 
                        margin-right: 30px

        p
            color: light-blue
            padding: 0 15px
        .hide-text
            @media(max-width: 375px)
                display: none
        img 
            width: 27px
            height: 17px