.technologies
    .container
        .row
            .row
                .svg
                    // // height 40px
                    // // width: 140px
                    // margin-bottom: 50px
                    // @media(max-width: 767px)
                    //     // height: 30px
                    //     // width: 130px
                    //     margin-bottom: 40px
                .svg,
                .svg *
                    &,
                    & *
                        opacity: 0.8
                        filter: grayscale(100%)
                        -webkit-filter: grayscale(100%)
                        transition: all 0.3s ease-out
                        // @media(max-width: 767px)
                            // opacity: 1
                            // filter: grayscale(0%)
                            // -webkit-filter: grayscale(0%)
                .col-svg
                    &:hover
                        .svg,
                        .svg *
                            &,
                            & *
                                opacity: 1 !important
                                filter: grayscale(0%) !important
                                -webkit-filter: grayscale(0%) !important

                // @media(max-width: 767px)
                //     .col-svg
                //         &:nth-last-child(-n+2)
                //             .svg
                //                 margin-bottom: 0