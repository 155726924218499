.contact-form
    form
        label
            display: block
            width: 100%
            position: relative
            padding-top: 68px
            font-weight: normal

            span
                position absolute
                top: 55px
                left: 0
                // z-index: -1
                transition: .2s top ease-out

                @media( min-width: 992px )
                    top: 68px

            input,
            textarea
                width: 100%
                border-width: 0 0 2px 0
                border-style: solid 
                border-color: brand-color
                background: transparent
                padding-bottom: 0
                box-shadow: none !important
                outline: none
                -webkit-border-radius:none
                border-radius:none
                -webkit-appearance:none
                -webkit-border-radius:0

                @media( min-width: 668px )
                    padding-bottom: 10px

                @media( min-width: 992px )
                    padding-bottom: 25px

            &.focused
                span
                    top: 31px


            // input
            //     &::-webkit-input-placeholder
            //         color: dark-blue
            //         text-align: center
            //     &::-moz-placeholder
            //         color: dark-blue
            //         text-align: center
            //     &:-ms-input-placeholder
            //         color: dark-blue
            //         text-align: center
            //     &:-moz-placeholder
            //         color: dark-blue
            //         text-align: center
            //     &:-webkit-autofill
            //         -webkit-box-shadow: none
            //         -webkit-box-shadow: 0 0 0 50px white inset !important
            //         background-color: white !important
            //     &:focus
            //         outline: none
            //         box-shadow: none
            //         &:-webkit-autofill
            //             -webkit-box-shadow: none
            //             -webkit-box-shadow: 0 0 0 50px white inset !important
            //             background-color: white !important
input
    background-color: transparent
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active 
    -webkit-transition-delay: 99999s
