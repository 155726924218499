.circles
    text-transform: uppercase
    *
        box-sizing border-box
    .circle-container
        position relative
        margin 0 auto
        // @media(min-width: 31.25rem)
        //     // width 36.25rem
        //     // max-width 100%     
        &:before 
            content: ''
            width 100%
            display block
            padding-top 100%
        .plus
            position: absolute 
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 42px
            height: 42px
            z-index 6
            @media(max-width: 425px)
                width: 24px
                height: 24px
            &:before,
            &:after
                content: ''
                width: 52%
                height: 2px
                background-color: brand-color
                position: absolute
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%)
            &:after
                height: 52%
                width: 2px

        .circle-container__inner
            position absolute
            top 0
            left 0
            width 100%
            height 100%
            // padding 7.1875rem

            .inner-circle
                height 100%
                border-radius 50%
                position relative
                .inner-circle__item
                    border-radius 50%
                    text-align center
                    mix-blend-mode multiply
                    margin 0 auto
                    width 55%
                    height 55%
                    position absolute
                    margin 0
                    padding 10%

                    span
                        display block
                        position relative
                        top 50%
                        transform translateY(-50%)
                        font-family sans-serif
                    &:nth-child(1)
                        background-color: rgba(brand-color, 0.1)
                        top 50%
                        transform: translateY(-50%)
                        left 0
                        z-index: 2
                        transition: all 0.5s linear
                        border: 2px solid dark-blue
                        &:hover
                            background-color: rgba(brand-color, 0.5)
                            z-index: 5

                    &:nth-child(2)
                        background-color: rgba(light-grey, 0.1)
                        top 50%
                        transform: translateY(-50%)
                        right 0
                        transition: all 0.5s linear
                        z-index: 3
                        border: 2px solid dark-blue
                        &:hover
                            background-color: rgba(light-grey, 0.9)
                            z-index 5