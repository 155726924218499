.menu-design
  width: 100%

.menu-right
  width: 1100px
  margin: 0 auto
  @media(max-width: 1200px)
    width: 900px
    padding: 0 40px
  @media(max-width: 768px)
    width: 700px
    padding: 0 40px

  @media(max-width: 700px)
    width: 400px
.menu-left
  float: left
  height: 54px
  margin-top: 6px
  margin-bottom: 3px
  a
    line-height: 24px
    img
      width: auto
      max-width: 100%
      border: 0
      @media(max-width: 1010px)
        max-height: 40px


.menu-nav
  z-index: 50
  float: right
  display: inline-block
  line-height: 24px


.menu-ul
  @media(max-width: 1010px)
    display: none
  position: relative
  z-index: 49
  margin: 0
  padding: 0
  list-style: none
  line-height: 24px

  li
    position: relative
    z-index: 45
    float: left
    font-size: 13px
    margin-left: 30px
    padding: 0
    line-height: 20px
    list-style-position: outside
    @media(max-width: 1200px)
      margin-left: 20px

    &::before
      background-color: inherit
    a
        font-size: 13px
        font-family: 'Lato', Helvetica, Arial
        text-transform: none
        display: block
        line-height: 54px
        color: #002c41
        position: relative
        z-index: 45
        outline-color: rgb(0, 44, 65)
        text-decoration-style: solid
        font-weight: 400
        border-image-width: 1
        text-decoration: none
        &:hover
           color: #05c3de



.hamburger-wrapper
  @media(min-width: 1010px)
    display: none
  position: absolute
  top: 33%
  left: 75%
  .btn-ham
    span
      display: block
      width: 25px
      height: 3px
      margin-bottom: 5px
      position: relative
      background: #232323
      border-radius: 3px
      z-index: 1

    input
      display: block
      width: 25px
      height: 25px
      position: absolute
      top: -7px
      left: -5px
      cursor: pointer
      opacity: 0
      z-index: 2
      -webkit-touch-callout: none
      &:checked
        & ~ span
          opacity: 1
          background: #232323
        &:nth-last-child(3)
          opacity: 0


  .hamburger-design
    transform: translateX(150%);
    opacity: 0
    &.is-active
      display: block
      position: absolute
      opacity: 1
      background: #1a1a1a
      padding: 0
      top: -53px
      right: -90px
      min-height: 2500px
      width: 340px
      z-index: 122248222001
      box-shadow: rgb(0 0 0 / 20%) 0px 0px 3px
      -moz-box-shadow: rgba(0,0,0, 0.2) 0px 0px 3px
      -webkit-box-shadow: rgb(0 0 0 / 20%) 0px 0px 3px
      border-left: 1px solid #333333
      transform-origin: 0% 0%
      transform: translateX(25%)
      transition: transform 1s ease-out
      @media(min-width: 760px)
        transform: translateX(40%)

      .menu-responsive
        .close-responsive-nav
          width: 100%
          height: 60px
          display: block
          postion: relative
          cursor: pointer
          span
            width: 20px
            height: 20px
            display: inline-block
            opacity: 0.3
            &::hover
              opacity: 1
            &::before, &::after
              position: absolute
              left: 30px
              content: ' '
              height: 20px
              width: 2px
              background-color: white
              top: 20px
            &::before
              transform: rotate(45deg)
            &::after
              transform: rotate(-45deg)
        .menu-ul-ham

              border-bottom: 1px solid #333333
              list-style: none
              margin: 0
              padding: 0
              li
                    margin: 0
                    color: #bfbfbf
                    padding: 0
                    cursor: pointer
                    border-top: 1px solid #333333
                    position: relative
                    text-decoration: none
                    &::before
                      background-color: inherit
                    &::focus
                      outline: 0

                    a
                      line-height: 30px
                      padding: 10px 20px
                      display: block
                      font-size: 13px
                      font-family: 'Lato', Helvetica, Arial
                      font-weight: normal
                      text-transform: none
                      text-decoration: none










