html

	body
		color: dark-blue
		font-family: 'GloberRegular'
		transition: .2s all ease-in-out
		top: 0
		position: relative

		&.no-scroll
			overflow: hidden

			main
				@media( min-width: 1350px )
					padding-top: 100vh

			.os-scrollbar-handle
				display: none

		.slick-slide
			outline: none

		main
			overflow hidden
			transition: .2s all ease-in-out
			@media (min-width: 1500px)
				.container
					width: 1484px

			// li
			//     list-style-type: none
			.space-between

				padding-top: 100px
				@media ( min-width: 500px )
					padding-top: 143px
				@media ( min-width: 992px )
					padding-top: 210px
				@media ( min-width: 1200px )
					padding-top: 225px
				@media ( min-width: 1550px )
					padding-top: 255px

				padding-bottom: 40px
				@media( min-width: 500px )
					padding-bottom: 70px
				@media( min-width: 768px )
					padding-bottom: 90px
				@media( min-width: 992px )
					padding-bottom: 125px
				@media( min-width: 1200px )
					padding-bottom: 155px
				@media( min-width: 1500px )
					padding-bottom: 175px

			.svg
				opacity: 0
				&.replaced-svg
					opacity: 1

			img
				max-width: 100%
				height auto

			.mb-150
				margin-bottom: 8.333rem

			.mb-120
				margin-bottom: 6.667rem

			.mb-80
				margin-bottom: 4.444rem

			.mb-50
				margin-bottom: 2.778rem

			.pt-150
				padding-top: 150px

				@media(max-width: 1200px)
					padding-top: 80px

				@media(max-width: 375px)
					padding-top: 60px

			.pb-150
				padding-bottom: 150px

				@media(max-width: 1200px)
					padding-bottom: 80px

				@media(max-width: 375px)
					padding-bottom: 60px

			.pt-90
				padding-top: 5rem

			.pb-90
				padding-bottom: 5rem

			.pt-75
				padding-top: 75px

				@media(max-width: 1200px)
					padding-top: 40px

				@media(max-width: 375px)
					padding-top: 30px

			.pb-75
				padding-bottom: 75px

				@media(max-width: 1200px)
					padding-bottom: 40px

				@media(max-width: 375px)
					padding-bottom: 30px

			.szary
				background-color: #eeeeee
				position: relative
				h2
					color: brand-color


			.bialy
				background-color: white
				position: relative

			.czarny
				background-color: black
				position: relative


			.granatowyCiemnyPcs
				background-color: #000057
				color: white

			.granatowyJasnyPcs
				background-color: #26009E
				color: white

			.turkusowyPcs
				background-color: #00E8CE
				color: white

			.bialyPcs
				background-color: #F9F9F9

			.granatowy
				background-color: dark-blue
				color: white
				position: relative
				.section-title
					&:before
						background-color: white

			.pomaranczowy
				background-color: brand-color
				position: relative
				h2
					color: white


			a
				color: currentColor
				text-decoration: underline
				&:hover
					color: currentColor
				&:focus
					color: currentColor
					outline: none

			strong
				font-weight: 700

	svg, svg *
		pointer-events: none

   .section-title
		font-family: "GloberxBold"
		position: relative
		line-height: 32px
		margin-bottom: 25px
		@media( min-width: 500px)
			margin-bottom: 40px
		@media ( min-width: 992px)
			margin-bottom: 56px
		&:before
			content: ''
			width: 20px
			height: 2px
			background-color: dark-blue
			position: absolute
			top: 50%
			left: -13px
			transform: translate( -100%, -50%)




.popup-contact
	position: fixed
	z-index: 30
	left: 0
	top: 0
	width: 100%
	display: none
	background-color: brand-color
	height: 0
	z-index : 201
	:first-child
		margin-bottom: 5px
	p
		margin-bottom:40px

	.popup-contact-border
		width: 480px
		margin: 0 15px
		position relative
		padding: 40px
		background-color: brand-color
	&.visible
		height: 100%
		z-index 1010
		display: flex
		align-items: center
		justify-content: center
