.start
    .news-section-container
        padding-top: 70px
        padding-bottom: 25px

        @media ( min-width: 500px )
            padding-top: 80px
            padding-bottom: 35px
        @media ( min-width: 768px )
            padding-top: 95px
            padding-bottom: 40px
        @media ( min-width: 992px )
            padding-top: 105px
            padding-bottom: 45px
        @media ( min-width: 1200px )
            padding-top: 115px
            padding-bottom: 50px