.layout-design
  position: relative
  height: 100%

.nav-design
  position: fixed
  top: 0
  z-index: 2000
  padding: 40px 0
  width: 100%
  transition: 0.4s
  background-color: white


