html body {
  color: #142832;
  font-family: 'GloberRegular';
  transition: 0.2s all ease-in-out;
  top: 0;
  position: relative;
}
html body.no-scroll {
  overflow: hidden;
}
@media (min-width: 1350px) {
  html body.no-scroll main {
    padding-top: 100vh;
  }
}
html body.no-scroll .os-scrollbar-handle {
  display: none;
}
html body .slick-slide {
  outline: none;
}
html body main {
  overflow: hidden;
  transition: 0.2s all ease-in-out;
}
@media (min-width: 1500px) {
  html body main .container {
    width: 1484px;
  }
}
html body main .space-between {
  padding-top: 100px;
  padding-bottom: 40px;
}
@media (min-width: 500px) {
  html body main .space-between {
    padding-top: 143px;
  }
}
@media (min-width: 992px) {
  html body main .space-between {
    padding-top: 210px;
  }
}
@media (min-width: 1200px) {
  html body main .space-between {
    padding-top: 225px;
  }
}
@media (min-width: 1550px) {
  html body main .space-between {
    padding-top: 255px;
  }
}
@media (min-width: 500px) {
  html body main .space-between {
    padding-bottom: 70px;
  }
}
@media (min-width: 768px) {
  html body main .space-between {
    padding-bottom: 90px;
  }
}
@media (min-width: 992px) {
  html body main .space-between {
    padding-bottom: 125px;
  }
}
@media (min-width: 1200px) {
  html body main .space-between {
    padding-bottom: 155px;
  }
}
@media (min-width: 1500px) {
  html body main .space-between {
    padding-bottom: 175px;
  }
}
html body main .svg {
  opacity: 0;
}
html body main .svg.replaced-svg {
  opacity: 1;
}
html body main img {
  max-width: 100%;
  height: auto;
}
html body main .mb-150 {
  margin-bottom: 8.333rem;
}
html body main .mb-120 {
  margin-bottom: 6.667rem;
}
html body main .mb-80 {
  margin-bottom: 4.444rem;
}
html body main .mb-50 {
  margin-bottom: 2.778rem;
}
html body main .pt-150 {
  padding-top: 150px;
}
@media (max-width: 1200px) {
  html body main .pt-150 {
    padding-top: 80px;
  }
}
@media (max-width: 375px) {
  html body main .pt-150 {
    padding-top: 60px;
  }
}
html body main .pb-150 {
  padding-bottom: 150px;
}
@media (max-width: 1200px) {
  html body main .pb-150 {
    padding-bottom: 80px;
  }
}
@media (max-width: 375px) {
  html body main .pb-150 {
    padding-bottom: 60px;
  }
}
html body main .pt-90 {
  padding-top: 5rem;
}
html body main .pb-90 {
  padding-bottom: 5rem;
}
html body main .pt-75 {
  padding-top: 75px;
}
@media (max-width: 1200px) {
  html body main .pt-75 {
    padding-top: 40px;
  }
}
@media (max-width: 375px) {
  html body main .pt-75 {
    padding-top: 30px;
  }
}
html body main .pb-75 {
  padding-bottom: 75px;
}
@media (max-width: 1200px) {
  html body main .pb-75 {
    padding-bottom: 40px;
  }
}
@media (max-width: 375px) {
  html body main .pb-75 {
    padding-bottom: 30px;
  }
}
html body main .szary {
  background-color: #eee;
  position: relative;
}
html body main .szary h2 {
  color: #eb594d;
}
html body main .bialy {
  background-color: #fff;
  position: relative;
}
html body main .czarny {
  background-color: #000;
  position: relative;
}
html body main .granatowyCiemnyPcs {
  background-color: #000057;
  color: #fff;
}
html body main .granatowyJasnyPcs {
  background-color: #26009e;
  color: #fff;
}
html body main .turkusowyPcs {
  background-color: #00e8ce;
  color: #fff;
}
html body main .bialyPcs {
  background-color: #f9f9f9;
}
html body main .granatowy {
  background-color: #142832;
  color: #fff;
  position: relative;
}
html body main .granatowy .section-title:before {
  background-color: #fff;
}
html body main .pomaranczowy {
  background-color: #eb594d;
  position: relative;
}
html body main .pomaranczowy h2 {
  color: #fff;
}
html body main a {
  color: currentColor;
  text-decoration: underline;
}
html body main a:hover {
  color: currentColor;
}
html body main a:focus {
  color: currentColor;
  outline: none;
}
html body main strong {
  font-weight: 700;
}
html svg,
html svg * {
  pointer-events: none;
}
.section-title {
  font-family: "GloberxBold";
  position: relative;
  line-height: 32px;
  margin-bottom: 25px;
}
@media (min-width: 500px) {
  .section-title {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .section-title {
    margin-bottom: 56px;
  }
}
.section-title:before {
  content: '';
  width: 20px;
  height: 2px;
  background-color: #142832;
  position: absolute;
  top: 50%;
  left: -13px;
  transform: translate(-100%, -50%);
}
.popup-contact {
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  width: 100%;
  display: none;
  background-color: #eb594d;
  height: 0;
  z-index: 201;
}
.popup-contact :first-child {
  margin-bottom: 5px;
}
.popup-contact p {
  margin-bottom: 40px;
}
.popup-contact .popup-contact-border {
  width: 480px;
  margin: 0 15px;
  position: relative;
  padding: 40px;
  background-color: #eb594d;
}
.popup-contact.visible {
  height: 100%;
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: 'GloberRegular';
  src: url("../font/globerregular.eot");
  src: url("../font/globerregular.eot?#iefix") format('embedded-opentype'), url("../font/globerregular.woff2") format('woff2'), url("../font/globerregular.woff") format('woff'), url("../font/GloberRegular.otf") format("opentype"), url("../font/globerregular.svg") format('svg');
}
@font-face {
  font-family: 'GloberHeavy';
  src: url("../font/globerheavy.eot");
  src: url("../font/globerheavy.eot?#iefix") format('embedded-opentype'), url("../font/globerheavy.woff2") format('woff2'), url("../font/globerheavy.woff") format('woff'), url("../font/GloberHeavy.otf") format("opentype"), url("../font/globerheavy.svg") format('svg');
}
@font-face {
  font-family: 'GloberxBold';
  src: url("../font/globerxbold.eot");
  src: url("../font/globerxbold.eot?#iefix") format('embedded-opentype'), url("../font/globerxbold.woff2") format('woff2'), url("../font/globerxbold.woff") format('woff'), url("../font/GloberxBold.otf") format("opentype"), url("../font/globerxbold.svg") format('svg');
}
@font-face {
  font-family: 'GloberBook';
  src: url("../font/globerbook.eot");
  src: url("../font/globerbook.eot?#iefix") format('embedded-opentype'), url("../font/globerbook.woff2") format('woff2'), url("../font/globerbook.woff") format('woff'), url("../font/GloberBook.otf") format("opentype"), url("../font/globerbook.svg") format('svg');
}
@font-face {
  font-family: 'GloberSemiBold';
  src: url("../font/globersemibold.eot");
  src: url("../font/globersemibold.eot?#iefix") format('embedded-opentype'), url("../font/globersemibold.woff2") format('woff2'), url("../font/globersemibold.woff") format('woff'), url("../font/GloberSemiBold.otf") format("opentype"), url("../font/globersemibold.svg") format('svg');
}
@font-face {
  font-family: 'GloberLight';
  src: url("../font/globerlight.eot");
  src: url("../font/globerlight.eot?#iefix") format('embedded-opentype'), url("../font/globerlight.woff2") format('woff2'), url("../font/globerlight.woff") format('woff'), url("../font/GloberLight.otf") format("opentype"), url("../font/globerlight.svg") format('svg');
}
html {
  font-size: 24px;
}
@media (max-width: 1499px) {
  html {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  html {
    font-size: 18px;
  }
}
html body {
  font-size: 24px;
  line-height: 1.875rem;
}
@media (max-width: 1199px) {
  html body {
    font-size: 22px;
  }
}
@media (max-width: 991px) {
  html body {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  html body {
    font-size: 18px;
  }
}
html main h1,
html main h2,
html main h3,
html main h4,
html main h5,
html main h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}
html main ul {
  list-style-type: none;
}
@media (max-width: 768px) {
  html main ul {
    padding-left: 20px;
  }
}
html main ul li {
  position: relative;
}
html main ul li:before {
  position: absolute;
  content: '';
  background-color: #eb594d;
  border-radius: 50%;
  top: 22px;
  left: -40px;
  height: 6px;
  width: 6px;
}
@media (max-width: 768px) {
  html main ul li:before {
    left: -20px;
    top: 10px;
  }
}
html main p {
  line-height: 1.875rem;
  margin: 0;
}
html main .f90 {
  font-size: 3.75rem;
  font-family: 'GloberHeavy';
  line-height: 110px;
}
@media (max-width: 1499px) {
  html main .f90 {
    font-size: 80px;
    line-height: 100px;
  }
}
@media (max-width: 1199px) {
  html main .f90 {
    font-size: 60px;
    line-height: 70px;
  }
}
@media (max-width: 991px) {
  html main .f90 {
    font-size: 50px;
    line-height: 70px;
  }
}
@media (max-width: 767px) {
  html main .f90 {
    font-size: 45px;
    line-height: 54px;
  }
}
@media (max-width: 500px) {
  html main .f90 {
    font-size: 35px;
    line-height: 45px;
  }
}
html main .f77 {
  font-family: 'GloberHeavy';
  font-size: 3.2rem;
  line-height: 4.041666666666667rem;
}
@media (max-width: 1499px) {
  html main .f77 {
    font-size: 70.4px;
    line-height: 77px;
  }
}
@media (max-width: 1199px) {
  html main .f77 {
    font-size: 59.4px;
    line-height: 61.59px;
  }
}
@media (max-width: 991px) {
  html main .f77 {
    font-size: 50px;
    line-height: 52px;
  }
}
@media (max-width: 767px) {
  html main .f77 {
    font-size: 41.4px;
    line-height: 45px;
  }
}
@media (max-width: 500px) {
  html main .f77 {
    font-size: 27px;
    line-height: 32.4px;
  }
}
html main .f48 {
  font-size: 2rem;
  font-family: 'GloberxBold';
  line-height: 2.416666666666667rem;
  margin-bottom: 60px;
}
@media (max-width: 1499px) {
  html main .f48 {
    font-size: 42px;
    line-height: 52px;
  }
}
@media (max-width: 768px) {
  html main .f48 {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 640px) {
  html main .f48 {
    font-size: 35px;
    line-height: 43px;
  }
}
@media (max-width: 576px) {
  html main .f48 {
    font-size: 30px;
    line-height: 34px;
  }
}
@media (max-width: 1500px) {
  html main .f48 {
    margin-bottom: 40px;
  }
}
@media (max-width: 375px) {
  html main .f48 {
    margin-bottom: 20px;
  }
}
html main .f48 p {
  font-size: 2rem;
  font-family: 'GloberxBold';
  line-height: 2.416666666666667rem;
}
@media (max-width: 1499px) {
  html main .f48 p {
    font-size: 42px;
    line-height: 52px;
  }
}
@media (max-width: 768px) {
  html main .f48 p {
    font-size: 36px;
    line-height: 46px;
  }
}
@media (max-width: 640px) {
  html main .f48 p {
    font-size: 35px;
    line-height: 43px;
  }
}
@media (max-width: 576px) {
  html main .f48 p {
    font-size: 30px;
    line-height: 34px;
  }
}
@media (min-width: 600px) {
  html main .f38 {
    font-size: 1.583333333333333rem;
    line-height: 2.125rem;
  }
}
html main .f30 {
  font-size: 1.25rem;
}
html main .f24 {
  font-size: 1rem;
}
html main .f24 h2 {
  font-size: 1rem;
}
html main .f18 {
  font-size: 0.75rem;
  line-height: 0.833333333333333rem;
}
html main .f18 p {
  font-size: 0.75rem;
  line-height: 0.833333333333333rem;
}
html main .f16 {
  font-size: 0.666666666666667rem;
}
html main .f14 {
  font-size: 14px;
}
html main .f12 {
  font-size: 12px;
  line-height: 24px;
}
html main .lh110 {
  line-height: 4.583333333333333rem;
}
html main .lh97 {
  line-height: 4.041666666666667rem;
}
html main .lh80 {
  line-height: 3.333333333333333rem;
}
html main .lh45 {
  line-height: 1.875rem;
}
html main .lh45 p {
  line-height: 1.875rem;
}
html main .lh45 h2 {
  line-height: 1.875rem;
}
html main .lh36 {
  line-height: 1.5rem;
}
html main .lh36 p {
  line-height: 1.5rem;
}
html main .lh32 {
  line-height: 1.333333333333333rem;
}
html main .lh24 {
  line-height: 24px;
}
html main .lh24 p {
  line-height: 24px;
}
html main .GX {
  font-family: 'GloberxBold';
}
html main .GB {
  font-family: 'GloberBook';
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.margin.extra-large {
  padding-top: 100px;
}
@media (min-width: 500px) {
  .margin.extra-large {
    padding-top: 143px;
  }
}
@media (min-width: 992px) {
  .margin.extra-large {
    padding-top: 210px;
  }
}
@media (min-width: 1200px) {
  .margin.extra-large {
    padding-top: 225px;
  }
}
@media (min-width: 1550px) {
  .margin.extra-large {
    padding-top: 255px;
  }
}
.margin.large {
  padding-top: 170px;
}
@media (max-width: 1200px) {
  .margin.large {
    padding-top: 100px;
  }
}
@media (max-width: 375px) {
  .margin.large {
    padding-top: 70px;
  }
}
.margin.medium {
  padding-top: 100px;
}
@media (max-width: 1200px) {
  .margin.medium {
    padding-top: 70px;
  }
}
@media (max-width: 375px) {
  .margin.medium {
    padding-top: 40px;
  }
}
.margin.small {
  padding-top: 70px;
}
@media (max-width: 1200px) {
  .margin.small {
    padding-top: 40px;
  }
}
.margin.extra-small {
  padding-top: 40px;
}
.news .content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
}
.news .content .news-item-wrapper {
  width: 100%;
  text-decoration: none;
}
@media (min-width: 500px) {
  .news .content .news-item-wrapper {
    width: 50%;
  }
}
.news .content .news-item-wrapper:first-child {
  width: 100%;
}
.news .content .news-item-wrapper .news-item-image {
  width: 100%;
  padding-top: 100%;
  background-color: #142832;
  background-size: cover;
  background-position: top, center;
  position: relative;
}
.news .content .news-item-wrapper .news-item-image .overlay-hover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
  background-color: rgba(20,40,50,0.8);
  z-index: 0;
}
.news .content .news-item-wrapper .news-item-image .overlay-hover .circle-plus {
  position: absolute;
  top: 55%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, -50%);
  transform: translate3d(-50%, -50%, -50%);
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  transform: translate(-50%, -50%);
}
.news .content .news-item-wrapper .news-item-content {
  padding-bottom: 50px;
}
.news .content .news-item-wrapper .news-item-content .date {
  margin-top: 30px;
}
.news .content .news-item-wrapper .news-item-content .title {
  margin-top: 10px;
  font-family: "GloberxBold";
  padding-right: 20px;
}
.news .content .news-item-wrapper .news-item-content .title span {
  display: inline;
  width: calc(100%);
  background-image: linear-gradient(transparent calc(100% - 2px), #142832 5px);
  background-position: 0 -7px;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.2s ease-out;
  text-shadow: 1px 0px #fff, -1px 0px #fff, 2px 0px #fff, -2px 0px #fff;
}
.news .content .news-item-wrapper:hover .news-item-image .overlay-hover {
  opacity: 1;
}
.news .content .news-item-wrapper:hover .news-item-image .overlay-hover .circle-plus {
  top: 50%;
}
.news .content .news-item-wrapper:hover .news-item-content .title span {
  background-size: 100% 100%;
  transition: background-size 0.5s ease-out;
}
@media (min-width: 992px) {
  .news .content .news-item-wrapper {
    width: 33.3333%;
  }
  .news .content .news-item-wrapper .news-item-content {
    padding-bottom: 60px;
  }
  .news .content .news-item-wrapper .news-item-content .titles {
    margin-top: 15px;
  }
  .news .content .news-item-wrapper:first-child {
    width: 33.3333%;
  }
}
.btn-plus {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  text-transform: lowercase;
  text-decoration: none;
}
.btn-plus:hover,
.btn-plus:focus {
  text-decoration: none;
}
@media (min-width: 992px) {
  .btn-plus {
    padding-left: 57px;
  }
}
.btn-plus .plus {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 32px;
  height: 32px;
  border: 2px solid #142832;
  border-radius: 100%;
  text-align: center;
  transform: translateY(-50%) rotate(0);
  transition: 0.2s transform ease-out;
}
@media (min-width: 992px) {
  .btn-plus .plus {
    width: 42px;
    height: 42px;
  }
}
.btn-plus .plus:before,
.btn-plus .plus:after {
  content: '';
  width: 52%;
  height: 2px;
  background-color: #142832;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-plus .plus:after {
  height: 52%;
  width: 2px;
}
.btn-plus:hover .plus {
  transform: translateY(-50%) rotate(-90deg);
}
.btn-plus.btn-plus-red {
  color: #eb594d;
}
.btn-plus.btn-plus-red .plus {
  border-color: #eb594d;
}
.btn-plus.btn-plus-red .plus:before,
.btn-plus.btn-plus-red .plus:after {
  background-color: #eb594d;
}
.btn-plus.btn-plus-white {
  color: #fff;
}
.btn-plus.btn-plus-white .plus {
  border-color: #fff;
}
.btn-plus.btn-plus-white .plus:before,
.btn-plus.btn-plus-white .plus:after {
  background-color: #fff;
}
.circle-plus {
  display: block;
  width: 42px;
  height: 42px;
  border: 2px solid #142832;
  border-radius: 100%;
  transform: rotate(0deg);
}
.circle-plus:before,
.circle-plus:after {
  content: '';
  width: 52%;
  height: 2px;
  background-color: #142832;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circle-plus:after {
  height: 52%;
  width: 2px;
}
@media (max-width: 767px) {
  .circle-plus {
    width: 32px;
    height: 32px;
  }
}
.circle-plus.circle-plus-red {
  border: 2px solid #eb594d;
}
.circle-plus.circle-plus-red:before,
.circle-plus.circle-plus-red:after {
  background-color: #eb594d;
}
.circle-plus.circle-plus-white {
  border: 2px solid #fff;
}
.circle-plus.circle-plus-white:before,
.circle-plus.circle-plus-white:after {
  background-color: #fff;
}
.circle-minus {
  display: block;
  width: 42px;
  height: 42px;
  border: 2px solid #142832;
  border-radius: 100%;
  transform: rotate(0deg);
}
.circle-minus:before {
  content: '';
  width: 52%;
  height: 2px;
  background-color: #142832;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 767px) {
  .circle-minus {
    width: 32px;
    height: 32px;
  }
}
.circle-minus.circle-minus-white {
  border: 2px solid #fff;
}
.circle-minus.circle-minus-white:before {
  background-color: #fff;
}
.circle-small-plus {
  border-radius: 50%;
  border: 2px solid #fff;
  width: 42px;
  height: 42px;
}
@media (max-width: 500px) {
  .circle-small-plus {
    width: 32px;
    height: 32px;
  }
}
.circle-small-plus:before,
.circle-small-plus:after {
  content: '';
  position: absolute;
  background-color: #fff;
  transform: translate(-50%, -50%);
}
.circle-small-plus:before {
  left: 50%;
  width: 2px;
  height: 9px;
  top: 50%;
}
.circle-small-plus:after {
  top: 50%;
  left: 50%;
  height: 2px;
  width: 9px;
}
.circle-small-minus {
  border-radius: 50%;
  border: 2px solid #fff;
  width: 42px;
  height: 42px;
}
@media (max-width: 500px) {
  .circle-small-minus {
    width: 32px;
    height: 32px;
  }
}
.circle-small-minus:after {
  content: '';
  position: absolute;
  background-color: #fff;
  transform: translate(-50%, -50%);
}
.circle-small-minus:after {
  top: 50%;
  left: 50%;
  height: 2px;
  width: 9px;
}
.circle-arrow {
  border: 2px solid #eb594d;
  padding: 3px 11px 3px 13px;
  border-radius: 100%;
  height: 32px;
  width: 14px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  text-align: center;
}
@media (max-width: 500px) {
  .circle-arrow {
    padding: 4px 8px 4px 10px;
    height: 20px;
    width: 10px;
  }
}
.circle-arrow.circle-arrow-white {
  border: 2px solid #fff;
}
.circle-arrow.circle-arrow-white * {
  fill: #fff !important;
}
.circle-arrow.circle-arrow-left {
  transform: rotate(180deg);
}
.circle-arrow * {
  fill: #eb594d !important;
  height: 19px;
}
.circle-btn {
  color: #fff;
  background-color: transparent;
  border: none;
  border-radius: 0;
  text-align: left;
  position: relative;
  padding-left: 56px;
}
.circle-btn:focus {
  outline: none;
}
@media (max-width: 767px) {
  .circle-btn {
    padding-left: 50px;
  }
}
.circle-btn span {
  border-radius: 50%;
  border: 2px solid #fff;
  width: 42px;
  height: 42px;
  position: absolute;
  left: 0;
}
@media (max-width: 767px) {
  .circle-btn span {
    width: 32px;
    height: 32px;
  }
}
.circle-btn span:before,
.circle-btn span:after {
  content: '';
  display: block;
  width: 60%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  transition: all 0.25s ease-in-out;
}
.circle-btn span:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.circle-btn span:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.title-container {
  margin-bottom: 40px;
}
@media (min-width: 500px) {
  .title-container {
    margin-bottom: 60px;
  }
}
@media (min-width: 768px) {
  .title-container {
    margin-bottom: 75px;
  }
}
@media (min-width: 992px) {
  .title-container {
    margin-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .title-container {
    margin-bottom: 125px;
  }
}
.title-container .description {
  margin-top: 33px;
}
@media (max-width: 500px) {
  .title-container .description {
    margin-top: 20px;
  }
}
.title-container .description p a {
  color: #eb594d;
}
.author-info-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 15px;
  display: flex;
}
.author-info-wrapper .author-photo-wrapper {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.author-info-wrapper .author-photo-wrapper .box-author-blog {
  width: 36px;
  height: 18px;
  background: #eb594d;
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
.author-info-wrapper .author-photo-wrapper .box2-author-blog {
  width: 36px;
  height: 18px;
  background: #eb594d;
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
.author-info-wrapper .author-photo-wrapper .author-img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  z-index: 2;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.author-info-wrapper p.post-author {
  color: #142832;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 22px;
  margin-top: 10px;
}
.author-info-wrapper p.post-author-position {
  font-size: 14px;
  line-height: 19px;
}
.author-info-wrapper-below-post {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 100px;
}
.author-info-wrapper-below-post .author-photo-wrapper {
  width: 88px;
  height: 88px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  margin-right: 10px;
}
.author-info-wrapper-below-post .author-photo-wrapper .box-post-footer-auth {
  width: 88px;
  height: 44px;
  background: #eb594d;
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
.author-info-wrapper-below-post .author-photo-wrapper .box2-post-footer-auth {
  width: 88px;
  height: 44px;
  background: #eb594d;
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
.author-info-wrapper-below-post .author-photo-wrapper .blog-post-footer-auth-img {
  width: 84px;
  height: 84px;
  border-radius: 100%;
  z-index: 2;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.author-info-wrapper-below-post .blog-post-footer-author-info {
  display: flex;
  flex-direction: column;
}
.author-info-wrapper-below-post .blog-post-footer-author-info p.post-author-footer-who {
  color: #142832;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}
.author-info-wrapper-below-post .blog-post-footer-author-info p.post-author-footer {
  color: #142832;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 22px;
}
.author-info-wrapper-below-post .blog-post-footer-author-info p.post-author-position-footer {
  font-size: 14px;
  line-height: 19px;
}
.circles {
  text-transform: uppercase;
}
.circles * {
  box-sizing: border-box;
}
.circles .circle-container {
  position: relative;
  margin: 0 auto;
}
.circles .circle-container:before {
  content: '';
  width: 100%;
  display: block;
  padding-top: 100%;
}
.circles .circle-container .plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
  height: 42px;
  z-index: 6;
}
@media (max-width: 425px) {
  .circles .circle-container .plus {
    width: 24px;
    height: 24px;
  }
}
.circles .circle-container .plus:before,
.circles .circle-container .plus:after {
  content: '';
  width: 52%;
  height: 2px;
  background-color: #eb594d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.circles .circle-container .plus:after {
  height: 52%;
  width: 2px;
}
.circles .circle-container .circle-container__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.circles .circle-container .circle-container__inner .inner-circle {
  height: 100%;
  border-radius: 50%;
  position: relative;
}
.circles .circle-container .circle-container__inner .inner-circle .inner-circle__item {
  border-radius: 50%;
  text-align: center;
  mix-blend-mode: multiply;
  margin: 0 auto;
  width: 55%;
  height: 55%;
  position: absolute;
  margin: 0;
  padding: 10%;
}
.circles .circle-container .circle-container__inner .inner-circle .inner-circle__item span {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  font-family: sans-serif;
}
.circles .circle-container .circle-container__inner .inner-circle .inner-circle__item:nth-child(1) {
  background-color: rgba(235,89,77,0.1);
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 2;
  transition: all 0.5s linear;
  border: 2px solid #142832;
}
.circles .circle-container .circle-container__inner .inner-circle .inner-circle__item:nth-child(1):hover {
  background-color: rgba(235,89,77,0.5);
  z-index: 5;
}
.circles .circle-container .circle-container__inner .inner-circle .inner-circle__item:nth-child(2) {
  background-color: rgba(223,223,223,0.1);
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  transition: all 0.5s linear;
  z-index: 3;
  border: 2px solid #142832;
}
.circles .circle-container .circle-container__inner .inner-circle .inner-circle__item:nth-child(2):hover {
  background-color: rgba(223,223,223,0.9);
  z-index: 5;
}
.contact-form form label {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 68px;
  font-weight: normal;
}
.contact-form form label span {
  position: absolute;
  top: 55px;
  left: 0;
  transition: 0.2s top ease-out;
}
@media (min-width: 992px) {
  .contact-form form label span {
    top: 68px;
  }
}
.contact-form form label input,
.contact-form form label textarea {
  width: 100%;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: #eb594d;
  background: transparent;
  padding-bottom: 0;
  box-shadow: none !important;
  outline: none;
  -webkit-border-radius: none;
  border-radius: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
@media (min-width: 668px) {
  .contact-form form label input,
  .contact-form form label textarea {
    padding-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .contact-form form label input,
  .contact-form form label textarea {
    padding-bottom: 25px;
  }
}
.contact-form form label.focused span {
  top: 31px;
}
input {
  background-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 99999s;
}
.next-previous a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
}
.next-previous .row .item:first-child a {
  align-items: flex-end;
  margin-right: 6%;
}
.next-previous .row .item:first-child a p {
  text-align: right;
}
.next-previous .row .item:nth-child(2) a {
  margin-left: 5%;
}
.next-previous .row .item .svg {
  margin-bottom: 15px;
}
.next-previous .row .item p {
  line-height: normal;
}
.next-previous .row .item .title {
  display: none;
  font-weight: bold;
}
@media (min-width: 1200px) {
  .next-previous .row .item:hover .text {
    display: none;
  }
  .next-previous .row .item:hover .title {
    display: block;
  }
}
.plug {
  background-color: #eb594d;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10em;
}
.plug a:hover,
.plug a:focus {
  text-decoration: none;
}
.plug .svg {
  width: 152px;
  height: 34px;
  transition: all 0.3s ease-out;
  margin-top: 6px;
}
.plug .svg * {
  fill: #fff !important;
}
@media (min-width: 500px) and (min-height: 700px) {
  .plug .svg {
    width: 209px;
    height: 48px;
  }
}
.plug p {
  color: #fff;
  font-family: GloberLight;
}
.plug p:hover,
.plug p:focus {
  color: #fff;
  font-family: GloberLight;
}
.realizations .container.title-container:first-child {
  margin-bottom: 0;
}
.filters {
  display: flex;
  justify-content: flex-start;
}
.filters span {
  color: #eb594d;
  font-family: GloberxBold;
}
.filters span,
.filters .btn {
  font-size: 1rem;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 1499px) {
  .filters span,
  .filters .btn {
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  .filters span,
  .filters .btn {
    font-size: 18px;
  }
}
.filters .item-wrapper {
  position: relative;
}
.filters .item-wrapper .btn-default {
  position: absolute;
  text-shadow: none;
  box-shadow: none;
  background-image: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #142832;
  opacity: 0;
  width: 0;
  overflow: hidden;
  transition: all 0.2s ease-out;
  line-height: inherit;
}
.filters .item-wrapper .btn-default.selected,
.filters .item-wrapper .btn-default.active {
  outline: none;
  opacity: 1;
  width: auto;
  outline: none;
}
.filters .item-wrapper .btn-default.focus {
  background-color: #fff;
  outline: none;
}
.filters .item-wrapper .btn-default:after {
  content: '';
  height: 2px;
  background-color: #eb594d;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  transition: 0.1s all ease-out;
}
.filters.expanded .item-wrapper .btn-default {
  opacity: 1;
  width: auto;
  position: relative;
}
.filters.expanded .item-wrapper .btn-default.active {
  font-family: 'GloberxBold';
  color: #eb594d;
}
.filters.expanded .item-wrapper .btn-default:hover:after {
  right: 0;
  left: 0;
}
.gallery .grid-sizer {
  width: 50%;
}
.gallery .grid-item {
  width: 100%;
  display: inline-block;
  text-decoration: none;
}
@media (min-width: 992px) {
  .gallery .grid-item {
    width: 50%;
    height: 633px;
  }
}
@media (min-width: 1200px) {
  .gallery .grid-item {
    height: 670px;
  }
}
@media (min-width: 1500px) {
  .gallery .grid-item {
    height: 835px;
    width: 50%;
  }
}
.gallery .grid-item .photo {
  width: 100%;
  padding-top: 70%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}
.gallery .grid-item .photo .inside-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 31px;
  opacity: 0;
  width: calc(100% - 42px);
  height: calc(100% - 42px);
  background-color: #eb594d;
  transition: 0.2s all ease-out;
}
.gallery .grid-item .photo .inside-container h1 {
  font-family: 'GloberBook';
  margin: 0;
  padding: 0;
}
.gallery .grid-item .photo .inside-container .technologies {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  border: 2px solid #142832;
  border-radius: 100%;
}
.gallery .grid-item .photo .inside-container .technologies .circle-chart {
  width: 155px;
  height: 155px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg) scale(1, -1);
}
.gallery .grid-item .photo .inside-container .technologies .circle-chart .circle-chart-circle {
  transition: 0.2s stroke-dasharray ease-out;
  transform-origin: center;
  stroke: #eb594d;
  stroke-width: 2;
  fill: transparent;
  stroke-dasharray: 100 100;
  stroke-linecap: round;
}
.gallery .grid-item .photo .inside-container .technologies .technology {
  position: absolute;
  transform: translate(-50%, -50%);
}
.gallery .grid-item .photo .inside-container .technologies .technology .img {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  position: relative;
}
.gallery .grid-item .photo .inside-container .technologies .technology .img svg,
.gallery .grid-item .photo .inside-container .technologies .technology .img img {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eb594d;
  border-radius: 100%;
}
.gallery .grid-item .photo .inside-container .technologies .technology span {
  position: absolute;
  white-space: nowrap;
  font-family: 'GloberRegular';
  opacity: 0;
}
.gallery .grid-item .photo .inside-container .technologies.count-1 .technology {
  top: 0;
  left: 50%;
}
.gallery .grid-item .photo .inside-container .technologies.count-1 .technology span {
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  text-align: center;
}
.gallery .grid-item .photo .inside-container .technologies.count-2 .circle-chart {
  transform: translate(-50%, -50%) rotate(-180deg) scale(1, -1);
}
.gallery .grid-item .photo .inside-container .technologies.count-2 .technology:nth-child(1) {
  top: 50%;
  left: 0;
}
.gallery .grid-item .photo .inside-container .technologies.count-2 .technology:nth-child(1) span {
  top: 50%;
  right: calc(100% + 30px);
  transform: translateY(-50%);
  text-align: right;
}
.gallery .grid-item .photo .inside-container .technologies.count-2 .technology:nth-child(2) {
  top: 50%;
  left: 100%;
}
.gallery .grid-item .photo .inside-container .technologies.count-2 .technology:nth-child(2) span {
  top: 50%;
  left: calc(100% + 30px);
  transform: translateY(-50%);
}
.gallery .grid-item .photo .inside-container .technologies.count-3 .circle-chart {
  transform: translate(-50%, -50%) rotate(-160deg) scale(1, -1);
}
.gallery .grid-item .photo .inside-container .technologies.count-3 .technology:nth-child(1) {
  top: 35%;
  left: 3%;
}
.gallery .grid-item .photo .inside-container .technologies.count-3 .technology:nth-child(1) span {
  top: 50%;
  right: calc(100% + 30px);
  transform: translateY(-50%);
  text-align: right;
}
.gallery .grid-item .photo .inside-container .technologies.count-3 .technology:nth-child(2) {
  top: 0;
  left: 50%;
}
.gallery .grid-item .photo .inside-container .technologies.count-3 .technology:nth-child(2) span {
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.gallery .grid-item .photo .inside-container .technologies.count-3 .technology:nth-child(3) {
  top: 35%;
  left: 97%;
}
.gallery .grid-item .photo .inside-container .technologies.count-3 .technology:nth-child(3) span {
  top: 50%;
  left: calc(100% + 30px);
  transform: translateY(-50%);
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .circle-chart {
  transform: translate(-50%, -50%) rotate(-180deg) scale(1, -1);
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .technology:nth-child(1) {
  top: 50%;
  left: 0%;
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .technology:nth-child(1) span {
  top: 50%;
  right: calc(100% + 30px);
  transform: translateY(-50%);
  text-align: right;
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .technology:nth-child(2) {
  top: 0%;
  left: 50%;
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .technology:nth-child(2) span {
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .technology:nth-child(3) {
  top: 50%;
  left: 100%;
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .technology:nth-child(3) span {
  top: 50%;
  left: calc(100% + 30px);
  transform: translateY(-50%);
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .technology:nth-child(4) {
  top: 100%;
  left: 50%;
}
.gallery .grid-item .photo .inside-container .technologies.count-4 .technology:nth-child(4) span {
  top: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .circle-chart {
  transform: translate(-50%, -50%) rotate(-160deg) scale(1, -1);
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(1) {
  top: 35%;
  left: 3%;
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(1) span {
  top: 50%;
  right: calc(100% + 30px);
  transform: translateY(-50%);
  text-align: right;
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(2) {
  top: 0;
  left: 50%;
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(2) span {
  bottom: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(3) {
  top: 35%;
  left: 97%;
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(3) span {
  top: 50%;
  left: calc(100% + 30px);
  transform: translateY(-50%);
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(4) {
  top: 90%;
  left: 80%;
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(4) span {
  top: 85%;
  left: calc(60% + 30px);
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(5) {
  top: 90%;
  left: 20%;
}
.gallery .grid-item .photo .inside-container .technologies.count-5 .technology:nth-child(5) span {
  top: 85%;
  right: calc(60% + 30px);
  text-align: right;
}
.gallery .grid-item .photo .plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0);
  height: 42px;
  width: 42px;
  border: 2px solid #eb594d;
  border-radius: 100%;
  transition: 0.2s all ease-out;
}
.gallery .grid-item .photo .plus:before,
.gallery .grid-item .photo .plus:after {
  content: '';
  width: 52%;
  height: 2px;
  background-color: #eb594d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.gallery .grid-item .photo .plus:after {
  height: 52%;
  width: 2px;
}
.gallery .grid-item .paragraph {
  padding: 51px 15% 100px 0;
}
@media (max-width: 1499px) {
  .gallery .grid-item .paragraph {
    padding: 40px 15px 70px 0;
  }
}
@media (max-width: 767px) {
  .gallery .grid-item .paragraph {
    padding: 40px 0 40px;
  }
}
.gallery .grid-item .paragraph h1 {
  color: #142832;
  font-family: 'GloberxBold';
  margin: 0;
  padding: 0;
}
.gallery .grid-item .paragraph h1 p {
  display: inline;
  width: calc(100%);
  background-image: linear-gradient(transparent calc(100% - 2px), #000 5px);
  background-position: 0 -12px;
  background-repeat: no-repeat;
  background-size: 0% 100%;
  transition: background-size 0.2s ease-out;
  text-shadow: 5px 0px #fff, -5px 0px #fff;
}
.gallery .grid-item .paragraph h5 {
  color: #142832;
  margin: 0 0 18px;
}
@media (min-width: 1200px) {
  .gallery .grid-item:hover .photo .inside-container {
    opacity: 1;
    transition: 0.2s all ease-out;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies .circle-chart .circle-chart-circle {
    transition: 0.5s stroke-dasharray ease-out;
    stroke-dasharray: 0 100;
    transition-delay: 0.2s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies .technology .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies .technology .img img {
    width: 100%;
    height: 100%;
    transition: 0.07s all ease-out;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies .technology span {
    opacity: 1;
    transition: 0.2s opacity ease-out;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies .technology:nth-child(1) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies .technology:nth-child(1) .img img {
    transition-delay: 0.3s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies .technology:nth-child(1) span {
    transition-delay: 0.32s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-2 .technology:nth-child(2) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-2 .technology:nth-child(2) .img img {
    transition-delay: 0.36s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-2 .technology:nth-child(2) span {
    transition-delay: 0.38s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-3 .technology:nth-child(2) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-3 .technology:nth-child(2) .img img {
    transition-delay: 0.32s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-3 .technology:nth-child(2) span {
    transition-delay: 0.34s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-3 .technology:nth-child(3) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-3 .technology:nth-child(3) .img img {
    transition-delay: 0.36s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-3 .technology:nth-child(3) span {
    transition-delay: 0.38s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(2) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(2) .img img {
    transition-delay: 0.32s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(2) span {
    transition-delay: 0.34s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(3) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(3) .img img {
    transition-delay: 0.36s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(3) span {
    transition-delay: 0.38s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(4) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(4) .img img {
    transition-delay: 0.46s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-4 .technology:nth-child(4) span {
    transition-delay: 0.48s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(2) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(2) .img img {
    transition-delay: 0.32s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(2) span {
    transition-delay: 0.34s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(3) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(3) .img img {
    transition-delay: 0.36s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(3) span {
    transition-delay: 0.38s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(4) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(4) .img img {
    transition-delay: 0.46s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(4) span {
    transition-delay: 0.48s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(5) .img svg,
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(5) .img img {
    transition-delay: 0.58s;
  }
  .gallery .grid-item:hover .photo .inside-container .technologies.count-5 .technology:nth-child(5) span {
    transition-delay: 0.6s;
  }
  .gallery .grid-item:hover .photo .plus {
    border-color: #142832;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: 0.2s all ease-out;
  }
  .gallery .grid-item:hover .photo .plus:before,
  .gallery .grid-item:hover .photo .plus:after {
    background-color: #142832;
  }
  .gallery .grid-item:hover .paragraph h1 p {
    background-size: 100% 100%;
    transition: background-size 0.5s ease-out;
  }
  .gallery .grid-item:hover .paragraph h5 {
    color: #eb594d;
  }
}
@-moz-keyframes circle-chart-fill {
  to {
    stroke-dasharray: 100 0;
  }
}
@-webkit-keyframes circle-chart-fill {
  to {
    stroke-dasharray: 100 0;
  }
}
@-o-keyframes circle-chart-fill {
  to {
    stroke-dasharray: 100 0;
  }
}
@keyframes circle-chart-fill {
  to {
    stroke-dasharray: 100 0;
  }
}
.realization .main-paragraph h1 {
  font-family: 'GloberHeavy';
  margin-bottom: 40px;
}
@media (max-width: 1500px) {
  .realization .main-paragraph h1 {
    margin-bottom: 30px;
  }
}
@media (max-width: 375px) {
  .realization .main-paragraph h1 {
    margin-bottom: 27px;
  }
}
@media (max-width: 1500px) {
  .realization .main-paragraph p {
    margin-bottom: 18px;
  }
}
.baner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  min-height: 30em;
  padding-top: 20%;
  padding-bottom: 9%;
}
@media (max-height: 960px) and (min-width: 1179px) {
  .baner {
    padding-top: 13%;
  }
}
@media (max-width: 777px) {
  .baner {
    padding-top: 45%;
  }
}
.baner .container .header {
  color: #fff;
  margin-bottom: 64px;
}
@media (max-width: 768px) {
  .baner .container .header {
    margin-bottom: 117px;
  }
}
@media (max-width: 480px) {
  .baner .container .header {
    margin-bottom: 24px;
  }
}
.baner .container .header .additional-description {
  margin-bottom: 6%;
}
.baner .container .header .additional-description h1 {
  position: relative;
}
@media (max-width: 768px) {
  .baner .container .header .additional-description h1 {
    display: none;
  }
}
.baner .container .header .additional-description h1:before {
  content: '';
  width: 20px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: -13px;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
.baner .container .header .header {
  font-family: 'GloberHeavy';
  margin-bottom: 38px;
}
.baner .container .header .subheader {
  font-family: 'GloberBook';
}
.baner .container .flex-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .baner .container .flex-wrapper {
    flex-direction: row;
  }
}
.baner .container .flex-wrapper .icons {
  display: flex;
}
.baner .container .flex-wrapper .icons a {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 45px;
}
@media (max-width: 480px) {
  .baner .container .flex-wrapper .icons a {
    margin-right: 30px;
  }
}
.baner .container .flex-wrapper .icons a .svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  margin-bottom: 12px;
}
.baner .container .flex-wrapper .icons a p {
  color: #dfdfdf;
  font-family: 'GloberSemiBold';
}
.baner .container .flex-wrapper .arrows {
  align-self: flex-end;
}
@media (max-width: 480px) {
  .baner .container .flex-wrapper .arrows {
    display: none;
  }
}
.baner .container .flex-wrapper .arrows a {
  margin-right: 22px;
}
.block-text h1 {
  font-family: 'GloberHeavy';
}
.block-text p {
  font-family: 'GloberBook';
}
.block-text ul {
  font-family: 'GloberBook';
}
.block-text-blue {
  background-color: #2aace8;
}
.block-text-blue h1 {
  font-family: 'GloberHeavy';
  margin-bottom: 2.5rem;
  color: #fff;
}
.block-text-blue p {
  font-family: 'GloberBook';
}
.carosuel .slick-track {
  margin-bottom: 2.222rem;
  display: flex;
  align-items: center;
}
.carosuel .slick-prev {
  left: -11%;
}
.carosuel .slick-next {
  right: -11%;
}
.carosuel .slick-prev:before,
.carosuel .slick-next:before {
  background-repeat: no-repeat;
  color: transparent;
  font-size: 26px;
}
@media (max-width: 767px) {
  .carosuel .slick-prev:before,
  .carosuel .slick-next:before {
    display: none;
  }
}
.carosuel .slick-prev:before {
  background-image: url("../../dist/img/arrow-left.png");
}
.carosuel .slick-next:before {
  background-image: url("../../dist/img/arrow-right.png");
}
.carosuel .slick-slide img {
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}
.carosuel .slick-dots li:before {
  content: none;
}
.carosuel a {
  text-decoration: none;
}
.carosuel .slick-active a {
  text-decoration: underline;
}
@media (max-width: 991px) {
  .two-img .container .row:first-child img {
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .two-img .container .row:first-child img {
    margin-bottom: 50px;
  }
}
.multiple-carosuel .slick-slide {
  margin: 0 10px;
}
.multiple-carosuel .slick-track {
  display: flex;
  align-items: center;
}
.photo-text .row-flex {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .photo-text .row-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .photo-text .row-flex img,
  .photo-text .row-flex video {
    margin-bottom: 50px;
  }
}
.social-media {
  display: flex;
  justify-content: center;
}
.social-media a {
  display: block;
  height: 64px;
  width: 64px;
  border: 1px solid #d9d9d9;
  position: relative;
  color: #000;
  transition: 0.5s color ease-out;
  margin-right: 5px;
}
.social-media a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.social-media a:before {
  content: '';
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.5s height ease-out;
}
.social-media a.facebook:before {
  background-color: #3b5998;
}
.social-media a.twitter:before {
  background-color: #00aced;
}
.social-media a.pinterest:before {
  background-color: #c92228;
}
.social-media a.linked-in:before {
  background-color: #0077b5;
}
.social-media a:last-child {
  margin-right: 0;
}
.social-media a:hover {
  color: #fff;
  transition: 0.2s color ease-out;
}
.social-media a:hover:before {
  height: 100%;
  transition: 0.2s height ease-out;
}
.previous-next {
  display: flex;
  justify-content: center;
}
.previous-next .wrapper {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 375px) {
  .previous-next .wrapper {
    justify-content: center;
  }
}
.previous-next .wrapper a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
@media (max-width: 375px) {
  .previous-next .wrapper a:first-child img {
    margin-right: 30px;
  }
}
.previous-next .wrapper p {
  color: #2aace8;
  padding: 0 15px;
}
@media (max-width: 375px) {
  .previous-next .wrapper .hide-text {
    display: none;
  }
}
.previous-next .wrapper img {
  width: 27px;
  height: 17px;
}
.download-section .row .col:first-child a img {
  float: right;
}
.download-section .row img {
  max-width: 150px;
  max-height: 60px;
}
.layout-design {
  position: relative;
  height: 100%;
}
.nav-design {
  position: fixed;
  top: 0;
  z-index: 2000;
  padding: 40px 0;
  width: 100%;
  transition: 0.4s;
  background-color: #fff;
}
.contact #g-map {
  width: 100%;
  height: 50vh;
}
.contact .contact-data {
  margin-top: 50px;
}
@media (min-width: 768px) {
  .contact .contact-data {
    margin-top: 70px;
  }
}
@media (min-width: 992px) {
  .contact .contact-data {
    margin-top: 90px;
  }
}
@media (min-width: 1200px) {
  .contact .contact-data {
    margin-top: 110px;
  }
}
.contact .contact-data ul {
  margin: 0;
  padding: 0;
}
.contact .contact-data ul li {
  list-style-type: none;
}
.contact .contact-data ul li:before {
  content: none;
}
.contact .contact-data .contact-column {
  margin-bottom: 20px;
}
.contact .contact-data .contact-column a {
  text-decoration: none;
}
@media (min-width: 768px) {
  .contact .contact-data .contact-column {
    margin-bottom: 40px;
  }
}
.contact .contact-data .contact-column:last-child {
  margin-bottom: 0;
}
.contact .contact-data .contact-column:nth-child(2n-1) {
  clear: both;
}
@media (min-width: 992px) {
  .contact .contact-data .contact-column {
    margin-bottom: 0;
  }
  .contact .contact-data .contact-column:nth-child(2n-1) {
    clear: none;
  }
}
.contact .contact-form {
  margin-top: 80px;
}
@media (min-width: 768px) {
  .contact .contact-form {
    margin-top: 100px;
  }
}
@media (min-width: 992px) {
  .contact .contact-form {
    margin-top: 130px;
  }
}
@media (min-width: 1200px) {
  .contact .contact-form {
    margin-top: 160px;
  }
}
.contact .contact-form form label {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 68px;
}
.contact .contact-form form label span {
  position: absolute;
  top: 55px;
  left: 0;
  z-index: -1;
  transition: 0.2s top ease-out;
}
@media (min-width: 992px) {
  .contact .contact-form form label span {
    top: 68px;
  }
}
.contact .contact-form form label input,
.contact .contact-form form label textarea {
  width: 100%;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: #eb594d;
  background: transparent;
  padding-bottom: 0;
  box-shadow: none !important;
  outline: none;
  -webkit-border-radius: none;
  border-radius: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
@media (min-width: 668px) {
  .contact .contact-form form label input,
  .contact .contact-form form label textarea {
    padding-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .contact .contact-form form label input,
  .contact .contact-form form label textarea {
    padding-bottom: 25px;
  }
}
.contact .contact-form form label.focused span {
  top: 31px;
}
.contact .contact-form form label.required span:after {
  content: " *";
  color: #eb594d;
}
.contact .contact-form form label.error span {
  color: #eb594d;
  font-family: 'GloberxBold';
}
.contact .contact-form form #captcha-invalid {
  color: #eb594d;
}
.contact .contact-form form .information {
  margin-top: 60px;
  margin-bottom: 30px;
}
.contact .contact-form form button {
  background: transparent;
  border: none;
  color: #eb594d;
  padding: 0;
  box-shadow: none !important;
  outline: none;
}
.contact .contact-form form button span {
  color: #eb594d;
  position: relative;
  padding-right: 50px;
  display: block;
}
.contact .contact-form form button span img,
.contact .contact-form form button span svg {
  position: absolute;
  top: 60%;
  right: 0;
  transform: translateY(-50%);
  display: inline-block;
  border: 2px solid #eb594d;
  padding: 5px;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  box-sizing: content-box;
  text-align: center;
}
.contact .contact-form form button span img *,
.contact .contact-form form button span svg * {
  fill: #eb594d;
}
.blog-entry .container.title-container:first-child {
  margin-bottom: 0;
}
.blog-entry .blog-entry-header {
  margin-bottom: 50px;
}
@media (min-width: 500px) {
  .blog-entry .blog-entry-header {
    margin-bottom: 80px;
  }
}
@media (min-width: 768px) {
  .blog-entry .blog-entry-header {
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .blog-entry .blog-entry-header {
    margin-bottom: 140px;
  }
}
@media (min-width: 1200px) {
  .blog-entry .blog-entry-header {
    margin-bottom: 160px;
  }
}
@media (min-width: 1500px) {
  .blog-entry .blog-entry-header {
    margin-bottom: 180px;
  }
}
.blog-entry .blog-entry-header h4.date {
  color: #142832;
}
.blog-entry .other-news-section-container {
  padding-top: 40px;
}
@media (min-width: 500px) {
  .blog-entry .other-news-section-container {
    padding-top: 50px;
  }
}
@media (min-width: 768px) {
  .blog-entry .other-news-section-container {
    padding-top: 70px;
  }
}
@media (min-width: 992px) {
  .blog-entry .other-news-section-container {
    padding-top: 100px;
  }
}
@media (min-width: 1200px) {
  .blog-entry .other-news-section-container {
    padding-top: 140px;
  }
}
@media (min-width: 1500px) {
  .blog-entry .other-news-section-container {
    padding-top: 160px;
  }
}
.blog section.first-news a {
  text-decoration: none;
}
.blog section.first-news .image-wrapper {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 768px) {
  .blog section.first-news .image-wrapper {
    padding-top: 56.25%;
  }
}
.blog section.first-news .image-wrapper .text-container {
  padding: 30px 8.33%;
  color: #fff;
}
@media (min-width: 500px) {
  .blog section.first-news .image-wrapper .text-container {
    padding: 50px 8.33%;
  }
}
@media (min-width: 768px) {
  .blog section.first-news .image-wrapper .text-container {
    padding: 0 8.33%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
.blog section.first-news .image-wrapper .text-container h1 {
  color: #fff;
  font-family: 'GloberHeavy';
}
.blog section.first-news .image-wrapper .text-container h2.date {
  margin-bottom: 10%;
}
.start .selected-projects .rotator-wrapper {
  width: 100%;
  position: relative;
  background-color: #142832;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper {
    height: 50vw;
    max-height: 623px;
  }
}
@media (min-width: 1500px) {
  .start .selected-projects .rotator-wrapper {
    height: 40vw;
  }
}
.start .selected-projects .rotator-wrapper .selected-project {
  height: 60vw;
  background-size: cover;
  background-position: center;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .selected-project {
    height: 50vw;
  }
}
@media (min-width: 1500px) {
  .start .selected-projects .rotator-wrapper .selected-project {
    height: 40vw;
    max-height: 623px;
  }
}
.start .selected-projects .rotator-wrapper .container,
.start .selected-projects .rotator-wrapper .row,
.start .selected-projects .rotator-wrapper .col {
  height: 100%;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.start .selected-projects .rotator-wrapper .readmore-wrapper {
  background-color: #eb594d;
  position: relative;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .readmore-wrapper {
    position: absolute;
    height: calc(100% - 66px);
    width: 663px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.start .selected-projects .rotator-wrapper .readmore-wrapper::after,
.start .selected-projects .rotator-wrapper .readmore-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100vw;
  background-color: #eb594d;
  display: block;
  height: 100%;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .readmore-wrapper::after,
  .start .selected-projects .rotator-wrapper .readmore-wrapper::before {
    display: none;
  }
}
.start .selected-projects .rotator-wrapper .readmore-wrapper::before {
  left: 100%;
}
.start .selected-projects .rotator-wrapper .readmore-wrapper::after {
  right: 100%;
}
.start .selected-projects .rotator-wrapper .description-wrapper {
  padding: 70px 15px 40px;
  height: 100%;
}
@media (min-width: 600px) {
  .start .selected-projects .rotator-wrapper .description-wrapper {
    padding: 80px 15px 50px;
  }
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper {
    padding: 100px 60px;
  }
}
.start .selected-projects .rotator-wrapper .description-wrapper .btn-plus {
  margin-top: 30px;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper .btn-plus {
    margin-top: 0;
  }
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow {
  top: 45px;
  width: 35px;
  height: 35px;
  border: 2px solid #142832;
  border-radius: 100%;
  transform: none;
  display: flex;
  justify-content: center;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow {
    width: 42px;
    height: 42px;
  }
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-prev,
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-next {
  display: -webkit-box;
  -webkit-box-orient: horizontal;
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-prev:before,
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-next:before {
  display: none;
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-prev img,
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-next img,
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-prev svg,
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-next svg {
  height: 20px;
  width: 10px;
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-prev {
  left: 15px;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-prev {
    left: 60px;
  }
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-prev img,
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-prev svg {
  transform: rotate(-180deg);
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-next {
  left: 70px;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper .slick-arrow.slick-next {
    left: 125px;
  }
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list {
  height: 100%;
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track {
  height: 100%;
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content {
  height: calc(100% - 46px);
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .f48 {
  margin-bottom: 0;
  margin-top: 25px;
}
@media (max-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .f48 {
    margin-bottom: 25px;
  }
}
@media (max-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content {
    margin: 40px 0;
  }
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .description {
  margin-top: 10px;
}
@media (max-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .description {
    display: none;
  }
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .wrapperItem {
  display: inline-block;
  margin-top: -15px;
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .wrapperItem .categories {
  font-size: 15px;
  display: inline-flex;
  position: relative;
  padding-right: 7px;
  height: 25px;
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .wrapperItem .categories::after {
  content: '';
  position: absolute;
  right: 0;
  top: 15px;
  bottom: -9px;
  width: 1px;
  background-color: #6d2d2d;
}
@media (max-width: 992px) {
  .start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .wrapperItem .categories::after {
    top: 9px;
    bottom: -1px;
  }
}
.start .selected-projects .rotator-wrapper .description-wrapper .slick-list .slick-track .content .wrapperItem .categories:last-child::after {
  display: none;
}
.start .selected-projects .rotator-wrapper .readme-rotator {
  left: 15px;
  bottom: 40px;
}
@media (min-width: 992px) {
  .start .selected-projects .rotator-wrapper .readme-rotator {
    left: 60px;
    bottom: 95px;
  }
  .start .selected-projects .rotator-wrapper .readme-rotator .btn-plus {
    width: 45px;
    height: 45px;
  }
}
.start .what-do-we-do {
  background-color: #efefef;
  padding-top: 70px;
  padding-bottom: 70px;
}
@media (min-width: 992px) {
  .start .what-do-we-do {
    padding-top: 115px;
    padding-bottom: 115px;
  }
}
.start .what-do-we-do .content {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .start .what-do-we-do .content {
    margin-top: 40px;
  }
}
@media (min-width: 992px) {
  .start .what-do-we-do .content {
    margin-top: 60px;
  }
}
@media (min-width: 1200px) {
  .start .what-do-we-do .content {
    margin-top: 90px;
  }
}
.start .what-do-we-do .content .pictogram-wrapper {
  position: relative;
  min-height: 420px;
}
@media (min-width: 600px) {
  .start .what-do-we-do .content .pictogram-wrapper {
    min-height: 500px;
  }
}
@media (min-width: 992px) {
  .start .what-do-we-do .content .pictogram-wrapper {
    min-height: 478px;
  }
}
@media (min-width: 1200px) {
  .start .what-do-we-do .content .pictogram-wrapper {
    min-height: 478px;
  }
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle,
.start .what-do-we-do .content .pictogram-wrapper .inner-circle {
  position: relative;
  border: 2px solid #142832;
  border-radius: 100%;
}
@media (min-width: 992px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle,
  .start .what-do-we-do .content .pictogram-wrapper .inner-circle {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle > svg,
.start .what-do-we-do .content .pictogram-wrapper .inner-circle > svg {
  position: absolute;
  display: none;
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle > svg circle,
.start .what-do-we-do .content .pictogram-wrapper .inner-circle > svg circle {
  fill: transparent;
  stroke-width: 1;
  stroke: rgba(235,89,77,0.4);
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle {
  width: 220px;
  height: 220px;
  margin: 90px auto 40px;
  transform: scale(0.85);
}
@media (min-width: 375px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle {
    transform: scale(1);
  }
}
@media (min-width: 600px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle {
    width: 300px;
    height: 300px;
  }
}
@media (min-width: 992px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle {
    margin-left: 160px;
    width: 220px;
    height: 220px;
  }
}
@media (min-width: 1200px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle {
    width: 300px;
    height: 300px;
  }
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle > svg {
  top: -7px;
  left: -7px;
  width: calc(100% + 14px);
  height: calc(100% + 14px);
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .moon-orbit {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .moon-orbit .moon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 25px;
  border: 2px solid #eb594d;
  border-radius: 100%;
  background-color: #efefef;
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element {
  position: absolute;
  background: #efefef;
  border: 2px solid #142832;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  padding: 0;
  margin: 0;
  transition: 0.2s border ease-out;
  outline: none;
  box-shadow: none !important;
  text-transform: lowercase;
}
@media (min-width: 600px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element {
    width: 81px;
    height: 81px;
  }
}
@media (min-width: 992px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element {
    width: 55px;
    height: 55px;
  }
}
@media (min-width: 1200px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element {
    width: 81px;
    height: 81px;
  }
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element svg {
  width: 100%;
  height: 100%;
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element svg * {
  fill: #142832;
  transition: 0.2s all ease-out;
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element span {
  position: absolute;
  text-transform: lowercase;
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(1 ) {
  bottom: 50%;
  left: 0;
  transform: translate(-50%, 50%);
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(1 ) span {
  right: 100px;
  top: 140%;
  transform: translate(100%, -50%);
}
@media (min-width: 600px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(1 ) span {
    right: auto;
    left: -15px;
    top: 50%;
    transform: translate(-100%, -50%);
  }
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(2 ) {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(2 ) span {
  left: 50%;
  top: -15px;
  transform: translate(-50%, -100%);
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(3 ) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(3 ) span {
  right: 20px;
  top: 140%;
  transform: translate(100%, -50%);
}
@media (min-width: 600px) {
  .start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(3 ) span {
    right: -15px;
    top: 50%;
    transform: translate(100%, -50%);
  }
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(4 ) {
  bottom: 0;
  right: 50%;
  transform: translate(50%, 50%);
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:nth-child(4 ) span {
  left: 50%;
  bottom: -15px;
  transform: translate(-50%, 100%);
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element.active,
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:hover {
  border-color: #eb594d;
}
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element.active svg *,
.start .what-do-we-do .content .pictogram-wrapper .outer-circle .other-strategy-elements .strategy-element:hover svg * {
  fill: #eb594d;
}
.start .what-do-we-do .content .pictogram-wrapper .inner-circle {
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-width: 0;
}
@media (min-width: 600px) {
  .start .what-do-we-do .content .pictogram-wrapper .inner-circle {
    border-width: 2px;
  }
}
@media (min-width: 992px) {
  .start .what-do-we-do .content .pictogram-wrapper .inner-circle {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 1200px) {
  .start .what-do-we-do .content .pictogram-wrapper .inner-circle {
    width: 150px;
    height: 150px;
  }
}
.start .what-do-we-do .content .pictogram-wrapper .inner-circle svg {
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
}
.start .what-do-we-do .content .pictogram-wrapper .inner-circle .strategy-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'GloberxBold';
  border: 0;
  background: none;
  outline: none;
  box-shadow: none !important;
  text-transform: lowercase;
}
.start .what-do-we-do .content .pictogram-wrapper .inner-circle .strategy-element:before {
  content: '';
  width: 0;
  height: 0;
  background: #eb594d;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  transition: 0.2s all ease-out;
}
.start .what-do-we-do .content .pictogram-wrapper .inner-circle .strategy-element.active:before,
.start .what-do-we-do .content .pictogram-wrapper .inner-circle .strategy-element:hover:before {
  width: 50px;
  height: 50px;
  transition: 0.5s all ease-out;
}
.start .what-do-we-do .content .description-container .description-wrapper {
  display: none;
}
.start .what-do-we-do .content .description-container .description-wrapper h1 {
  color: #eb594d;
  font-family: "GloberxBold";
}
.start .what-do-we-do .content .description-container .description-wrapper .btn-plus {
  margin-top: 75px;
}
.start .what-do-we-do .content .description-container .description-wrapper.active {
  display: block;
}
@-moz-keyframes moon-orbit-move {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes moon-orbit-move {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes moon-orbit-move {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes moon-orbit-move {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes moon-orbit-move-2 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}
@-webkit-keyframes moon-orbit-move-2 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}
@-o-keyframes moon-orbit-move-2 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}
@keyframes moon-orbit-move-2 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(450deg);
  }
}
@-moz-keyframes moon-orbit-move-3 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
@-webkit-keyframes moon-orbit-move-3 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
@-o-keyframes moon-orbit-move-3 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
@keyframes moon-orbit-move-3 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(540deg);
  }
}
@-moz-keyframes moon-orbit-move-4 {
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(630deg);
  }
}
@-webkit-keyframes moon-orbit-move-4 {
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(630deg);
  }
}
@-o-keyframes moon-orbit-move-4 {
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(630deg);
  }
}
@keyframes moon-orbit-move-4 {
  0% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(630deg);
  }
}
.start .how-do-we-work {
  padding-top: 0;
}
@media (min-width: 500px) {
  .start .how-do-we-work {
    padding-top: 50px;
  }
}
.start .how-do-we-work .container-fluid .content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper {
  width: 100%;
  background-color: #f00;
  background-color: #142832;
  background-size: cover;
  background-position: top, center;
  position: relative;
  text-decoration: none;
}
@media (min-width: 768px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper {
    width: 33.333333333%;
  }
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 15px;
}
@media (min-width: 776px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content {
    padding: 40 30px;
  }
}
@media (min-width: 992px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content {
    padding: 75px 45px;
  }
}
@media (min-width: 1200px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content {
    padding: 95px 75px;
  }
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .bg-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20,40,50,0.8);
  opacity: 0;
  transition: 0.2s opacity ease-out;
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .f48 {
  margin: 0;
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .description {
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: 0.2s opacity ease-out;
  padding: 20px 0 30px;
}
@media (min-width: 776px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .description {
    padding: 30px 0 70px;
  }
}
@media (min-width: 992px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .description {
    padding: 40px 0 100px;
  }
}
@media (min-width: 1200px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .description {
    padding: 50px 0 120px;
  }
}
@media (max-width: 1200px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .description {
    height: 185px;
  }
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .description p {
  color: #fff;
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .title {
  color: #fff;
  font-family: 'GloberxBold';
  transition: 0.2s color ease-out;
  position: relative;
  z-index: 1;
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .btn-plus {
  color: #fff;
  transition: 0.2s color ease-out;
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .btn-plus .plus {
  border-color: #fff;
  transform: translateY(-50%) rotate(0deg);
  transition: 0.2s all ease-out;
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .btn-plus .plus:before,
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .btn-plus .plus:after {
  background-color: #fff;
  transition: 0.2s background ease-out;
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .btn-plus:hover .plus {
  transform: translateY(-50%) rotate(0deg);
}
.start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content .btn-plus span {
  opacity: 0;
  transition: 0.2s opacity ease-out;
}
@media (min-width: 1200px) {
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content:hover .bg-div,
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content:hover .description {
    opacity: 1;
    transition: 0.5s opacity ease-out;
  }
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content:hover .title {
    color: #eb594d;
    transition: 0.5s color ease-out;
  }
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content:hover .btn-plus {
    color: #eb594d;
    transition: 0.5s color ease-out;
  }
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content:hover .btn-plus .plus {
    border-color: #eb594d;
    transition: 0.5s border ease-out, 0.2s transform ease-out;
    transform: translateY(-50%) rotate(-90deg);
  }
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content:hover .btn-plus .plus:before,
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content:hover .btn-plus .plus:after {
    background-color: #eb594d;
    transition: 0.5s background ease-out;
  }
  .start .how-do-we-work .container-fluid .content .how-do-w-work-item-wrapper .how-do-w-work-item-content:hover .btn-plus span {
    opacity: 1;
    transition: 0.5s opacity ease-out;
  }
}
.start .others-about-us {
  padding-top: 50px;
}
@media (min-width: 500px) {
  .start .others-about-us {
    padding-top: 70px;
  }
}
@media (min-width: 768px) {
  .start .others-about-us {
    padding-top: 130px;
  }
}
@media (min-width: 992px) {
  .start .others-about-us {
    padding-top: 170px;
  }
}
@media (min-width: 1200px) {
  .start .others-about-us {
    padding-top: 190px;
  }
}
.start .others-about-us .container .logos .others-logo button {
  padding: 38px 50px;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  position: relative;
  outline: none;
  box-shadow: none !important;
}
.start .others-about-us .container .logos .others-logo button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  height: 2px;
  background-color: #eb594d;
  transition: 0.2s all ease-out;
}
.start .others-about-us .container .logos .others-logo button img {
  height: 50px;
  max-width: 100%;
  opacity: 0.2;
  transition: 0.2s all ease-out;
}
.start .others-about-us .container .logos .others-logo:hover button img,
.start .others-about-us .container .logos .others-logo.active button img {
  opacity: 1;
  transition: 0.5s all ease-out;
}
.start .others-about-us .container .logos .others-logo.active button:after {
  left: 0;
  right: 0;
  transition: 0.5s all ease-out;
}
.start .others-about-us .container .opinions {
  background-color: #efefef;
  padding: 30px 0;
  color: #142832;
}
@media (min-width: 500px) {
  .start .others-about-us .container .opinions {
    padding: 80px 0 50px;
  }
}
@media (min-width: 992px) {
  .start .others-about-us .container .opinions {
    padding: 140px 0 80px;
  }
}
@media (min-width: 1200px) {
  .start .others-about-us .container .opinions {
    padding: 180px 0 120px;
  }
}
.start .others-about-us .container .opinions .others-opinion {
  opacity: 0;
  height: 0;
  min-height: 0;
  transition: 0s all ease-out;
}
.start .others-about-us .container .opinions .others-opinion blockquote {
  padding: 0;
  border: 0;
  margin: 0;
}
.start .others-about-us .container .opinions .others-opinion blockquote .content {
  text-align: center;
  color: inherit;
}
.start .others-about-us .container .opinions .others-opinion blockquote footer {
  margin-top: 71px;
  text-align: center;
  color: inherit;
}
.start .others-about-us .container .opinions .others-opinion blockquote footer .who {
  font-family: "GloberxBold";
}
.start .others-about-us .container .opinions .others-opinion blockquote footer:before {
  content: "";
}
.start .others-about-us .container .opinions .others-opinion.active {
  opacity: 1;
  height: auto;
  transition: 0.5s all ease-out;
}
.start .news-section-container {
  padding-top: 70px;
  padding-bottom: 25px;
}
@media (min-width: 500px) {
  .start .news-section-container {
    padding-top: 80px;
    padding-bottom: 35px;
  }
}
@media (min-width: 768px) {
  .start .news-section-container {
    padding-top: 95px;
    padding-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .start .news-section-container {
    padding-top: 105px;
    padding-bottom: 45px;
  }
}
@media (min-width: 1200px) {
  .start .news-section-container {
    padding-top: 115px;
    padding-bottom: 50px;
  }
}
.section-title-url a {
  text-decoration: none;
}
@media (max-width: 1199px) {
  .text-photo .container .row {
    display: flex;
    flex-wrap: wrap;
  }
  .text-photo .container .row div:first-child {
    margin-top: 40px;
    order: 2;
  }
  .text-photo .container .row:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 1499px) and (min-width: 1200px) {
  .text-photo .container .row p {
    font-size: 21px;
    line-height: 31px;
  }
}
.text-photo .container a {
  text-decoration: none;
}
.text-photo .container a:hover .plus {
  transform: translateY(-50%) rotate(-90deg);
}
.background-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
}
.background-image .section-title:before {
  background-color: #fff;
}
.centered-texts .f48 {
  margin: 0;
}
.circles-section .circles .circle-container {
  max-width: 800px;
  margin-top: -7%;
  margin-bottom: -7%;
}
.circles-section .circles .circle-container .plus {
  width: 60px;
  height: 60px;
}
@media (max-width: 600px) {
  .circles-section .circles .circle-container .plus {
    width: 24px;
    height: 24px;
  }
}
.circles-text .circles .circle-container {
  max-width: 600px;
}
.technologies .container .row .row .svg,
.technologies .container .row .row .svg *,
.technologies .container .row .row .svg * * {
  opacity: 0.8;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  transition: all 0.3s ease-out;
}
.technologies .container .row .col-svg:hover .svg,
.technologies .container .row .col-svg:hover .svg *,
.technologies .container .row .col-svg:hover .svg * * {
  opacity: 1 !important;
  filter: grayscale(0%) !important;
  -webkit-filter: grayscale(0%) !important;
}
.image-on-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.11;
}
@media (min-width: 992px) {
  .image-text .row-center {
    display: flex;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
}
.image-text .col-center {
  text-align: center;
}
@media (max-width: 991px) {
  .image-text .col-center {
    margin-bottom: 40px;
  }
}
.photo-on-right figure {
  display: table;
  margin: 0;
}
.photo-on-right img {
  margin-bottom: 25px;
}
.photo-on-right figcaption {
  display: table-caption;
  caption-side: bottom;
}
.photo-on-right figcaption p {
  font-size: 14px;
  line-height: normal;
}
.film-text video {
  max-width: 100%;
}
@media (min-width: 992px) {
  .film-text .row-center {
    display: flex;
    align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
}
.film-text .col-center {
  text-align: center;
}
@media (max-width: 991px) {
  .film-text .col-center {
    margin-bottom: 40px;
  }
}
.numeric-tabs .tab {
  color: #eb594d;
  cursor: pointer;
  padding: 0;
  z-index: 1;
}
.numeric-tabs .tab .up {
  padding: 0 15px;
  border-bottom: 2px solid #dfdfdf;
  position: relative;
  height: 115px;
}
@media (max-width: 767px) {
  .numeric-tabs .tab .up {
    height: 62px;
  }
}
.numeric-tabs .tab .up .numbers {
  color: #efefef;
  font-family: GloberHeavy;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: 1px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 65px;
}
@media (max-width: 1499px) {
  .numeric-tabs .tab .up .numbers {
    bottom: 3px;
  }
}
@media (max-width: 992px) {
  .numeric-tabs .tab .up .numbers {
    bottom: 5px;
  }
}
@media (max-width: 767px) {
  .numeric-tabs .tab .up .numbers {
    font-size: 46px;
    bottom: 0px;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
.numeric-tabs .tab .up .circle-plus {
  position: absolute;
  border: 2px solid #dfdfdf;
  left: calc(50% - 22px);
  bottom: -22px;
}
.numeric-tabs .tab .up .circle-plus:before {
  background-color: #dfdfdf;
}
.numeric-tabs .tab .up .circle-plus:after {
  background-color: #dfdfdf;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
@media (max-width: 767px) {
  .numeric-tabs .tab .up .circle-plus {
    bottom: -17px;
    left: calc(50% - 16px);
  }
}
.numeric-tabs .tab .down {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  height: 120px;
  opacity: 0;
  transition: 0.2s all ease-in-out;
  line-height: normal;
}
@media (max-width: 767px) {
  .numeric-tabs .tab .down {
    height: inherit;
  }
}
.numeric-tabs .tab.active .up .numbers {
  color: #eb594d;
  font-size: 77px;
  transform: translate(-50%, -50px);
}
@media (max-width: 767px) {
  .numeric-tabs .tab.active .up .numbers {
    font-size: 50px;
    transform: translate(-50%, -27px);
  }
}
.numeric-tabs .tab.active .circle-plus:after {
  height: 12px;
  width: 2px;
  background-color: #eb594d;
  border-radius: 50%;
  width: 12px;
}
@media (max-width: 767px) {
  .numeric-tabs .tab.active .circle-plus:after {
    width: 10px;
    height: 10px;
  }
}
.numeric-tabs .tab.active .down {
  opacity: 1;
}
.numeric-tabs .content {
  opacity: 0;
  height: 0;
  min-height: 0;
  transition: 0s all ease-out;
}
.numeric-tabs .content.active {
  opacity: 1;
  height: auto;
  transition: 0.5s all ease-out;
}
.numbers .up {
  position: relative;
  z-index: 2;
}
.numbers .up:before {
  content: '';
  position: absolute;
  height: 2px;
  background-color: #142832;
  width: calc(100% + 30px);
  bottom: 18px;
  left: -15px;
}
@media (max-width: 1499px) {
  .numbers .up:before {
    bottom: 13px;
  }
}
@media (max-width: 1199px) {
  .numbers .up:before {
    bottom: 11px;
  }
}
@media (max-width: 991px) {
  .numbers .up:before {
    bottom: 17px;
  }
}
@media (max-width: 767px) {
  .numbers .up:before {
    max-width: 232px;
    left: calc(50% - 16px);
    width: 100%;
    transform: translateX(calc(-50% + 16px));
    bottom: 9px;
  }
}
@media (max-width: 767px) {
  .numbers .up .f77 {
    font-size: 55.2px;
  }
}
.numbers .down {
  max-width: 300px;
  transform: translate(0, -48px);
  margin: 0 auto;
}
@media (max-width: 1199px) {
  .numbers .down {
    transform: translate(0, -41px);
    max-width: 200px;
  }
}
@media (max-width: 991px) {
  .numbers .down {
    transform: translate(0, -47px);
  }
}
@media (max-width: 767px) {
  .numbers .down {
    transform: translate(0, -36px);
  }
}
.numbers .down .circle {
  border-radius: 50%;
  width: 100%;
  border: 2px solid #fff;
  padding-bottom: 100%;
}
.numbers .down .desc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 100%;
}
.accordion-section .panel {
  background-color: transparent;
  border: none;
}
.accordion-section .panel .panel-heading {
  padding: 0;
  background-image: none;
  background-color: transparent;
}
.accordion-section .panel .panel-heading .panel-title {
  color: #fff;
  margin: 0;
}
.accordion-section .panel .panel-heading .panel-title a {
  display: block;
  position: relative;
  padding: 5% 57px 5% 0;
  text-decoration: none;
}
@media (max-width: 500px) {
  .accordion-section .panel .panel-heading .panel-title a {
    padding-right: 39px;
  }
}
.accordion-section .panel .panel-heading .panel-title a:hover,
.accordion-section .panel .panel-heading .panel-title a:focus {
  color: #fff;
}
.accordion-section .panel .panel-heading .panel-title a .plus-minus,
.accordion-section .panel .panel-heading .panel-title a .minus-to-plus {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -21px;
  border-radius: 50%;
  border: 2px solid #374a54;
  width: 42px;
  height: 42px;
}
@media (max-width: 500px) {
  .accordion-section .panel .panel-heading .panel-title a .plus-minus,
  .accordion-section .panel .panel-heading .panel-title a .minus-to-plus {
    width: 24px;
    height: 24px;
  }
}
.accordion-section .panel .panel-heading .panel-title a .plus-to-minus,
.accordion-section .panel .panel-heading .panel-title a .minus-to-plus {
  transition: transform ease 0.2s;
}
.accordion-section .panel .panel-heading .panel-title a .plus-to-minus:before,
.accordion-section .panel .panel-heading .panel-title a .minus-to-plus:before,
.accordion-section .panel .panel-heading .panel-title a .plus-to-minus:after,
.accordion-section .panel .panel-heading .panel-title a .minus-to-plus:after {
  content: '';
  position: absolute;
  background-color: #374a54;
}
.accordion-section .panel .panel-heading .panel-title a .plus-to-minus:before,
.accordion-section .panel .panel-heading .panel-title a .minus-to-plus:before {
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 52%;
  top: 50%;
  margin-top: -10px;
}
@media (max-width: 500px) {
  .accordion-section .panel .panel-heading .panel-title a .plus-to-minus:before,
  .accordion-section .panel .panel-heading .panel-title a .minus-to-plus:before {
    margin-top: -5px;
  }
}
.accordion-section .panel .panel-heading .panel-title a .plus-to-minus:after,
.accordion-section .panel .panel-heading .panel-title a .minus-to-plus:after {
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -1px;
  height: 2px;
  width: 52%;
  transition: transform ease 0.2s;
}
@media (max-width: 500px) {
  .accordion-section .panel .panel-heading .panel-title a .plus-to-minus:after,
  .accordion-section .panel .panel-heading .panel-title a .minus-to-plus:after {
    margin-left: -5px;
  }
}
.accordion-section .panel .panel-heading .panel-title a .minus-to-plus {
  transform: rotate(-90deg);
}
.accordion-section .panel .panel-heading .panel-title a .minus-to-plus:after {
  transform: rotate(-90deg);
}
.accordion-section .panel .panel-heading .panel-title a[aria-expanded="false"] {
  border-bottom-width: 1px;
  border-bottom-color: #374a54;
  border-bottom-style: solid;
  transition: border-color ease 0.2s;
}
.accordion-section .panel .panel-heading .panel-title a[aria-expanded="true"] {
  border-bottom-width: 1px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
}
.accordion-section .panel .collapsing {
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
.accordion-section .panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top: none;
  border-bottom: 1px solid #374a54;
  padding: 0 0 8% 0;
}
.methodology .sticky-nav {
  max-height: 116px;
  padding: 2% 0;
  background-color: #eb594d;
  display: flex;
  align-items: center;
  transition: all 0.6s ease;
}
.methodology .sticky-nav .text {
  color: #ba3a30;
}
.methodology .sticky-nav .text img {
  margin: 0 50px;
}
.methodology .sticky-nav .text span {
  transition: 0.5s all ease;
  cursor: pointer;
  display: block;
}
@media (min-width: 768px) {
  .methodology .sticky-nav .text span {
    display: inline;
  }
}
.methodology .sticky-nav .text span.arrow {
  display: none;
}
@media (min-width: 768px) {
  .methodology .sticky-nav .text span.arrow {
    display: inline;
  }
}
.methodology .sticky-nav.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}
.methodology .sticky-nav.offset1 .text span:first-child {
  color: #fff;
}
.methodology .sticky-nav.offset2 .text span:last-child {
  color: #fff;
}
.methodology .margin {
  background-color: #efefef;
}
.methodology .infographic {
  position: relative;
}
.methodology .infographic .sections .section {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
}
.methodology .infographic .sections .section:first-child {
  height: 18%;
  top: 0;
  background-color: #efefef;
}
.methodology .infographic .sections .section:nth-child(2) {
  height: 12%;
  top: 18%;
}
.methodology .infographic .sections .section:nth-child(3) {
  height: 11%;
  background-color: #efefef;
  top: 30%;
}
.methodology .infographic .sections .section:nth-child(4) {
  height: 8%;
  top: 41%;
}
@media (max-width: 991px) {
  .methodology .infographic .sections .section .container {
    width: 100%;
  }
}
.methodology .infographic .sections .section .container .box-text .header {
  margin-bottom: 21px;
}
@media (max-width: 991px) {
  .methodology .infographic .sections .section .container .box-text .header {
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    position: absolute;
    top: 4%;
    width: auto;
    height: 25px;
    white-space: nowrap;
  }
}
@media (max-width: 991px) and (max-width: 500px) {
  .methodology .infographic .sections .section .container .box-text .header {
    font-size: 13px;
  }
}
@media (max-width: 991px) and (max-width: 400px) {
  .methodology .infographic .sections .section .container .box-text .header {
    font-size: 10px;
  }
}
@media (max-width: 991px) {
  .methodology .infographic .sections .section .container .box-text .description {
    display: none;
  }
}
@media (max-width: 991px) {
  .methodology .infographic img {
    padding-left: 25px;
  }
}
body.sticky .sticky-nav.sticky {
  top: 70px;
}
@media (min-height: 500px) {
  body.sticky .sticky-nav.sticky {
    top: 103px;
  }
}
.pleograf-section .circle-wrapper {
  position: relative;
  min-height: 320px;
}
@media (min-width: 600px) {
  .pleograf-section .circle-wrapper {
    min-height: 350px;
  }
}
@media (min-width: 1200px) {
  .pleograf-section .circle-wrapper {
    min-height: 310px;
  }
}
.pleograf-section .circle-wrapper .outside-circle,
.pleograf-section .circle-wrapper .inside-circle {
  position: relative;
  border: 2px solid #142832;
  border-radius: 100%;
}
@media (min-width: 992px) {
  .pleograf-section .circle-wrapper .outside-circle,
  .pleograf-section .circle-wrapper .inside-circle {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.pleograf-section .circle-wrapper .outside-circle {
  width: 220px;
  height: 220px;
  margin: 69px auto 40px;
  transform: scale(0.85);
}
@media (min-width: 375px) {
  .pleograf-section .circle-wrapper .outside-circle {
    transform: scale(1);
  }
}
@media (min-width: 600px) {
  .pleograf-section .circle-wrapper .outside-circle {
    width: 240px;
    height: 240px;
  }
}
@media (min-width: 992px) {
  .pleograf-section .circle-wrapper .outside-circle {
    margin-left: 36%;
    width: 220px;
    height: 220px;
  }
}
@media (min-width: 1200px) {
  .pleograf-section .circle-wrapper .outside-circle {
    width: 240px;
    height: 240px;
  }
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element {
  position: absolute;
  background: #fff;
  border: 2px solid #142832;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  padding: 0;
  margin: 0;
  transition: 0.2s border ease-out;
  outline: none;
  box-shadow: none !important;
  text-transform: lowercase;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 600px) {
  .pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 992px) {
  .pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element {
    width: 55px;
    height: 55px;
  }
}
@media (min-width: 1200px) {
  .pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element {
    width: 100px;
    height: 100px;
  }
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
}
@media (min-width: 600px) {
  .pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element a {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 992px) {
  .pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element a {
    width: 55px;
    height: 55px;
  }
}
@media (min-width: 1200px) {
  .pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element a {
    width: 100px;
    height: 100px;
  }
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element [aria-expanded="true"] {
  color: #142832;
  transition: color ease 400ms;
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element [aria-expanded="true"] svg * {
  fill: #eb594d;
  transition: fill ease 400ms;
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element .svg {
  opacity: 1;
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element svg {
  width: 65%;
  height: 65%;
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element svg * {
  fill: #142832;
  transition: fill ease 400ms;
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element:nth-child(1 ) {
  bottom: 30%;
  left: 0;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-70%, 50%);
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element:nth-child(2 ) {
  top: 0;
  left: 50%;
  transform: translate(-50%, -70%);
}
.pleograf-section .circle-wrapper .outside-circle .pleograf-elements .pleograf-element:nth-child(3 ) {
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(70%, -50%);
  top: 70%;
}
.pleograf-section .circle-wrapper .inside-circle {
  width: 150px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
}
@media (min-width: 992px) {
  .pleograf-section .circle-wrapper .inside-circle {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 1200px) {
  .pleograf-section .circle-wrapper .inside-circle {
    width: 150px;
    height: 150px;
  }
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'GloberxBold';
  border: 0;
  background: none;
  outline: none;
  box-shadow: none !important;
  width: 100%;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element .text0,
.pleograf-section .circle-wrapper .inside-circle .pleograf-element .text1,
.pleograf-section .circle-wrapper .inside-circle .pleograf-element .text2,
.pleograf-section .circle-wrapper .inside-circle .pleograf-element .text3 {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element .text0 {
  visibility: visible;
  opacity: 1;
  font-weight: 500;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element.visible-1 .text1 {
  visibility: visible;
  opacity: 1;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element.visible-1 .text0 {
  visibility: hidden;
  opacity: 0;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element.visible-2 .text2 {
  visibility: visible;
  opacity: 1;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element.visible-2 .text0 {
  visibility: hidden;
  opacity: 0;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element.visible-3 .text3 {
  visibility: visible;
  opacity: 1;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element.visible-3 .text0 {
  visibility: hidden;
  opacity: 0;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element ul li {
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element ul li.ghost {
  opacity: 0;
  max-width: 0;
  color: #eb594d;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element ul li.ghost.visible {
  max-width: 1em;
  opacity: 1;
}
.pleograf-section .circle-wrapper .inside-circle .pleograf-element ul li::before {
  display: none;
}
.pleograf-section .panel {
  border: none;
  box-shadow: none;
}
.pleograf-section .panel .f48 {
  margin-bottom: 20px;
}
.pleograf-section .panel .f48 a {
  color: #efefef;
  transition: color ease 400ms;
  text-decoration: none;
}
.pleograf-section .panel .f48 a[aria-expanded="true"] {
  color: #142832;
  transition: color ease 400ms;
}
.pleo-group .item {
  color: #fff;
  position: relative;
  float: left;
  overflow: hidden;
  margin: 0;
  color: #000;
  -webkit-perspective: 50em;
  padding: 7%;
  perspective: 50em;
  width: 100%;
  max-width: 33.3%;
  text-align: center;
}
.pleo-group .item * {
  -webkit-box-sizing: padding-box;
  box-sizing: padding-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.pleo-group .item .svg {
  max-width: 100%;
  vertical-align: top;
  height: 40px;
  width: 190px;
  opacity: 1;
}
.pleo-group .item figcaption {
  top: 50%;
  left: 20px;
  right: 20px;
  position: absolute;
  opacity: 0;
  z-index: 1;
  -webkit-transform: translate(10%, -50%);
  transform: translate(10%, -50%);
  padding: 9%;
  -moz-transform: translate(10%, -50%);
  -ms-transform: translate(10%, -50%);
  -o-transform: translate(10%, -50%);
}
.pleo-group .item .layout {
  background-color: #fff;
  position: absolute;
  content: "";
  display: block;
  top: 18px;
  left: 18px;
  right: 18px;
  bottom: 18px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  opacity: 0;
}
.pleo-group .item a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}
@media (min-width: 1025px) {
  .pleo-group .item:hover figcaption {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 1;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
  }
}
@media (min-width: 1025px) {
  .pleo-group .item:hover .layout {
    opacity: 1;
  }
}
.pleo-group .container {
  position: relative;
}
.pleo-group .container:before {
  position: absolute;
  content: '';
  height: 1px;
  background-color: #142832;
  top: 50%;
  width: calc(100% - 32px);
}
.pleo-group .wrapper {
  overflow: hidden;
  position: relative;
}
.pleo-group .wrapper:before {
  position: absolute;
  content: '';
  width: 1px;
  background-color: #142832;
  left: 33.3%;
  height: 600px;
}
.pleo-group .wrapper:after {
  position: absolute;
  content: '';
  width: 1px;
  background-color: #142832;
  left: 66.6%;
  height: 600px;
}
#career #list .list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
#career #list .list .job-details-container {
  margin-bottom: 12px;
  display: none;
  position: relative;
  width: 100%;
}
#career #list .list .job-details-container .job-details {
  border: 1px solid #dfdfdf;
  padding: 15px;
  padding: 5%;
}
#career #list .list .job-details-container .job-details .close {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 1;
  cursor: pointt;
}
#career #list .list .job-details-container button.apply-job {
  background-color: transparent;
  border: none;
  color: #eb594d;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: row;
  align-items: center;
  -webkit-align-items: center;
  margin-top: 5%;
  outline: none;
}
#career #list .list .job-details-container button.apply-job span {
  padding-left: 15px;
}
#career #list .list .item {
  position: relative;
  padding: 5%;
  width: 100%;
  margin-bottom: 14px;
  height: 333px;
  cursor: pointer;
  background-color: #eb594d;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 640px) {
  #career #list .list .item {
    height: 200px;
  }
}
@media (min-width: 550px) {
  #career #list .list .item {
    width: calc(50% - 6px);
  }
}
@media (min-width: 768px) {
  #career #list .list .item {
    width: calc(50% - 8px);
  }
}
#career #list .list .item .control-button {
  color: #fff;
  background-color: transparent;
  border: none;
  border-radius: 0;
  text-align: left;
  position: relative;
  padding-left: 50px;
}
@media (min-width: 992px) {
  #career #list .list .item .control-button {
    padding-left: 57px;
  }
}
#career #list .list .item .control-button:focus {
  outline: none;
}
#career #list .list .item .control-button span {
  padding-left: 8px;
}
#career #list .list .item .control-button.collapse {
  display: none;
}
#career #list .list .item .control-button .circle-plus,
#career #list .list .item .control-button .circle-minus-white {
  position: absolute;
  left: 0;
}
#career #list .list .item .text-wrapper {
  z-index: 2;
  position: relative;
  transition: color 0.4s ease;
}
#career #list .list .item.active .blend {
  background-color: rgba(0,0,0,0.1);
}
#career #list .list .item.active .control-button.more-info {
  display: none;
}
#career #list .list .item.active .control-button.collapse {
  display: block;
}
#career #list .list .design {
  background-color: #efefef;
}
#career #list .list .design .control-button {
  color: #eb594d;
}
#career #list .list .design .control-button .circle-plus,
#career #list .list .design .control-button .circle-minus-white {
  border: 2px solid #eb594d;
}
#career #list .list .design .control-button .circle-plus:before,
#career #list .list .design .control-button .circle-minus-white:before,
#career #list .list .design .control-button .circle-plus:after,
#career #list .list .design .control-button .circle-minus-white:after {
  background-color: #eb594d;
}
.apply-popup .header-text .header {
  margin-bottom: 20px;
}
.apply-popup .header-text .job-title {
  margin-bottom: 60px;
}
.apply-popup .contact-form form input,
.apply-popup .contact-form form textarea {
  border-color: #fff;
}
.apply-popup .form-wrapper h3 {
  margin: 35px 0 30px;
}
.apply-popup .form-wrapper input[type="checkbox"] {
  margin: 0;
}
.apply-popup .form-wrapper input[type="checkbox"] + label span {
  margin-right: 13px;
}
.apply-popup .form-wrapper input[type="checkbox"] + label span.notification-box {
  display: block;
  width: 21px;
  height: 21px;
  background: #142832;
  border: 1px solid #374a54;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.apply-popup .form-wrapper input[type="checkbox"] + label span.notification-box span {
  display: block;
  background: #142832;
  border-radius: 20px;
  width: 7px;
  height: 7px;
  margin: 6px;
}
.apply-popup .form-wrapper input[type="checkbox"]:checked + label span.notification-box span {
  background-color: #fff;
}
.apply-popup .form-wrapper .form-group {
  width: 100%;
  overflow: hidden;
}
.apply-popup .form-wrapper .file-container {
  position: relative;
  padding-left: 56px;
  color: #fff;
  margin-bottom: 35px;
  cursor: pointer;
}
.apply-popup .form-wrapper .file-container .circle-plus-white {
  position: absolute;
  left: 0;
}
.apply-popup .form-wrapper .file-container label {
  padding-top: 0;
}
.apply-popup .form-wrapper .notification {
  margin-bottom: 35px;
}
.apply-popup .form-wrapper .notification p {
  font-size: 12px;
  line-height: 24px;
}
.apply-popup .form-wrapper label.btn {
  padding: 0;
  background-color: #00f;
}
.apply-popup .form-wrapper input[type="file"] {
  display: none;
}
.apply-popup .form-wrapper input[type="file"] + label.full-width {
  width: 100%;
}
@media (min-width: 768px) {
  .apply-popup .form-wrapper .recaptcha {
    padding-top: 15px;
  }
}
.apply-popup button {
  color: #fff;
  background-color: transparent;
  padding: 0;
  border: none;
  display: flex;
}
.apply-popup button:focus {
  outline: none;
}
.apply-popup button .svg {
  margin-right: 14px;
}
#captcha-invalid {
  color: #fff;
  font-size: 14px;
  opacity: 0;
  margin-bottom: 30px;
  height: 0;
}
#captcha-invalid.visible {
  opacity: 1;
  height: auto;
}
#popup {
  background-color: #eb594d;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  padding: 25px 0;
  display: none;
}
@media (min-width: 768px) {
  #popup {
    padding: 70px 0;
  }
}
#popup .close-wrapper {
  text-align: right;
}
#popup .close-wrapper .svg {
  height: 22px;
  cursor: pointer;
}
#popup .close-wrapper .svg,
#popup .close-wrapper .svg * {
  fill: #fff;
  opacity: 1;
}
#popup .popup-content {
  height: calc(100vh - 140px);
  overflow-y: auto;
  overflow-x: hidden;
}
.overlay {
  position: fixed;
  z-index: 1000000;
  top: 0;
  transform: translateY(-100%);
  min-height: 100%;
  left: 0;
  right: 0;
  will-change: transform;
  transition: all 0.2s ease-in-out;
}
.overlay.is-active {
  transform: none;
}
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
}
.overlay .overlay-content {
  padding: 130px 0 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
@media (min-width: 500px) and (min-height: 700px) {
  .overlay .overlay-content {
    padding: 0 0 30px;
  }
}
@media (max-height: 699px) {
  .overlay .overlay-content {
    max-height: calc(100vh - 170px);
    overflow-y: auto;
    padding: 0 0 30px;
  }
}
.overlay .overlay-content .wrapper {
  min-width: 100%;
}
.overlay .overlay-content .wrapper ul {
  margin: 0;
  padding: 0;
}
.overlay .overlay-content .wrapper ul li {
  list-style-type: none;
}
.overlay .overlay-content .wrapper ul li.language-switcher {
  font-size: 18px;
  color: #e4e4e4;
}
.overlay .overlay-content .wrapper ul li.language-switcher a {
  display: inline-block;
}
.overlay .overlay-content .wrapper ul li.language-switcher a span {
  font-size: 18px;
}
.overlay .overlay-content .wrapper ul li.language-switcher a.active span {
  font-family: GloberRegular;
}
@media (min-width: 992px) and (min-height: 700px) {
  .overlay .overlay-content .wrapper {
    display: inline-block;
    position: absolute;
    min-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-100%, -50%);
    border-right: 1px solid #f27065;
  }
}
.overlay .overlay-content .wrapper a {
  font-family: 'GloberLight';
  text-decoration: none;
  color: #fff;
  display: block;
  line-height: 57px;
  text-shadow: 1px 0px #eb594d, -1px 0px #eb594d, 2px 0px #eb594d, -2px 0px #eb594d, 3px 0px #eb594d, -3px 0px #eb594d, 1px 2px #eb594d, -1px 2px #eb594d, 2px 2px #eb594d, -2px 2px #eb594d, 3px 2px #eb594d, -3px 2px #eb594d, 1px 4px #eb594d, -1px 4px #eb594d, 2px 4px #eb594d, -2px 4px #eb594d, 3px 4px #eb594d, -3px 4px #eb594d, 1px -2px #eb594d, -1px -2px #eb594d, 2px -2px #eb594d, -2px -2px #eb594d, 3px -2px #eb594d, -3px -2px #eb594d, 1px -4px #eb594d, -1px -4px #eb594d, 2px -4px #eb594d, -2px -4px #eb594d, 3px -4px #eb594d, -3px -4px #eb594d, 0 1px #eb594d, 0 -1px #eb594d, 0 2px #eb594d, 0 -2px #eb594d, 0 3px #eb594d, 0 -3px #eb594d;
}
@media (max-height: 900px) {
  .overlay .overlay-content .wrapper a {
    font-size: 30px;
    line-height: 43px;
  }
}
@media (max-width: 1200px) {
  .overlay .overlay-content .wrapper a {
    font-size: 30px;
    line-height: 43px;
  }
}
@media (max-height: 699px) {
  .overlay .overlay-content .wrapper a {
    font-size: 18px;
    line-height: 27px;
  }
}
@media (max-width: 480px) {
  .overlay .overlay-content .wrapper a {
    font-size: 18px;
    line-height: 27px;
  }
}
.overlay .overlay-content .wrapper a span {
  position: relative;
  font-size: 24px;
  line-height: 30px;
}
@media (min-width: 768px) {
  .overlay .overlay-content .wrapper a span {
    font-size: 30px;
    line-height: 36px;
  }
}
@media (min-width: 1024px) {
  .overlay .overlay-content .wrapper a span {
    font-size: 36px;
    line-height: 42px;
  }
}
.overlay .overlay-content .wrapper a span:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0px;
  top: calc(100% - 1px);
  left: 50%;
  right: 50%;
  background: #fff;
  z-index: -1;
  transition: 0.2s all ease-out;
}
.overlay .overlay-content .wrapper a.active > span {
  color: #ba3a30;
  font-family: 'GloberxBold';
}
.overlay .overlay-content .wrapper a.active > span:before {
  top: calc(100% - 5px);
  bottom: 4px;
  background-color: #ba3a30;
}
.overlay .overlay-content .wrapper a:hover > span:before {
  left: 0;
  right: 0;
}
.overlay .overlay-content .wrapper .bottom-menu {
  margin-top: 30px;
}
.overlay .overlay-content .wrapper .bottom-menu a span {
  color: #ff968d;
  position: relative;
  padding-right: 50px;
}
.overlay .overlay-content .wrapper .bottom-menu a span img,
.overlay .overlay-content .wrapper .bottom-menu a span svg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: inline-block;
  border: 2px solid #ff968d;
  padding: 5px;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  box-sizing: content-box;
  text-align: center;
  box-shadow: 2px 0 #eb594d, -2px 0 #eb594d, 0 2px #eb594d, 0 -2px #eb594d;
  height: 15px;
  width: 15px;
}
@media (min-width: 992px) {
  .overlay .overlay-content .wrapper .bottom-menu a span img,
  .overlay .overlay-content .wrapper .bottom-menu a span svg {
    height: 25px;
    width: 25px;
  }
}
.overlay .overlay-content .wrapper .bottom-menu a span img *,
.overlay .overlay-content .wrapper .bottom-menu a span svg * {
  fill: #ff968d;
}
.overlay .overlay-content .wrapper .bottom-menu a span:before {
  background-color: #ff968d;
}
.overlay .overlay-content .wrapper > span {
  color: #fff;
  font-size: 24px;
  font-family: 'GloberLight';
}
.overlay .container-bottom {
  position: absolute;
  display: flex;
  color: #fff;
  font-size: 14px;
  flex-wrap: wrap;
  line-height: 17px;
  bottom: 10px;
}
@media (min-width: 768px) {
  .overlay .container-bottom {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-height: 500px) and (min-width: 500px) {
  .overlay .container-bottom {
    bottom: 50px;
  }
}
@media (min-height: 700px) {
  .overlay .container-bottom {
    bottom: 35px;
  }
}
@media (min-width: 992px) {
  .overlay .container-bottom {
    bottom: 80px;
  }
}
.overlay .container-bottom .wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.overlay .container-bottom .wrapper .item-wrapper {
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
}
.overlay .container-bottom .wrapper .item-wrapper .mail a,
.overlay .container-bottom .wrapper .item-wrapper .social a {
  color: #fff;
  font-size: 18px;
  line-height: 45px;
  text-decoration: none;
}
@media (max-width: 480px) {
  .overlay .container-bottom .wrapper .item-wrapper {
    flex-direction: column;
  }
  .overlay .container-bottom .wrapper .item-wrapper .mail {
    margin-bottom: 5px;
  }
}
.overlay .container-bottom .wrapper .item-wrapper p {
  margin-right: 26px;
  line-height: 45px;
}
.overlay .container-bottom .wrapper .item-wrapper a {
  margin-right: 16px;
}
.overlay .container-bottom .wrapper .lang a {
  color: #fff;
  position: relative;
  margin-right: 10px;
  font-size: 18px;
  line-height: 45px;
  text-decoration: none;
}
.overlay .container-bottom .wrapper .lang a:after {
  cursor: default;
  content: "/";
  position: absolute;
  right: -12px;
  font-size: 20px;
  pointer-events: none;
}
.overlay .container-bottom .wrapper .lang a:last-child {
  margin-right: 0;
}
.overlay .container-bottom .wrapper .lang a:last-child:after {
  display: none;
}
.overlay .container-bottom .wrapper .autor {
  font-family: 'GloberSemiBold';
}
.overlay.pleo-red {
  background-color: #eb594d;
}
.overlay .right-side p {
  color: #fff;
}
.overlay .svg * {
  fill: #fff;
}
.navigation {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 200;
}
@media (min-height: 700px) and (min-width: 992px) {
  .navigation {
    top: 50px;
  }
}
.navigation .wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px 0;
}
@media (min-height: 500px) {
  .navigation .wrapper {
    padding: 25px 0;
  }
}
.navigation .wrapper .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navigation .wrapper .container:before,
.navigation .wrapper .container:after {
  display: none;
}
.navigation .wrapper .container .svg {
  width: 152px;
  height: 34px;
  transition: all 0.3s ease-out;
  margin-top: 6px;
}
@media (min-width: 769px) and (min-height: 700px) {
  .navigation .wrapper .container .svg {
    width: 209px;
    height: 48px;
  }
}
.navigation .wrapper .container .svg * {
  transition: fill 0.3s ease-out;
}
.navigation .wrapper .container .case-studies {
  color: #fff;
}
.navigation .wrapper .container .case-studies.pleo-red {
  color: #142832;
  text-decoration: none;
}
@media (min-width: 768px) {
  .navigation .wrapper .container .case-studies.pleo-red {
    margin-top: -3px;
  }
}
.navigation .wrapper .container .right-side {
  display: flex;
  align-items: center;
}
.navigation .wrapper .container .right-side a {
  transition: 0.3s color ease-out;
  text-decoration: none;
}
.navigation .wrapper .container .right-side p {
  font-size: 24px;
  font-family: 'GloberxBold';
  margin-right: 12px;
  display: none;
  transition: font-size 0.3s ease-out;
}
@media (min-width: 600px) {
  .navigation .wrapper .container .right-side p {
    display: inline;
  }
}
.navigation .wrapper .container .right-side .back {
  display: flex;
  position: relative;
  padding-left: 9px;
  margin-top: 0 !important;
}
.navigation .wrapper .container .right-side .back:before,
.navigation .wrapper .container .right-side .back:after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 4px;
  height: 1px;
  transform-origin: 0 50%;
  top: 50%;
  left: 0;
}
@media (max-width: 599px) {
  .navigation .wrapper .container .right-side .back:before,
  .navigation .wrapper .container .right-side .back:after {
    display: none;
  }
}
.navigation .wrapper .container .right-side .back.pleo-red:before,
.navigation .wrapper .container .right-side .back.pleo-red:after {
  background-color: #142832;
}
.navigation .wrapper .container .right-side .back:before {
  transform: rotate(45deg);
}
.navigation .wrapper .container .right-side .back:after {
  transform: rotate(-45deg);
}
.navigation .wrapper .container .right-side .back p {
  font-size: 12px;
  margin-right: 45px;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger {
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease-out;
  position: relative;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #fff;
  border-radius: 100px;
  outline: none;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger * {
  pointer-events: none;
}
@media (min-height: 500px) {
  .navigation .wrapper .container .right-side .menu-toggle .hamburger {
    width: 42px;
    height: 42px;
  }
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger .menu-cross {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 14px;
  height: 12px;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: #fff;
  font-size: 0;
  transition: all 0.25s ease-in-out;
}
@media (min-width: 500px) {
  .navigation .wrapper .container .right-side .menu-toggle .hamburger .menu-cross {
    width: 20px;
  }
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger .menu-cross:before,
.navigation .wrapper .container .right-side .menu-toggle .hamburger .menu-cross:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  transition: all 0.25s ease-in-out;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger button {
  border: none;
  outline: none;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger.pleo-red {
  border-color: #eb594d;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger.pleo-red .menu-cross {
  border-color: #eb594d;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger.pleo-red .menu-cross:before,
.navigation .wrapper .container .right-side .menu-toggle .hamburger.pleo-red .menu-cross:after {
  background: #eb594d;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger.is-active {
  border-color: #fff;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger.is-active .menu-cross {
  border-color: transparent;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger.is-active .menu-cross:before {
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #fff !important;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger.is-active .menu-cross:after {
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: #fff !important;
}
.navigation .wrapper .container .right-side .menu-toggle .hamburger:hover .circle-chart .circle-chart-circle {
  transition: 0.5s stroke-dasharray ease-out, 0.1s opacity ease-out;
  transition-delay: 0s, 0.49s;
  stroke-dasharray: 0 100;
  opacity: 0;
}
.navigation .wrapper:before {
  content: '';
  position: absolute;
  top: -200%;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #fff;
  z-index: -1;
  transition: 0.5s all ease-out;
}
.navigation.main.floating-nav .wrapper {
  position: fixed;
  transition: none;
  transform: translateY(-100%);
}
.navigation.main:not(.sticky) .wrapper .container .svg.white .circle-chart-circle {
  stroke: #fff;
}
.navigation.main:not(.sticky) .wrapper .container .svg.white path {
  fill: #fff;
}
.navigation.main.sticky .wrapper {
  transform: translateY(-100%);
  transition: 0.2s transform ease-out;
}
.navigation.main.sticky .wrapper .container {
  transition: 0.2s all ease-out;
}
.navigation.main.sticky .wrapper .container .svg {
  width: 152px;
  height: 34px;
}
.navigation.main.sticky .wrapper .container .right-side a {
  color: #142832;
  display: none;
}
@media (min-width: 450px) {
  .navigation.main.sticky .wrapper .container .right-side a {
    display: inline;
  }
}
.navigation.main.sticky .wrapper .container .right-side a p {
  font-size: 18px;
}
.navigation.main.sticky .wrapper .container .right-side .back {
  display: flex;
}
.navigation.main.sticky .wrapper .container .right-side .back:before,
.navigation.main.sticky .wrapper .container .right-side .back:after {
  background-color: #142832;
}
@media (max-width: 499px) {
  .navigation.main.sticky .wrapper .container .right-side .back {
    display: none;
  }
}
.navigation.main.sticky .wrapper .container .right-side .back p {
  font-size: 12px;
}
.navigation.main.sticky .wrapper .container .hamburger {
  height: 32px;
  width: 32px;
  border-color: #142832;
}
.navigation.main.sticky .wrapper .container .hamburger .menu-cross {
  border-color: #142832;
  width: 14px;
  height: 10px;
}
.navigation.main.sticky .wrapper .container .hamburger .menu-cross:before,
.navigation.main.sticky .wrapper .container .hamburger .menu-cross:after {
  background-color: #142832;
}
.navigation.main.sticky .wrapper:before {
  top: 0;
  transition: 0.1s all ease-out;
}
.navigation.main.sticky.show .wrapper {
  transform: translateY(0);
  transition: 0.5s transform ease-out;
}
.menu-design {
  width: 100%;
}
.menu-right {
  width: 1100px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .menu-right {
    width: 900px;
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .menu-right {
    width: 700px;
    padding: 0 40px;
  }
}
@media (max-width: 700px) {
  .menu-right {
    width: 400px;
  }
}
.menu-left {
  float: left;
  height: 54px;
  margin-top: 6px;
  margin-bottom: 3px;
}
.menu-left a {
  line-height: 24px;
}
.menu-left a img {
  width: auto;
  max-width: 100%;
  border: 0;
}
@media (max-width: 1010px) {
  .menu-left a img {
    max-height: 40px;
  }
}
.menu-nav {
  z-index: 50;
  float: right;
  display: inline-block;
  line-height: 24px;
}
.menu-ul {
  position: relative;
  z-index: 49;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 24px;
}
@media (max-width: 1010px) {
  .menu-ul {
    display: none;
  }
}
.menu-ul li {
  position: relative;
  z-index: 45;
  float: left;
  font-size: 13px;
  margin-left: 30px;
  padding: 0;
  line-height: 20px;
  list-style-position: outside;
}
@media (max-width: 1200px) {
  .menu-ul li {
    margin-left: 20px;
  }
}
.menu-ul li::before {
  background-color: inherit;
}
.menu-ul li a {
  font-size: 13px;
  font-family: 'Lato', Helvetica, Arial;
  text-transform: none;
  display: block;
  line-height: 54px;
  color: #002c41;
  position: relative;
  z-index: 45;
  outline-color: #002c41;
  text-decoration-style: solid;
  font-weight: 400;
  border-image-width: 1;
  text-decoration: none;
}
.menu-ul li a:hover {
  color: #05c3de;
}
.hamburger-wrapper {
  position: absolute;
  top: 33%;
  left: 75%;
}
@media (min-width: 1010px) {
  .hamburger-wrapper {
    display: none;
  }
}
.hamburger-wrapper .btn-ham span {
  display: block;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: #232323;
  border-radius: 3px;
  z-index: 1;
}
.hamburger-wrapper .btn-ham input {
  display: block;
  width: 25px;
  height: 25px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}
.hamburger-wrapper .btn-ham input:checked ~ span {
  opacity: 1;
  background: #232323;
}
.hamburger-wrapper .btn-ham input:checked:nth-last-child(3) {
  opacity: 0;
}
.hamburger-wrapper .hamburger-design {
  transform: translateX(150%);
  opacity: 0;
}
.hamburger-wrapper .hamburger-design.is-active {
  display: block;
  position: absolute;
  opacity: 1;
  background: #1a1a1a;
  padding: 0;
  top: -53px;
  right: -90px;
  min-height: 2500px;
  width: 340px;
  z-index: 122248222001;
  box-shadow: #000 0px 0px 3px;
  -moz-box-shadow: rgba(0,0,0,0.2) 0px 0px 3px;
  -webkit-box-shadow: #000 0px 0px 3px;
  border-left: 1px solid #333;
  transform-origin: 0% 0%;
  transform: translateX(25%);
  transition: transform 1s ease-out;
}
@media (min-width: 760px) {
  .hamburger-wrapper .hamburger-design.is-active {
    transform: translateX(40%);
  }
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .close-responsive-nav {
  width: 100%;
  height: 60px;
  display: block;
  postion: relative;
  cursor: pointer;
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .close-responsive-nav span {
  width: 20px;
  height: 20px;
  display: inline-block;
  opacity: 0.3;
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .close-responsive-nav span::hover {
  opacity: 1;
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .close-responsive-nav span::before,
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .close-responsive-nav span::after {
  position: absolute;
  left: 30px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #fff;
  top: 20px;
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .close-responsive-nav span::before {
  transform: rotate(45deg);
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .close-responsive-nav span::after {
  transform: rotate(-45deg);
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .menu-ul-ham {
  border-bottom: 1px solid #333;
  list-style: none;
  margin: 0;
  padding: 0;
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .menu-ul-ham li {
  margin: 0;
  color: #bfbfbf;
  padding: 0;
  cursor: pointer;
  border-top: 1px solid #333;
  position: relative;
  text-decoration: none;
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .menu-ul-ham li::before {
  background-color: inherit;
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .menu-ul-ham li::focus {
  outline: 0;
}
.hamburger-wrapper .hamburger-design.is-active .menu-responsive .menu-ul-ham li a {
  line-height: 30px;
  padding: 10px 20px;
  display: block;
  font-size: 13px;
  font-family: 'Lato', Helvetica, Arial;
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
}
.pleo-label {
  position: fixed;
  right: 0;
  top: 50%;
  width: 103px;
  background-color: #dfdfdf;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 13px 0;
  box-sizing: border-box;
  transform: translateY(-50%);
  z-index: 4;
  display: flex;
  justify-content: center;
}
@media (max-width: 480px) {
  .pleo-label {
    display: none;
  }
}
.pleo-label a {
  display: flex;
  align-items: center;
}
footer {
  width: 100%;
  position: relative;
}
footer #scroll-up {
  background-color: transparent;
  border: none;
  outline: none;
  position: fixed;
  bottom: 85px;
  right: 4%;
  visibility: hidden;
  opacity: 0;
  transition: 0.8s all ease;
  z-index: 99;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  height: 42px;
  width: 42px;
}
footer #scroll-up img {
  display: block;
}
footer #scroll-up .svg {
  width: 10px;
  height: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}
footer #scroll-up svg * {
  fill: #fff !important;
}
footer #scroll-up.visible {
  visibility: visible;
  opacity: 1;
}
footer #scroll-up.sticky {
  top: -31px;
  position: absolute;
  bottom: auto;
}
footer .footer-wrapper {
  border-top: 2px solid #dfdfdf;
  padding-top: 50px;
  padding-bottom: 65px;
}
footer .footer-wrapper ul {
  padding: 0;
  margin: 0;
}
footer .footer-wrapper ul li {
  list-style-type: none;
}
footer .footer-wrapper ul li:before {
  content: none;
}
footer .footer-wrapper a {
  text-decoration: none;
}
footer .footer-wrapper h4.vertical-section-title {
  margin-bottom: 35px;
}
@media (max-width: 1199px) {
  footer .footer-wrapper h4.vertical-section-title {
    margin-bottom: 22px;
  }
}
@media (max-width: 1199px) {
  footer .footer-wrapper .col {
    margin-bottom: 31px;
  }
}
footer .footer-wrapper .row .col:last-child {
  margin-bottom: 0;
}
footer .footer-wrapper .copyright {
  text-align: right;
}
@media (max-width: 767px) {
  footer .footer-wrapper .copyright {
    text-align: center;
  }
}
footer .footer-wrapper .pleogroup {
  display: flex;
  flex-direction: row;
}
footer .footer-wrapper .pleogroup h4 {
  margin-right: 22px;
}
@media (max-width: 991px) {
  footer .footer-wrapper .pleogroup h4 {
    margin-bottom: 35px;
  }
}
footer .footer-wrapper .pleogroup ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}
footer .footer-wrapper .pleogroup ul li {
  margin-right: 24px;
  list-style-type: none;
}
@media (max-width: 991px) {
  footer .footer-wrapper .pleogroup ul li {
    margin-bottom: 35px;
  }
}
footer .footer-wrapper .pleogroup ul li a .svg {
  height: 19px;
  width: 116px;
  opacity: 1;
}
.reveal {
  transition: 0.2s all ease-out;
}
.reveal.rv-fade-in {
  opacity: 0;
}
.reveal.rw-scale {
  transform: scale(1.9);
}
.reveal.animated {
  transition: 0.1s all ease-out;
}
.reveal.animated.rv-fade-in {
  opacity: 1;
}
.reveal.animated.rw-scale {
  transform: scale(1);
}
.fzf {
  background-color: #eb594d;
}
.fzf .navigation .svg * {
  fill: #142832 !important;
}
.fzf .navigation .open .svg * {
  fill: #fff !important;
}
.fzf .navigation .wrapper .hamburger {
  border: none;
  position: relative;
}
.fzf .navigation .wrapper .hamburger:after {
  content: url("../img/dead.jpg");
  position: absolute;
  top: -1px;
}
.fzf .navigation .wrapper .right-side .menu-toggle .hamburger .menu-cross {
  opacity: 0;
  transition: 0.5s opacity ease;
}
.fzf .navigation .wrapper .right-side .menu-toggle .is-active {
  border-color: #fff;
  background-image: none;
}
.fzf .navigation .wrapper .right-side .menu-toggle .is-active:after {
  content: '';
}
.fzf .navigation .wrapper .right-side .menu-toggle .is-active .menu-cross {
  border-color: transparent;
  opacity: 1;
  transition: 0.5s opacity ease;
}
.fzf .navigation .wrapper .right-side .menu-toggle .is-active .menu-cross:before {
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #fff !important;
  transition: 0.5s transform ease;
}
.fzf .navigation .wrapper .right-side .menu-toggle .is-active .menu-cross:after {
  transform: translate(-50%, -50%) rotate(-45deg);
  background-color: #fff !important;
  transition: 0.5s transform ease;
}
.fzf .content p {
  margin-bottom: 13px;
}
.fzf .content a {
  display: flex;
  color: #fff;
  text-decoration: none;
}
@media (min-width: 768px) {
  .fzf .content a {
    margin-top: 50px;
  }
}
.fzf .content a .svg {
  margin-right: 14px;
}
.fzf footer .footer-wrapper {
  border-top-color: #142832;
}
.fzf footer .footer-wrapper .svg * {
  fill: #142832 !important;
}
