.film-text
    video
        max-width: 100%
    @media(min-width: 992px)
        .row-center
            display: flex
            align-items: center
            display: -webkit-flex
            display: flex
            -webkit-align-items: center
            align-items: center
    .col-center
        text-align: center
        @media(max-width: 991px)
            margin-bottom: 40px