.news

    .content
        width: 100%
        display: flex
        flex-direction: row
        // justify-content space-between
        align-items: stretch
        flex-wrap: wrap

        .news-item-wrapper
            width: 100%
            text-decoration: none

            @media( min-width: 500px )
             width: 50%

            &:first-child
                width: 100%

            .news-item-image
                width: 100%
                padding-top: 100%
                background-color: dark-blue
                background-size: cover
                background-position: top, center
                position relative
                .overlay-hover
                    position: absolute
                    top: 0
                    bottom: 0
                    left: 0
                    right: 0
                    height: 100%
                    width: 100%
                    opacity: 0
                    transition: .5s ease
                    background-color: #008CBA
                    background-color: alpha( dark-blue, .8 )
                    z-index: 0
                    .circle-plus
                        position: absolute
                        top: 55%
                        left: 50%


                        -webkit-transform: translate3d(-50%, -50%, -50%)
                        transform: translate3d(-50%, -50%, -50%)
                        -webkit-transition: 0.2s ease-in-out
                        transition: 0.2s ease-in-out
                        transform: translate(-50%, -50%)


            .news-item-content
                padding-bottom: 50px

                .date
                    margin-top: 30px

                .title

                    margin-top: 10px
                    font-family: "GloberxBold"
                    padding-right: 20px

                    span
                        display: inline
                        width: calc( 100% );
                        background-image: linear-gradient( transparent calc( 100% - 2px ), dark-blue 5px);
                        background-position: 0 -7px
                        background-repeat: no-repeat
                        background-size: 0% 100%;
                        transition: background-size .2s ease-out
                        text-shadow:
                            1px 0px white,
                            -1px 0px white,
                            2px 0px white,
                            -2px 0px white;


            &:hover
                .news-item-image
                    .overlay-hover
                        opacity: 1
                        .circle-plus
                            top: 50%

                .news-item-content
                    .title
                        span
                            background-size: 100% 100%
                            transition: background-size .5s ease-out

            @media( min-width: 992px )
                width: 33.3333%

                .news-item-content
                    padding-bottom: 60px

                    .titles
                        margin-top: 15px

                &:first-child
                    width: 33.3333%
