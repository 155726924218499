$ease = ease
$duration = 400ms

.pleograf-section

	.circle-wrapper
		position: relative
		min-height: 320px

		@media( min-width: 600px )
			min-height: 350px
		@media( min-width: 1200px )
			min-height: 310px

		.outside-circle,
		.inside-circle
			position: relative
			border: 2px solid dark-blue
			border-radius: 100%
			@media( min-width: 992px )
				position: absolute
				top: 0
				left: 0


		.outside-circle
			width: 220px
			height: 220px
			margin: 69px auto 40px
			transform: scale(0.85)
			
			@media( min-width: 375px )
				transform: scale(1)

			@media( min-width: 600px )
				width: 240px
				height: 240px

			@media( min-width: 992px )
				margin-left: 36%;
				width: 220px
				height: 220px

			@media( min-width: 1200px )
				width: 240px
				height: 240px

			.pleograf-elements
				position: absolute
				top: 0
				left: 0
				right: 0
				bottom: 0
				
				.pleograf-element
					position: absolute
					background: white
					border: 2px solid dark-blue
					border-radius: 100%
					width: 70px
					height: 70px
					padding: 0
					margin: 0
					transition: .2s border ease-out
					outline: none
					box-shadow: none !important
					text-transform: lowercase
					display: flex
					justify-content: center
					align-items: center

					@media( min-width: 600px )
						width: 100px
						height: 100px


					@media( min-width: 992px )
						width: 55px
						height: 55px

					@media( min-width: 1200px )
						width: 100px
						height: 100px


					a
						display: flex
						justify-content: center
						align-items: center
						width: 70px
						height: 70px

						@media( min-width: 600px )
							width: 100px
							height: 100px


						@media( min-width: 992px )
							width: 55px
							height: 55px

						@media( min-width: 1200px )
							width: 100px
							height: 100px
						

					[aria-expanded="true"]
						color: dark-blue
						transition: color $ease $duration
						svg
							*
								fill: brand-color
								transition: fill $ease $duration

					.svg
						opacity: 1

					svg
						width: 65%
						height: 65%
						*
							fill: dark-blue
							transition: fill $ease $duration



					&:nth-child( 1 )
						bottom: 30%
						left: 0
						-webkit-transform: translate(-50%,50%)
						transform: translate(-70%,50%)


					&:nth-child( 2 )
						top: 0
						left: 50%
						transform: translate( -50%, -70% )

					&:nth-child( 3 )
						right: 0
						-webkit-transform: translate(50%,-50%)
						transform: translate(70%,-50%)
						top: 70%

					
					// &.active,
					// &:hover
					//     svg
					//         *
					//             fill: brand-color
					//             transition: fill $ease $duration


		.inside-circle
			width: 150px
			height: 150px
			top: 50%
			left: 50%
			transform: translate( -50%, -50% )
			border: none

			@media( min-width: 992px )
				width: 100px
				height: 100px

			@media( min-width: 1200px )
				width: 150px
				height: 150px

			.pleograf-element
				position: absolute
				top: 50%
				left: 50%
				transform: translate( -50%, -50% )
				font-family: 'GloberxBold'
				border: 0
				background: none
				outline: none
				box-shadow: none !important
				width: 100%

				.text0,
				.text1,
				.text2,
				.text3
					visibility: hidden
					opacity: 0
					position: absolute
					top: 50%
					left: 50%
					transform: translate( -50%, -50% )
				
				.text0
					visibility: visible
					opacity: 1 
					font-weight: 500

				&.visible-1
					.text1
						visibility: visible
						opacity: 1
					.text0
						visibility: hidden 
						opacity: 0

				&.visible-2
					.text2
						visibility: visible
						opacity: 1
					.text0
						visibility: hidden
						opacity: 0

				&.visible-3
					.text3
						visibility: visible
						opacity: 1
					.text0
						visibility: hidden
						opacity: 0

				ul
					padding: 0
					margin: 0
					display: flex
					
					li
						font-weight: 500
						transition: all 0.3s ease-in-out

						&.ghost 
							opacity: 0
							max-width: 0
							color: brand-color

							&.visible
								max-width: 1em
								opacity: 1

						&::before
							display: none

	.panel
		border: none
		box-shadow: none
		.f48
			margin-bottom: 20px
			a
				color: light-grey-2
				transition: color $ease $duration
				text-decoration: none
				&[aria-expanded="true"]
					color: dark-blue
					transition: color $ease $duration

