#popup
    background-color: brand-color
    position: fixed
    top: 0
    left: 0
    z-index: 9999
    width: 100%
    height: 100%
    padding: 25px 0
    display: none
    @media( min-width: 768px )
        padding: 70px 0

    .close-wrapper
        text-align: right

        .svg
            height: 22px
            cursor: pointer

        .svg,
        .svg *
            fill: #fff
            opacity: 1

    .popup-content
        height: calc( 100vh - 140px )
        overflow-y: auto
        overflow-x: hidden

