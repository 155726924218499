.author-info-wrapper
	flex-direction: column
	justify-content: flex-start
	margin-bottom: 15px
	display: flex
	.author-photo-wrapper
		width: 36px
		height: 36px
		border-radius: 100%
		display: flex
		align-items: center
		justify-content: center
		position: relative
		flex-direction: column
		.box-author-blog
			width: 36px
			height: 18px
			background: brand-color
			border-top-left-radius: 95px
			border-top-right-radius: 95px
			position: absolute
			top: 0px
		.box2-author-blog
			width: 36px
			height: 18px
			background: brand-color
			border-bottom-left-radius: 95px
			border-bottom-right-radius: 95px
			position: absolute
			bottom: 0px
		.author-img
			width: 32px
			height: 32px
			border-radius: 100%
			z-index: 2
			background-position: center !important
			background-size: cover !important
			background-repeat: no-repeat !important
	p.post-author
		color: #142832
		font-size: 16px
		text-transform: uppercase
		line-height: 22px
		margin-top: 10px
	p.post-author-position
		font-size: 14px
		line-height: 19px

