.reveal
    transition: .2s all ease-out

    &.rv-fade-in
        opacity: 0
    &.rw-scale
        transform: scale( 1.9 )

    &.animated
        transition: .1s all ease-out

        &.rv-fade-in
            opacity: 1
        &.rw-scale
            transform: scale( 1 )
