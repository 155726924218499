.realization
    .main-paragraph
        h1
            font-family: 'GloberHeavy'
         
            margin-bottom: 40px
            @media(max-width:1500px)
                margin-bottom: 30px
            @media(max-width: 375px)
                margin-bottom: 27px
        p
            @media(max-width:1500px)
                margin-bottom: 18px


