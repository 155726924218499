.pleo-group

    .item
        color #fff
        position relative
        float left
        overflow hidden
        margin 0
        color #000000
        -webkit-perspective 50em
        padding: 7%
        perspective 50em
        width: 100%
        max-width: 33.3%
        text-align: center


    .item
        *
            -webkit-box-sizing padding-box
            box-sizing padding-box
            -webkit-transition all 0.2s ease-out
            transition all 0.2s ease-out
            
        .svg
            max-width 100%
            vertical-align top
            height 40px
            width: 190px
            opacity: 1
        figcaption
            top 50%
            left 20px
            right 20px
            position absolute
            opacity 0
            z-index 1
            -webkit-transform translate(10%, -50%)
            transform translate(10%, -50%)
            padding: 9%

            -moz-transform: translate(10%, -50%)
            -ms-transform: translate(10%, -50%)
            -o-transform: translate(10%, -50%)

        .layout
            background-color #ffffff
            position absolute
            content ""
            display block
            top 18px
            left 18px
            right 18px
            bottom 18px
            -webkit-transition all .4s ease-in
            transition all .4s ease-in

            -moz-transition: all .4s ease-in
            -o-transition: all .4s ease-in 
            transition: all .4s ease-in
            // -webkit-transform rotateY(90deg)
            // transform rotateY(90deg)
            // -webkit-transform-origin 50% 50%
            // -ms-transform-origin 50% 50%
            // transform-origin 50% 50%
            opacity 0
        a
            left 0
            right 0
            top 0
            bottom 0
            position absolute
            z-index 1


    .item
        @media(min-width: 1025px)
            &:hover 
                figcaption
                    -webkit-transform translate(0, -50%)
                    transform translate(0, -50%)
                    opacity 1
                    -webkit-transition-delay 0.2s
                    transition-delay 0.2s

                    -moz-transform: translate(0, -50%)
                    -ms-transform: translate(0, -50%)
                    -o-transform: translate(0, -50%)

    .item
        @media(min-width: 1025px)
            &:hover
                .layout
                    opacity 1

    .container
        position relative
        &:before
            position: absolute
            content: ''
            height: 1px
            background-color: #142832
            top: 50%
            width: calc(100% - 32px)

    .wrapper
        overflow hidden
        position relative
        &:before
            position: absolute
            content: ''
            width: 1px
            background-color: #142832
            left: 33.3%
            height: 600px
        &:after
            position: absolute
            content: ''
            width: 1px
            background-color: #142832
            left: 66.6%
            height: 600px
