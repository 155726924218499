.circles-section
    .circles
        .circle-container
            max-width: 800px
            margin-top: -7%
            margin-bottom: -7%
            .plus
                width: 60px
                height: 60px
                @media(max-width: 600px)
                    width: 24px
                    height: 24px