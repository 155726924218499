.plug 
    background-color: brand-color
    height 100vh
    display: flex
    justify-content: center
    align-items: center
    min-height: 10em
    a
        &:hover, &:focus
            text-decoration: none 

    .svg    

        width: 152px
        height: 34px
        transition all 0.3s ease-out
        margin-top: 6px
        *
            fill: white !important

        @media( min-width: 500px ) and (min-height: 700px)
            width: 209px
            height: 48px
    p
        color: white
        font-family: GloberLight
        &:hover,
        &:focus
            color: white
            font-family: GloberLight