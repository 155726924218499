.start
    .selected-projects
        .rotator-wrapper
            width: 100%
            position: relative
            background-color: dark-blue


            @media( min-width: 992px )
                height: 50vw
                max-height: 623px


            @media( min-width: 1500px )
                height: 40vw

            .selected-project
                height: 60vw
                background-size: cover
                background-position: center

                @media( min-width: 992px )
                    height: 50vw

                @media( min-width: 1500px )
                    height: 40vw
                    max-height: 623px


            .container,
            .row,
            .col
                height: 100%

            .container
                @media( min-width: 992px )
                    position: absolute
                    top: 0
                    left: 50%
                    transform: translateX( -50% )

            .readmore-wrapper
                background-color: brand-color
                position: relative
                @media( min-width: 992px )
                    position: absolute
                    height: calc( 100% - 66px )
                    width: 663px
                    top: 50%
                    right: 0
                    transform: translateY( -50% )

                &::after,
                &::before
                    content: ""
                    position: absolute
                    top: 0
                    width: 100vw
                    background-color: brand-color
                    display: block
                    height: 100%
                    @media( min-width: 992px )
                        display: none
                &::before
                    left: 100%
                &::after
                    right: 100%

            .description-wrapper
                padding: 70px 15px 40px
                height: 100%

                @media( min-width: 600px )
                    padding: 80px 15px 50px

                @media( min-width: 992px )
                    padding: 100px 60px

                .btn-plus
                    margin-top: 30px
                    @media( min-width: 992px )
                        margin-top: 0

                .slick-arrow
                    top: 45px
                    width: 35px
                    height: 35px
                    border: 2px solid dark-blue
                    border-radius: 100%
                    transform: none
                    display flex
                    justify-content: center

                    @media( min-width: 992px )
                        width: 42px
                        height: 42px

                    &.slick-prev,
                    &.slick-next
                        display: -webkit-box;
                        -webkit-box-orient: horizontal


                        &:before
                            display: none

                        img,
                        svg
                            height: 20px
                            width: 10px

                    &.slick-prev
                        left: 15px

                        @media( min-width: 992px )
                            left: 60px

                        img,
                        svg
                            transform: rotate( -180deg )

                    &.slick-next
                        left: 70px

                        @media( min-width: 992px )
                            left: 125px

                .slick-list
                    height: 100%

                    .slick-track
                        height: 100%

                        .content
                            height: calc( 100% - 46px )
                            flex-direction: column
                            display: flex
                            justify-content: center
                            .f48
                                margin-bottom: 0
                                margin-top: 25px
                                @media(max-width: 992px)
                                    margin-bottom: 25px
                            @media(max-width: 992px)
                                margin: 40px 0

                            .description
                                margin-top: 10px
                                @media(max-width: 992px)
                                    display: none
                            .wrapperItem
                                display: inline-block
                                margin-top: -15px
                                .categories
                                    font-size: 15px
                                    display: inline-flex
                                    position: relative
                                    padding-right: 7px
                                    height: 25px

                                    &::after
                                        content: ''
                                        position: absolute
                                        right: 0
                                        top: 15px
                                        bottom: -9px
                                        width: 1px
                                        background-color: #6d2d2d
                                        @media(max-width: 992px)
                                            top: 9px
                                            bottom: -1px
                                    &:last-child::after
                                            display: none


            .readme-rotator
                left: 15px
                bottom: 40px
                @media( min-width: 992px )
                    left: 60px
                    bottom: 95px
                    .btn-plus
                        width: 45px
                        height 45px
