.text-photo
    .container
        .row
            @media(max-width: 1199px)
                // margin-bottom: 50px
                display flex
                flex-wrap: wrap
                div
                    &:first-child
                        margin-top: 40px
                        order: 2
                &:last-child
                    margin-bottom: 0

            
            @media(max-width: 1499px) and (min-width: 1200px)
                p
                    font-size: 21px
                    line-height: 31px
        a
            text-decoration: none
            &:hover
                .plus
                    transform: translateY(-50%) rotate( -90deg )