.start
    .others-about-us
        padding-top: 50px
        
        @media ( min-width: 500px )
            padding-top: 70px
        @media ( min-width: 768px )
            padding-top: 130px
        @media ( min-width: 992px )
            padding-top: 170px
        @media ( min-width: 1200px )
            padding-top: 190px
        
        .container
            .logos
                .others-logo

                    button
                        padding: 38px 50px
                        width: 100%
                        height: 100%
                        background: transparent
                        border: 0
                        position: relative
                        outline: none
                        box-shadow: none !important

                        &:after
                            content: ''
                            position: absolute
                            bottom: 0
                            left: 50%
                            right: 50%
                            height: 2px
                            background-color: brand-color
                            transition: .2s all ease-out

                        img
                            height: 50px
                            max-width: 100%
                            opacity: .2
                            transition: .2s all ease-out
                    
                    &:hover,
                    &.active
                        button
                            img
                                opacity: 1
                                transition: .5s all ease-out

                    &.active
                        button
                            &:after
                                left: 0
                                right: 0
                                transition: .5s all ease-out

            .opinions
                background-color: light-grey-2
                padding: 30px 0 
                color: dark-blue

                @media( min-width: 500px )
                    padding: 80px 0 50px

                @media( min-width: 992px )
                    padding: 140px 0 80px

                @media( min-width: 1200px )
                    padding: 180px 0 120px

                .others-opinion
                    opacity: 0
                    height: 0
                    min-height: 0
                    transition: 0s all ease-out

                    blockquote 
                        padding: 0
                        border: 0
                        margin: 0

                        .content
                            text-align: center
                            color: inherit

                        footer
                            margin-top: 71px
                            text-align: center
                            color: inherit


                            .who
                                font-family: "GloberxBold"
                            
                            &:before
                                content: ""

                    &.active
                        opacity: 1
                        height: auto
                        transition: .5s all ease-out