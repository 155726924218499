#career
    #list
        .list
            display: flex
            flex-direction: row
            flex-wrap: wrap
            justify-content: space-between

            .job-details-container
                margin-bottom: 12px
                display: none
                position: relative
                width: 100%

                .job-details
                    border: 1px solid light-grey
                    padding: 15px
                    padding: 5%

                    .close
                        position: absolute
                        top: 20px
                        right: 20px
                        opacity: 1
                        cursor: pointt
                
                button.apply-job
                    background-color: transparent
                    border: none
                    color: brand-color
                    display: flex
                    display: -webkit-box
                    display: -moz-box
                    display: -ms-flexbox
                    display: -webkit-flex  
                    flex-direction: row
                    align-items: center
                    -webkit-align-items: center
                    margin-top: 5%
                    outline: none
                    span
                        padding-left: 15px

            .item
                position: relative
                padding: 5%
                width: 100%
                margin-bottom: 14px
                height: 333px
                cursor: pointer
                background-color: brand-color
                display: flex
                flex-direction: column
                justify-content: space-between
                @media(max-width: 640px)
                    height: 200px


                @media ( min-width: 550px )
                    width: calc( 50% - 6px )

                @media( min-width: 768px )
                    width: calc( 50% - 8px )

                // .blend
                //     position: absolute
                //     top: 0
                //     left: 0
                //     height: 100%
                //     width: 100%
                //     // z-index: 1
                //     transition: background-color 0.4s ease

                .control-button

                    color: #fff
                    background-color: transparent
                    border: none
                    border-radius: 0
                    text-align: left
                    position relative
                    // display: flex
                    // align-items: center
                    padding-left: 50px
                    @media( min-width: 992px )
                        padding-left: 57px
                    &:focus
                        outline: none

                    span
                        padding-left: 8px

                    &.collapse
                        display: none


                    .circle-plus,
                    .circle-minus-white
                        position: absolute
                        left: 0


                .text-wrapper
                    z-index: 2
                    position: relative
                    transition: color 0.4s ease



                &.active
                    .blend
                        background-color: rgba( black, 0.1 )


                    .control-button

                        &.more-info
                            display: none
                        &.collapse
                            display: block


            .design
                background-color: light-grey-2
                .control-button
                    color: brand-color
                    .circle-plus,
                    .circle-minus-white
                        border: 2px solid brand-color
                    .circle-plus,
                    .circle-minus-white
                        &:before,
                        &:after
                            background-color brand-color
