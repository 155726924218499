 .gallery

    .grid-sizer
        width: 50%

    .grid-item
        width: 100%
        display: inline-block
        text-decoration: none

        @media( min-width: 992px )
            width: 50%
            height: 633px

        @media( min-width: 1200px )
            height: 670px

        @media( min-width: 1500px )
            height: 835px
            width: 50%


        .photo
            width: 100%
            padding-top: 70%
            background-repeat: no-repeat
            background-size: cover
            background-position: center
            position: relative

            .inside-container
                position: absolute
                top: 50%
                left: 50%
                transform: translate( -50%, -50% )
                padding: 31px
                opacity: 0
                width: calc( 100% - 42px )
                height: calc( 100% - 42px )
                background-color: brand-color
                transition: .2s all ease-out

                h1
                    font-family:'GloberBook'
                    margin: 0
                    padding: 0
                
                .technologies
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate( -50%, -50% )
                    width: 150px
                    height: 150px
                    border: 2px solid dark-blue
                    border-radius: 100%
                    

                    .circle-chart
                        width: 155px
                        height: 155px
                        position: absolute
                        top: 50%
                        left: 50%
                        transform: translate( -50%, -50% ) rotate(-90deg) scale( 1, -1 )
                        
                        .circle-chart-circle
                            transition: .2s stroke-dasharray ease-out
                            transform-origin: center
                            stroke: brand-color
                            stroke-width: 2
                            fill: transparent
                            stroke-dasharray: 100 100
                            stroke-linecap: round

                    .technology
                        position: absolute
                        transform: translate( -50%, -50% )

                        .img
                            width: 42px
                            height: 42px
                            border-radius: 100%
                            position: relative

                            svg,
                            img
                                width: 0
                                height: 0
                                position: absolute
                                top: 50%
                                left: 50%
                                transform: translate( -50%, -50% )
                                background-color: brand-color
                                border-radius: 100%

                        
                        span
                            position: absolute
                            white-space: nowrap
                            font-family:'GloberRegular'
                            opacity: 0

                    &.count-1

                        .technology
                            top: 0
                            left: 50%

                            span
                                bottom: calc( 100% + 20px )
                                left: 50%
                                transform: translateX( -50% )
                                opacity: 0
                                text-align: center

                    &.count-2
                        
                        .circle-chart
                            transform: translate( -50%, -50% ) rotate(-180deg) scale( 1, -1 )

                        .technology

                            &:nth-child(1)
                                top: 50%
                                left: 0

                                span
                                    top: 50%
                                    right: calc( 100% + 30px )
                                    transform: translateY( -50% )
                                    text-align: right
                                    
                            &:nth-child(2)
                                top: 50%
                                left: 100%

                                span
                                    top: 50%
                                    left: calc( 100% + 30px )
                                    transform: translateY( -50% )

                    &.count-3
                        
                        .circle-chart
                            transform: translate( -50%, -50% ) rotate(-160deg) scale( 1, -1 )

                        .technology

                            &:nth-child(1)
                                top: 35%
                                left: 3%

                                span
                                    top: 50%
                                    right: calc( 100% + 30px )
                                    transform: translateY( -50% )
                                    text-align: right

                            &:nth-child(2)
                                top: 0
                                left: 50%

                                span
                                    bottom: calc( 100% + 20px )
                                    left: 50%
                                    transform: translateX( -50% )
                                    text-align: center
                                    
                            &:nth-child(3)
                                top: 35%
                                left: 97%

                                span
                                    top: 50%
                                    left: calc( 100% + 30px )
                                    transform: translateY( -50% )

                    &.count-4
                        
                        .circle-chart
                            transform: translate( -50%, -50% ) rotate(-180deg) scale( 1, -1 )

                        .technology

                            &:nth-child(1)
                                top: 50%
                                left: 0%

                                span
                                    top: 50%
                                    right: calc( 100% + 30px )
                                    transform: translateY( -50% )
                                    text-align: right

                            &:nth-child(2)
                                top: 0%
                                left: 50%

                                span
                                    bottom: calc( 100% + 20px )
                                    left: 50%
                                    transform: translateX( -50% )
                                    text-align: center
                                    
                            &:nth-child(3)
                                top: 50%
                                left: 100%

                                span
                                    top: 50%
                                    left: calc( 100% + 30px )
                                    transform: translateY( -50% )
                                    
                            &:nth-child(4)
                                top: 100%
                                left: 50%

                                span
                                    top: calc( 100% + 20px )
                                    left: 50%
                                    transform: translateX( -50% )
                                    text-align: center

                    &.count-5
                        
                        .circle-chart
                            transform: translate( -50%, -50% ) rotate(-160deg) scale( 1, -1 )

                        .technology

                            &:nth-child(1)
                                top: 35%
                                left: 3%

                                span
                                    top: 50%
                                    right: calc( 100% + 30px )
                                    transform: translateY( -50% )
                                    text-align: right

                            &:nth-child(2)
                                top: 0
                                left: 50%

                                span
                                    bottom: calc( 100% + 20px )
                                    left: 50%
                                    transform: translateX( -50% )
                                    text-align: center
                                    
                            &:nth-child(3)
                                top: 35%
                                left: 97%

                                span
                                    top: 50%
                                    left: calc( 100% + 30px )
                                    transform: translateY( -50% )
                                    
                            &:nth-child(4)
                                top: 90%
                                left: 80%

                                span
                                    top: 85%
                                    left: calc( 60% + 30px )
                                    
                            &:nth-child(5)
                                top: 90%
                                left: 20%

                                span
                                    top: 85%
                                    right: calc( 60% + 30px )
                                    text-align: right


            .plus
                position: absolute
                top: 50%
                left: 50%
                transform: translate( -50%, -50% ) rotate( 0 )
                height: 42px
                width: 42px
                border: 2px solid brand-color
                border-radius: 100%
                transition: .2s all ease-out
                
                &:before,
                &:after
                    content: ''
                    width: 52%
                    height: 2px
                    background-color: brand-color
                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate( -50%, -50% )

                &:after
                    height: 52%
                    width: 2px

        .paragraph
            padding: 51px 15% 100px 0
            @media(max-width: 1499px)
                padding: 40px 15px 70px 0
            @media(max-width: 767px)
                padding: 40px 0 40px


            h1
                color: dark-blue
                font-family: 'GloberxBold'
                margin: 0
                padding: 0

                p
                    display: inline
                    width: calc( 100% );
                    background-image: linear-gradient( transparent calc( 100% - 2px ), black 5px);
                    background-position: 0 -12px
                    background-repeat: no-repeat
                    background-size: 0% 100%;
                    transition: background-size .2s ease-out
                    text-shadow: 
                        5px 0px white,
                        -5px 0px white;
                
            h5
                color: dark-blue
                margin: 0 0 18px


        @media( min-width: 1200px )
            &:hover

                .photo
                    .inside-container
                        opacity: 1
                        transition: .2s all ease-out

                        .technologies

                            .circle-chart
                                
                                .circle-chart-circle
                                    transition: .5s stroke-dasharray ease-out
                                    stroke-dasharray: 0 100
                                    transition-delay: .2s

                            .technology

                                .img
                                    svg,
                                    img
                                        width: 100%
                                        height: 100%
                                        transition: .07s all ease-out

                                span
                                    opacity: 1
                                    transition: .2s opacity ease-out

                                &:nth-child(1)
                                    .img
                                        svg,
                                        img
                                            transition-delay: .3s

                                    span
                                        transition-delay: .32s


                            &.count-2
                                
                                .technology

                                            
                                    &:nth-child(2)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .36s

                                        span
                                            transition-delay: .38s

                            

                            &.count-3
                                
                                .technology

                                            
                                    &:nth-child(2)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .32s

                                        span
                                            transition-delay: .34s
                                                
                                    &:nth-child(3)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .36s

                                        span
                                            transition-delay: .38s

                            &.count-4
                                
                                .technology

                                            
                                    &:nth-child(2)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .32s

                                        span
                                            transition-delay: .34s
                                                
                                    &:nth-child(3)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .36s

                                        span
                                            transition-delay: .38s

                                    &:nth-child(4)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .46s

                                        span
                                            transition-delay: .48s

                            &.count-5
                                
                                .technology

                                            
                                    &:nth-child(2)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .32s

                                        span
                                            transition-delay: .34s
                                                
                                    &:nth-child(3)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .36s

                                        span
                                            transition-delay: .38s

                                    &:nth-child(4)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .46s

                                        span
                                            transition-delay: .48s
        
                                    &:nth-child(5)
                                        .img
                                            svg,
                                            img
                                                transition-delay: .58s

                                        span
                                            transition-delay: .6s

                    .plus
                        border-color: dark-blue
                        transform: translate( -50%, -50% ) rotate( 90deg )
                        transition: .2s all ease-out
                        
                        &:before,
                        &:after
                            background-color: dark-blue

                .paragraph

                    h1
                        p
                            background-size: 100% 100%
                            transition: background-size .5s ease-out
                    
                    h5
                        color: brand-color

@keyframes circle-chart-fill {
    to { stroke-dasharray: 100 0 }
}