.author-info-wrapper-below-post
    display: flex
    flex-direction: row
    align-items: center
    margin-top: 100px
    .author-photo-wrapper
        width: 88px
        height: 88px
        border-radius: 100%
        display: flex
        align-items: center
        justify-content: center
        position: relative
        flex-direction: column
        margin-right: 10px
        .box-post-footer-auth
            width: 88px
            height: 44px
            background: brand-color
            border-top-left-radius: 95px
            border-top-right-radius: 95px
            position: absolute
            top: 0px
        .box2-post-footer-auth
            width: 88px
            height: 44px
            background: brand-color
            border-bottom-left-radius: 95px
            border-bottom-right-radius: 95px
            position: absolute
            bottom: 0px
        .blog-post-footer-auth-img
            width: 84px
            height: 84px
            border-radius: 100%
            z-index: 2
            background-position: center !important
            background-size: cover !important
            background-repeat: no-repeat !important
    .blog-post-footer-author-info
        display: flex
        flex-direction: column
        p.post-author-footer-who
            color: #142832
            font-size: 16px
            line-height: 22px
            margin-bottom: 16px
        p.post-author-footer
            color: #142832
            font-size: 16px
            text-transform: uppercase
            line-height: 22px
        p.post-author-position-footer
            font-size: 14px
            line-height: 19px
