light-blue = #374a54
$ease = ease
$duration = .2s

.accordion-section
    .panel
        background-color: transparent
        border: none
        .panel-heading
            padding 0
            background-image: none
            background-color: transparent
            .panel-title
                color: white
                margin: 0
                
                a
                    display: block
                    position relative
                    padding: 5% 57px 5% 0
                    text-decoration: none
                    @media(max-width: 500px)
                        padding-right: 39px
                    &:hover,
                    &:focus
                        color: white
                    .plus-minus,
                    .minus-to-plus
                        position: absolute
                        right: 0
                        top: 50%
                        margin-top: -21px
                        border-radius: 50%
                        border: 2px solid light-blue
                        width: 42px
                        height 42px
                        @media(max-width: 500px)
                            width: 24px
                            height 24px
                    .plus-to-minus,
                    .minus-to-plus
                        transition: transform $ease $duration
                        &:before,
                        &:after
                            content: ''
                            position: absolute
                            background-color light-blue
                        &:before
                            left: 50%
                            margin-left: -1px
                            width: 2px
                            height: 52%
                            top: 50%
                            margin-top: -10px
                            @media(max-width: 500px)
                                margin-top: -5px
                        &:after
                            top: 50%
                            left: 50%
                            margin-left: -10px
                            margin-top: -1px
                            height 2px
                            width 52%
                            transition: transform $ease $duration
                            @media(max-width: 500px)
                                margin-left: -5px
                    .minus-to-plus
                        transform: rotate(-90deg)
                        &:after
                            transform: rotate(-90deg)
                    &[aria-expanded="false"]
                        border-bottom-width: 1px
                        border-bottom-color: light-blue
                        border-bottom-style: solid
                        transition: border-color $ease $duration

                    &[aria-expanded="true"]
                        border-bottom-width: 1px
                        border-bottom-color: transparent
                        border-bottom-style: solid
        .collapsing
            -webkit-transition-timing-function: $ease
            -o-transition-timing-function: $ease
            transition-timing-function: $ease
            -webkit-transition-duration: $duration
            -o-transition-duration: $duration
            transition-duration: $duration


    .panel-default>.panel-heading+.panel-collapse>.panel-body
        border-top: none 
        border-bottom: 1px solid light-blue
        padding 0 0 8% 0

