.fzf
    background-color: brand-color
    .navigation
        .svg *
            fill: dark-blue  !important
        .open
            .svg *
                fill: white  !important
        .wrapper
            .hamburger
                border: none
                position: relative
                &:after
                    content: url('../img/dead.jpg')
                    position absolute  
                    top: -1px

            .right-side
                .menu-toggle
                    .hamburger
                        .menu-cross
                            opacity: 0
                            transition: .5s opacity ease
                    .is-active
                        border-color: white
                        background-image: none
                        &:after
                            content: ''

                        .menu-cross
                            border-color: transparent
                            opacity: 1
                            transition: 0.5s opacity ease

                            &:before
                                transform: translate(-50%, -50%) rotate(45deg)
                                background-color: white !important
                                transition: 0.5s transform ease

                            &:after
                                transform: translate(-50%, -50%) rotate(-45deg)
                                background-color: white !important
                                transition: 0.5s transform ease


    .content

        p
            margin-bottom: 13px

        a
            display: flex
            color: white
            text-decoration: none
            @media(min-width: 768px)
                margin-top: 50px
            .svg
                margin-right: 14px
                


    footer .footer-wrapper
        border-top-color: dark-blue
        .svg *
            fill: dark-blue  !important
