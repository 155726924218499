.social-media
    display: flex
    justify-content: center

    a
        display: block
        height: 64px
        width: 64px
        border: 1px solid #d9d9d9
        position: relative
        color: black
        transition: .5s color ease-out
        margin-right: 5px


        i
            position: absolute
            top: 50%
            left: 50%
            transform: translate( -50%, -50% )

        &:before
            content:''
            position: absolute
            height: 2px
            bottom: 0
            left: 0
            right: 0
            transition: .5s height ease-out

        &.facebook
            &:before
                background-color: #3b5998

        &.twitter
            &:before
                background-color: #00aced

        &.pinterest
            &:before
                background-color: #C92228

        &.linked-in
            &:before
                background-color: #0077B5

        &:last-child
            margin-right: 0

        &:hover
            color: white
            transition: .2s color ease-out

            &:before
                height: 100%
                transition: .2s height ease-out
