.blog-entry
    .container.title-container
        &:first-child
            margin-bottom: 0
    .blog-entry-header
        margin-bottom: 50px
        @media( min-width: 500px )
            margin-bottom: 80px
        @media( min-width: 768px )
            margin-bottom: 100px
        @media( min-width: 992px )
            margin-bottom: 140px
        @media( min-width: 1200px )
            margin-bottom: 160px
        @media( min-width: 1500px )
            margin-bottom: 180px

        h4.date
            color: dark-blue
    .other-news-section-container
        padding-top: 40px

        @media( min-width: 500px )
            padding-top: 50px
        @media( min-width: 768px )
            padding-top: 70px
        @media( min-width: 992px )
            padding-top: 100px
        @media( min-width: 1200px )
            padding-top: 140px
        @media( min-width: 1500px )
            padding-top: 160px

