.blog
    section.first-news
        a
            text-decoration: none

        .image-wrapper
            position: relative
            background-position: center
            background-repeat: no-repeat
            background-size: cover 

            @media( min-width: 768px )
                padding-top: 56.25%

            .text-container
                padding: 30px 8.33%
                color: #fff

                @media( min-width: 500px )
                    padding: 50px 8.33%

                @media( min-width: 768px )
                    padding: 0 8.33%
                    position: absolute
                    top: 50%
                    transform: translateY(-50%)

                h1
                    color: #fff
                    font-family: 'GloberHeavy'  

                h2.date
                    margin-bottom: 10% 
