.pleo-label
    position: fixed
    right: 0
    top: 50%
    width: 103px
    background-color: #dfdfdf
    border-top-left-radius: 5px
    border-bottom-left-radius: 5px
    padding: 13px 0
    box-sizing: border-box
    transform: translateY(-50%)
    z-index: 4
    display: flex
    justify-content: center
    @media(max-width: 480px)
        display: none
    a
        display: flex
        align-items: center