.apply-popup

    .header-text
        .header
            margin-bottom: 20px
        .job-title 
            margin-bottom: 60px

    .contact-form
        form
            input,
            textarea
                border-color: white
    .form-wrapper
        h3
            margin: 35px 0 30px

         input[type="checkbox"]
            margin: 0
            // display: none

            + label
                span
                    margin-right: 13px
                    &.notification-box
                        display: block
                        width: 21px
                        height: 21px
                        background: dark-blue
                        border: 1px solid light-blue
                        cursor: pointer
                        transition: all .2s ease-in-out

                        span
                            display: block
                            background: dark-blue
                            border-radius: 20px
                            width: 7px
                            height: 7px
                            margin: 6px

            &:checked
                + label							
                    span
                        &.notification-box
                            span
                                background-color: #fff

 

        .form-group
            width: 100%
            overflow: hidden
        .file-container
            position relative
            padding-left: 56px
            color:white
            margin-bottom: 35px
            cursor: pointer
            .circle-plus-white
                position: absolute
                left 0
            label
                padding-top: 0

        .notification
            margin-bottom: 35px
            p
                font-size: 12px
                line-height: 24px


        label.btn
            padding: 0
            background-color: blue

        input[type="file"]
            display: none

            + label
            
                &.full-width
                    width: 100%
        
        .recaptcha
            @media( min-width: 768px )
                padding-top: 15px


    button
        color: white
        background-color: transparent
        padding: 0
        border: none
        display: flex
        &:focus
            outline: none
        .svg
            margin-right: 14px


#captcha-invalid
    color: white
    font-size: 14px
    opacity: 0
    margin-bottom: 30px
    height 0
    &.visible
        opacity: 1
        height auto